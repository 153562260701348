/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';
import type { PeopleRelationshipType } from './PeopleRelationshipType';
import {
    PeopleRelationshipTypeFromJSON,
    PeopleRelationshipTypeFromJSONTyped,
    PeopleRelationshipTypeToJSON,
} from './PeopleRelationshipType';
import type { PeopleRelationshipUpdate } from './PeopleRelationshipUpdate';
import {
    PeopleRelationshipUpdateFromJSON,
    PeopleRelationshipUpdateFromJSONTyped,
    PeopleRelationshipUpdateToJSON,
} from './PeopleRelationshipUpdate';
import type { PersonCreateAllOf } from './PersonCreateAllOf';
import {
    PersonCreateAllOfFromJSON,
    PersonCreateAllOfFromJSONTyped,
    PersonCreateAllOfToJSON,
} from './PersonCreateAllOf';

/**
 * 
 * @export
 * @interface PeopleRelationshipCreate
 */
export interface PeopleRelationshipCreate {
    /**
     * 
     * @type {MetadataCreate}
     * @memberof PeopleRelationshipCreate
     */
    metadata: MetadataCreate;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipCreate
     */
    child: string;
    /**
     * 
     * @type {Date}
     * @memberof PeopleRelationshipCreate
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PeopleRelationshipCreate
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipCreate
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipCreate
     */
    parent: string;
    /**
     * 
     * @type {PeopleRelationshipType}
     * @memberof PeopleRelationshipCreate
     */
    relationShipType: PeopleRelationshipType;
}

/**
 * Check if a given object implements the PeopleRelationshipCreate interface.
 */
export function instanceOfPeopleRelationshipCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "child" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "relationShipType" in value;

    return isInstance;
}

export function PeopleRelationshipCreateFromJSON(json: any): PeopleRelationshipCreate {
    return PeopleRelationshipCreateFromJSONTyped(json, false);
}

export function PeopleRelationshipCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeopleRelationshipCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCreateFromJSON(json['metadata']),
        'child': json['child'],
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'parent': json['parent'],
        'relationShipType': PeopleRelationshipTypeFromJSON(json['relationShipType']),
    };
}

export function PeopleRelationshipCreateToJSON(value?: PeopleRelationshipCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCreateToJSON(value.metadata),
        'child': value.child,
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
        'notes': value.notes,
        'parent': value.parent,
        'relationShipType': PeopleRelationshipTypeToJSON(value.relationShipType),
    };
}

