/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityCreate } from './ActivityCreate';
import {
    ActivityCreateFromJSON,
    ActivityCreateFromJSONTyped,
    ActivityCreateToJSON,
} from './ActivityCreate';
import type { ActivityTransition } from './ActivityTransition';
import {
    ActivityTransitionFromJSON,
    ActivityTransitionFromJSONTyped,
    ActivityTransitionToJSON,
} from './ActivityTransition';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { OpportunityAllOf } from './OpportunityAllOf';
import {
    OpportunityAllOfFromJSON,
    OpportunityAllOfFromJSONTyped,
    OpportunityAllOfToJSON,
} from './OpportunityAllOf';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {Metadata}
     * @memberof Activity
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    action?: string;
    /**
     * 
     * @type {Array<ActivityTransition>}
     * @memberof Activity
     */
    transitions?: Array<ActivityTransition>;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    comment?: string;
}

/**
 * Check if a given object implements the Activity interface.
 */
export function instanceOfActivity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function ActivityFromJSON(json: any): Activity {
    return ActivityFromJSONTyped(json, false);
}

export function ActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Activity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'action': !exists(json, 'action') ? undefined : json['action'],
        'transitions': !exists(json, 'transitions') ? undefined : ((json['transitions'] as Array<any>).map(ActivityTransitionFromJSON)),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ActivityToJSON(value?: Activity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'action': value.action,
        'transitions': value.transitions === undefined ? undefined : ((value.transitions as Array<any>).map(ActivityTransitionToJSON)),
        'comment': value.comment,
    };
}

