/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Person,
  PersonCreate,
  PersonQuery,
  PersonQueryResult,
  PersonSummary,
  PersonUpdate,
} from '../models';
import {
    PersonFromJSON,
    PersonToJSON,
    PersonCreateFromJSON,
    PersonCreateToJSON,
    PersonQueryFromJSON,
    PersonQueryToJSON,
    PersonQueryResultFromJSON,
    PersonQueryResultToJSON,
    PersonSummaryFromJSON,
    PersonSummaryToJSON,
    PersonUpdateFromJSON,
    PersonUpdateToJSON,
} from '../models';

export interface CreatePersonRequest {
    personCreate: PersonCreate;
}

export interface GetPersonRequest {
    id: string;
}

export interface GetPersonSummaryRequest {
    id: string;
}

export interface QueryPeopleRequest {
    personQuery: PersonQuery;
}

export interface UpdatePersonRequest {
    id: string;
    personUpdate: PersonUpdate;
}

/**
 * PersonApi - interface
 * 
 * @export
 * @interface PersonApiInterface
 */
export interface PersonApiInterface {
    /**
     * 
     * @param {PersonCreate} personCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApiInterface
     */
    createPersonRaw(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>>;

    /**
     */
    createPerson(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApiInterface
     */
    getPersonRaw(requestParameters: GetPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>>;

    /**
     */
    getPerson(requestParameters: GetPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApiInterface
     */
    getPersonSummaryRaw(requestParameters: GetPersonSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonSummary>>;

    /**
     */
    getPersonSummary(requestParameters: GetPersonSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonSummary>;

    /**
     * 
     * @param {PersonQuery} personQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApiInterface
     */
    queryPeopleRaw(requestParameters: QueryPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonQueryResult>>;

    /**
     */
    queryPeople(requestParameters: QueryPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonQueryResult>;

    /**
     * 
     * @param {string} id 
     * @param {PersonUpdate} personUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApiInterface
     */
    updatePersonRaw(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>>;

    /**
     */
    updatePerson(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person>;

}

/**
 * 
 */
export class PersonApi extends runtime.BaseAPI implements PersonApiInterface {

    /**
     */
    async createPersonRaw(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>> {
        if (requestParameters.personCreate === null || requestParameters.personCreate === undefined) {
            throw new runtime.RequiredError('personCreate','Required parameter requestParameters.personCreate was null or undefined when calling createPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonCreateToJSON(requestParameters.personCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonFromJSON(jsonValue));
    }

    /**
     */
    async createPerson(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person> {
        const response = await this.createPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonRaw(requestParameters: GetPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonFromJSON(jsonValue));
    }

    /**
     */
    async getPerson(requestParameters: GetPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person> {
        const response = await this.getPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonSummaryRaw(requestParameters: GetPersonSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonSummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/{id}/summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getPersonSummary(requestParameters: GetPersonSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonSummary> {
        const response = await this.getPersonSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryPeopleRaw(requestParameters: QueryPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonQueryResult>> {
        if (requestParameters.personQuery === null || requestParameters.personQuery === undefined) {
            throw new runtime.RequiredError('personQuery','Required parameter requestParameters.personQuery was null or undefined when calling queryPeople.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonQueryToJSON(requestParameters.personQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryPeople(requestParameters: QueryPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonQueryResult> {
        const response = await this.queryPeopleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonRaw(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Person>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePerson.');
        }

        if (requestParameters.personUpdate === null || requestParameters.personUpdate === undefined) {
            throw new runtime.RequiredError('personUpdate','Required parameter requestParameters.personUpdate was null or undefined when calling updatePerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonUpdateToJSON(requestParameters.personUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonFromJSON(jsonValue));
    }

    /**
     */
    async updatePerson(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Person> {
        const response = await this.updatePersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
