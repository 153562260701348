/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpportunityStatusReason {
    Test = 'Test',
    EncodingError = 'EncodingError',
    UninterestingZone = 'UninterestingZone',
    RealEstateDeveloperOwnership = 'RealEstateDeveloperOwnership',
    StateOwnership = 'StateOwnership',
    InterestCommitteeRefusal = 'InterestCommitteeRefusal',
    OwnerNotFound = 'OwnerNotFound',
    NonSellingOwner = 'NonSellingOwner',
    TechnicalInconsistency = 'TechnicalInconsistency',
    NotProfitable = 'NotProfitable',
    PricedOfferedTooLow = 'PricedOfferedTooLow',
    SoldToCompetitor = 'SoldToCompetitor',
    NotSuitableSellingConditions = 'NotSuitableSellingConditions',
    OwnerWithdrawal = 'OwnerWithdrawal',
    NoBuyer = 'NoBuyer'
}


export function OpportunityStatusReasonFromJSON(json: any): OpportunityStatusReason {
    return OpportunityStatusReasonFromJSONTyped(json, false);
}

export function OpportunityStatusReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityStatusReason {
    return json as OpportunityStatusReason;
}

export function OpportunityStatusReasonToJSON(value?: OpportunityStatusReason | null): any {
    return value as any;
}

