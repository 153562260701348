/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityRepartitionSurface
 */
export interface OpportunityRepartitionSurface {
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionSurface
     */
    annexesSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionSurface
     */
    cadastralSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof OpportunityRepartitionSurface
     */
    givenSurface?: OpportunityRepartitionSurfaceGivenSurfaceEnum;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionSurface
     */
    grossBuildingSurfaceAboveGround?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionSurface
     */
    salableNetSurface?: number;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityRepartitionSurfaceGivenSurfaceEnum {
    Gross = 'gross',
    Net = 'net'
}


/**
 * Check if a given object implements the OpportunityRepartitionSurface interface.
 */
export function instanceOfOpportunityRepartitionSurface(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityRepartitionSurfaceFromJSON(json: any): OpportunityRepartitionSurface {
    return OpportunityRepartitionSurfaceFromJSONTyped(json, false);
}

export function OpportunityRepartitionSurfaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityRepartitionSurface {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annexesSurface': !exists(json, 'annexesSurface') ? undefined : json['annexesSurface'],
        'cadastralSurface': !exists(json, 'cadastralSurface') ? undefined : json['cadastralSurface'],
        'givenSurface': !exists(json, 'givenSurface') ? undefined : json['givenSurface'],
        'grossBuildingSurfaceAboveGround': !exists(json, 'grossBuildingSurfaceAboveGround') ? undefined : json['grossBuildingSurfaceAboveGround'],
        'salableNetSurface': !exists(json, 'salableNetSurface') ? undefined : json['salableNetSurface'],
    };
}

export function OpportunityRepartitionSurfaceToJSON(value?: OpportunityRepartitionSurface | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annexesSurface': value.annexesSurface,
        'cadastralSurface': value.cadastralSurface,
        'givenSurface': value.givenSurface,
        'grossBuildingSurfaceAboveGround': value.grossBuildingSurfaceAboveGround,
        'salableNetSurface': value.salableNetSurface,
    };
}

