/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Activity,
  CommentCreate,
  Opportunity,
  OpportunityCreate,
  OpportunityPreliminaryUrbanismStudy,
  OpportunityPreliminaryUrbanismStudyCreate,
  OpportunityPreliminaryUrbanismStudyUpdate,
  OpportunityQuery,
  OpportunityQueryResult,
  OpportunityStateUpdate,
  OpportunitySummary,
  OpportunityUpdate,
} from '../models';
import {
    ActivityFromJSON,
    ActivityToJSON,
    CommentCreateFromJSON,
    CommentCreateToJSON,
    OpportunityFromJSON,
    OpportunityToJSON,
    OpportunityCreateFromJSON,
    OpportunityCreateToJSON,
    OpportunityPreliminaryUrbanismStudyFromJSON,
    OpportunityPreliminaryUrbanismStudyToJSON,
    OpportunityPreliminaryUrbanismStudyCreateFromJSON,
    OpportunityPreliminaryUrbanismStudyCreateToJSON,
    OpportunityPreliminaryUrbanismStudyUpdateFromJSON,
    OpportunityPreliminaryUrbanismStudyUpdateToJSON,
    OpportunityQueryFromJSON,
    OpportunityQueryToJSON,
    OpportunityQueryResultFromJSON,
    OpportunityQueryResultToJSON,
    OpportunityStateUpdateFromJSON,
    OpportunityStateUpdateToJSON,
    OpportunitySummaryFromJSON,
    OpportunitySummaryToJSON,
    OpportunityUpdateFromJSON,
    OpportunityUpdateToJSON,
} from '../models';

export interface CreateNewCommentForOpportunityRequest {
    id: string;
    commentCreate: CommentCreate;
}

export interface CreateOpportunityRequest {
    opportunityCreate: OpportunityCreate;
}

export interface CreateOpportunityPreliminaryUrbanismStudyRequest {
    id: string;
    opportunityPreliminaryUrbanismStudyCreate: OpportunityPreliminaryUrbanismStudyCreate;
}

export interface DeleteOpportunityPreliminaryUrbanismStudyRequest {
    id: string;
    studyId: string;
}

export interface GetOpportunityRequest {
    id: string;
}

export interface GetOpportunityActivitiesRequest {
    id: string;
}

export interface GetOpportunityPreliminaryUrbanismStudiesRequest {
    id: string;
}

export interface GetOpportunitySummaryRequest {
    id: string;
}

export interface QueryOpportunitiesRequest {
    opportunityQuery: OpportunityQuery;
}

export interface UpdateOpportunityRequest {
    id: string;
    opportunityUpdate: OpportunityUpdate;
}

export interface UpdateOpportunityPreliminaryUrbanismStudyRequest {
    id: string;
    studyId: string;
    opportunityPreliminaryUrbanismStudyUpdate: OpportunityPreliminaryUrbanismStudyUpdate;
}

export interface UpdateOpportunityStateRequest {
    id: string;
    opportunityStateUpdate: OpportunityStateUpdate;
}

/**
 * OpportunityApi - interface
 * 
 * @export
 * @interface OpportunityApiInterface
 */
export interface OpportunityApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {CommentCreate} commentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    createNewCommentForOpportunityRaw(requestParameters: CreateNewCommentForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Activity>>;

    /**
     */
    createNewCommentForOpportunity(requestParameters: CreateNewCommentForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Activity>;

    /**
     * 
     * @param {OpportunityCreate} opportunityCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    createOpportunityRaw(requestParameters: CreateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>>;

    /**
     */
    createOpportunity(requestParameters: CreateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity>;

    /**
     * 
     * @param {string} id 
     * @param {OpportunityPreliminaryUrbanismStudyCreate} opportunityPreliminaryUrbanismStudyCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    createOpportunityPreliminaryUrbanismStudyRaw(requestParameters: CreateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>>;

    /**
     */
    createOpportunityPreliminaryUrbanismStudy(requestParameters: CreateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy>;

    /**
     * 
     * @param {string} id 
     * @param {string} studyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    deleteOpportunityPreliminaryUrbanismStudyRaw(requestParameters: DeleteOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>>;

    /**
     */
    deleteOpportunityPreliminaryUrbanismStudy(requestParameters: DeleteOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    getOpportunityRaw(requestParameters: GetOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>>;

    /**
     */
    getOpportunity(requestParameters: GetOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    getOpportunityActivitiesRaw(requestParameters: GetOpportunityActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Activity>>>;

    /**
     */
    getOpportunityActivities(requestParameters: GetOpportunityActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Activity>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    getOpportunityPreliminaryUrbanismStudiesRaw(requestParameters: GetOpportunityPreliminaryUrbanismStudiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OpportunityPreliminaryUrbanismStudy>>>;

    /**
     */
    getOpportunityPreliminaryUrbanismStudies(requestParameters: GetOpportunityPreliminaryUrbanismStudiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OpportunityPreliminaryUrbanismStudy>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    getOpportunitySummaryRaw(requestParameters: GetOpportunitySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunitySummary>>;

    /**
     */
    getOpportunitySummary(requestParameters: GetOpportunitySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunitySummary>;

    /**
     * 
     * @param {OpportunityQuery} opportunityQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    queryOpportunitiesRaw(requestParameters: QueryOpportunitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityQueryResult>>;

    /**
     */
    queryOpportunities(requestParameters: QueryOpportunitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityQueryResult>;

    /**
     * 
     * @param {string} id 
     * @param {OpportunityUpdate} opportunityUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    updateOpportunityRaw(requestParameters: UpdateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>>;

    /**
     */
    updateOpportunity(requestParameters: UpdateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity>;

    /**
     * 
     * @param {string} id 
     * @param {string} studyId 
     * @param {OpportunityPreliminaryUrbanismStudyUpdate} opportunityPreliminaryUrbanismStudyUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    updateOpportunityPreliminaryUrbanismStudyRaw(requestParameters: UpdateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>>;

    /**
     */
    updateOpportunityPreliminaryUrbanismStudy(requestParameters: UpdateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy>;

    /**
     * 
     * @param {string} id 
     * @param {OpportunityStateUpdate} opportunityStateUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpportunityApiInterface
     */
    updateOpportunityStateRaw(requestParameters: UpdateOpportunityStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunitySummary>>;

    /**
     */
    updateOpportunityState(requestParameters: UpdateOpportunityStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunitySummary>;

}

/**
 * 
 */
export class OpportunityApi extends runtime.BaseAPI implements OpportunityApiInterface {

    /**
     */
    async createNewCommentForOpportunityRaw(requestParameters: CreateNewCommentForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createNewCommentForOpportunity.');
        }

        if (requestParameters.commentCreate === null || requestParameters.commentCreate === undefined) {
            throw new runtime.RequiredError('commentCreate','Required parameter requestParameters.commentCreate was null or undefined when calling createNewCommentForOpportunity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentCreateToJSON(requestParameters.commentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async createNewCommentForOpportunity(requestParameters: CreateNewCommentForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Activity> {
        const response = await this.createNewCommentForOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOpportunityRaw(requestParameters: CreateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>> {
        if (requestParameters.opportunityCreate === null || requestParameters.opportunityCreate === undefined) {
            throw new runtime.RequiredError('opportunityCreate','Required parameter requestParameters.opportunityCreate was null or undefined when calling createOpportunity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityCreateToJSON(requestParameters.opportunityCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityFromJSON(jsonValue));
    }

    /**
     */
    async createOpportunity(requestParameters: CreateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity> {
        const response = await this.createOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOpportunityPreliminaryUrbanismStudyRaw(requestParameters: CreateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createOpportunityPreliminaryUrbanismStudy.');
        }

        if (requestParameters.opportunityPreliminaryUrbanismStudyCreate === null || requestParameters.opportunityPreliminaryUrbanismStudyCreate === undefined) {
            throw new runtime.RequiredError('opportunityPreliminaryUrbanismStudyCreate','Required parameter requestParameters.opportunityPreliminaryUrbanismStudyCreate was null or undefined when calling createOpportunityPreliminaryUrbanismStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/preliminaryUrbanismStudies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityPreliminaryUrbanismStudyCreateToJSON(requestParameters.opportunityPreliminaryUrbanismStudyCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityPreliminaryUrbanismStudyFromJSON(jsonValue));
    }

    /**
     */
    async createOpportunityPreliminaryUrbanismStudy(requestParameters: CreateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy> {
        const response = await this.createOpportunityPreliminaryUrbanismStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOpportunityPreliminaryUrbanismStudyRaw(requestParameters: DeleteOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOpportunityPreliminaryUrbanismStudy.');
        }

        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteOpportunityPreliminaryUrbanismStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/preliminaryUrbanismStudies/{studyId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityPreliminaryUrbanismStudyFromJSON(jsonValue));
    }

    /**
     */
    async deleteOpportunityPreliminaryUrbanismStudy(requestParameters: DeleteOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy> {
        const response = await this.deleteOpportunityPreliminaryUrbanismStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunityRaw(requestParameters: GetOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOpportunity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityFromJSON(jsonValue));
    }

    /**
     */
    async getOpportunity(requestParameters: GetOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity> {
        const response = await this.getOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunityActivitiesRaw(requestParameters: GetOpportunityActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Activity>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOpportunityActivities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/activities`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFromJSON));
    }

    /**
     */
    async getOpportunityActivities(requestParameters: GetOpportunityActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Activity>> {
        const response = await this.getOpportunityActivitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunityPreliminaryUrbanismStudiesRaw(requestParameters: GetOpportunityPreliminaryUrbanismStudiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OpportunityPreliminaryUrbanismStudy>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOpportunityPreliminaryUrbanismStudies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/preliminaryUrbanismStudies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OpportunityPreliminaryUrbanismStudyFromJSON));
    }

    /**
     */
    async getOpportunityPreliminaryUrbanismStudies(requestParameters: GetOpportunityPreliminaryUrbanismStudiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OpportunityPreliminaryUrbanismStudy>> {
        const response = await this.getOpportunityPreliminaryUrbanismStudiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunitySummaryRaw(requestParameters: GetOpportunitySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunitySummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOpportunitySummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunitySummaryFromJSON(jsonValue));
    }

    /**
     */
    async getOpportunitySummary(requestParameters: GetOpportunitySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunitySummary> {
        const response = await this.getOpportunitySummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryOpportunitiesRaw(requestParameters: QueryOpportunitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityQueryResult>> {
        if (requestParameters.opportunityQuery === null || requestParameters.opportunityQuery === undefined) {
            throw new runtime.RequiredError('opportunityQuery','Required parameter requestParameters.opportunityQuery was null or undefined when calling queryOpportunities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityQueryToJSON(requestParameters.opportunityQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryOpportunities(requestParameters: QueryOpportunitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityQueryResult> {
        const response = await this.queryOpportunitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOpportunityRaw(requestParameters: UpdateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Opportunity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOpportunity.');
        }

        if (requestParameters.opportunityUpdate === null || requestParameters.opportunityUpdate === undefined) {
            throw new runtime.RequiredError('opportunityUpdate','Required parameter requestParameters.opportunityUpdate was null or undefined when calling updateOpportunity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityUpdateToJSON(requestParameters.opportunityUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityFromJSON(jsonValue));
    }

    /**
     */
    async updateOpportunity(requestParameters: UpdateOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Opportunity> {
        const response = await this.updateOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOpportunityPreliminaryUrbanismStudyRaw(requestParameters: UpdateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunityPreliminaryUrbanismStudy>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOpportunityPreliminaryUrbanismStudy.');
        }

        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateOpportunityPreliminaryUrbanismStudy.');
        }

        if (requestParameters.opportunityPreliminaryUrbanismStudyUpdate === null || requestParameters.opportunityPreliminaryUrbanismStudyUpdate === undefined) {
            throw new runtime.RequiredError('opportunityPreliminaryUrbanismStudyUpdate','Required parameter requestParameters.opportunityPreliminaryUrbanismStudyUpdate was null or undefined when calling updateOpportunityPreliminaryUrbanismStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/preliminaryUrbanismStudies/{studyId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"studyId"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityPreliminaryUrbanismStudyUpdateToJSON(requestParameters.opportunityPreliminaryUrbanismStudyUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunityPreliminaryUrbanismStudyFromJSON(jsonValue));
    }

    /**
     */
    async updateOpportunityPreliminaryUrbanismStudy(requestParameters: UpdateOpportunityPreliminaryUrbanismStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunityPreliminaryUrbanismStudy> {
        const response = await this.updateOpportunityPreliminaryUrbanismStudyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOpportunityStateRaw(requestParameters: UpdateOpportunityStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpportunitySummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOpportunityState.');
        }

        if (requestParameters.opportunityStateUpdate === null || requestParameters.opportunityStateUpdate === undefined) {
            throw new runtime.RequiredError('opportunityStateUpdate','Required parameter requestParameters.opportunityStateUpdate was null or undefined when calling updateOpportunityState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/opportunities/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpportunityStateUpdateToJSON(requestParameters.opportunityStateUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpportunitySummaryFromJSON(jsonValue));
    }

    /**
     */
    async updateOpportunityState(requestParameters: UpdateOpportunityStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpportunitySummary> {
        const response = await this.updateOpportunityStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
