/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { OpportunityInvolvement } from './OpportunityInvolvement';
import {
    OpportunityInvolvementFromJSON,
    OpportunityInvolvementFromJSONTyped,
    OpportunityInvolvementToJSON,
} from './OpportunityInvolvement';
import type { OpportunityLocalisationSummary } from './OpportunityLocalisationSummary';
import {
    OpportunityLocalisationSummaryFromJSON,
    OpportunityLocalisationSummaryFromJSONTyped,
    OpportunityLocalisationSummaryToJSON,
} from './OpportunityLocalisationSummary';
import type { OpportunityProspectingSummary } from './OpportunityProspectingSummary';
import {
    OpportunityProspectingSummaryFromJSON,
    OpportunityProspectingSummaryFromJSONTyped,
    OpportunityProspectingSummaryToJSON,
} from './OpportunityProspectingSummary';
import type { OpportunityState } from './OpportunityState';
import {
    OpportunityStateFromJSON,
    OpportunityStateFromJSONTyped,
    OpportunityStateToJSON,
} from './OpportunityState';
import type { OpportunityStatus } from './OpportunityStatus';
import {
    OpportunityStatusFromJSON,
    OpportunityStatusFromJSONTyped,
    OpportunityStatusToJSON,
} from './OpportunityStatus';
import type { OpportunityStatusReason } from './OpportunityStatusReason';
import {
    OpportunityStatusReasonFromJSON,
    OpportunityStatusReasonFromJSONTyped,
    OpportunityStatusReasonToJSON,
} from './OpportunityStatusReason';
import type { OpportunitySummaryAllOf } from './OpportunitySummaryAllOf';
import {
    OpportunitySummaryAllOfFromJSON,
    OpportunitySummaryAllOfFromJSONTyped,
    OpportunitySummaryAllOfToJSON,
} from './OpportunitySummaryAllOf';

/**
 * 
 * @export
 * @interface OpportunitySummary
 */
export interface OpportunitySummary {
    /**
     * 
     * @type {OpportunityStatus}
     * @memberof OpportunitySummary
     */
    status: OpportunityStatus;
    /**
     * 
     * @type {OpportunityInvolvement}
     * @memberof OpportunitySummary
     */
    involvement: OpportunityInvolvement;
    /**
     * 
     * @type {number}
     * @memberof OpportunitySummary
     */
    weight: number;
    /**
     * 
     * @type {Date}
     * @memberof OpportunitySummary
     */
    nextDueDate: Date;
    /**
     * 
     * @type {Array<OpportunityStatusReason>}
     * @memberof OpportunitySummary
     */
    statusReasons?: Array<OpportunityStatusReason>;
    /**
     * 
     * @type {Metadata}
     * @memberof OpportunitySummary
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof OpportunitySummary
     */
    name: string;
    /**
     * 
     * @type {OpportunityLocalisationSummary}
     * @memberof OpportunitySummary
     */
    localisation: OpportunityLocalisationSummary;
    /**
     * 
     * @type {OpportunityProspectingSummary}
     * @memberof OpportunitySummary
     */
    prospecting?: OpportunityProspectingSummary;
}

/**
 * Check if a given object implements the OpportunitySummary interface.
 */
export function instanceOfOpportunitySummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "involvement" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "nextDueDate" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "localisation" in value;

    return isInstance;
}

export function OpportunitySummaryFromJSON(json: any): OpportunitySummary {
    return OpportunitySummaryFromJSONTyped(json, false);
}

export function OpportunitySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunitySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': OpportunityStatusFromJSON(json['status']),
        'involvement': OpportunityInvolvementFromJSON(json['involvement']),
        'weight': json['weight'],
        'nextDueDate': (new Date(json['nextDueDate'])),
        'statusReasons': !exists(json, 'statusReasons') ? undefined : ((json['statusReasons'] as Array<any>).map(OpportunityStatusReasonFromJSON)),
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'localisation': OpportunityLocalisationSummaryFromJSON(json['localisation']),
        'prospecting': !exists(json, 'prospecting') ? undefined : OpportunityProspectingSummaryFromJSON(json['prospecting']),
    };
}

export function OpportunitySummaryToJSON(value?: OpportunitySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': OpportunityStatusToJSON(value.status),
        'involvement': OpportunityInvolvementToJSON(value.involvement),
        'weight': value.weight,
        'nextDueDate': (value.nextDueDate.toISOString()),
        'statusReasons': value.statusReasons === undefined ? undefined : ((value.statusReasons as Array<any>).map(OpportunityStatusReasonToJSON)),
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'localisation': OpportunityLocalisationSummaryToJSON(value.localisation),
        'prospecting': OpportunityProspectingSummaryToJSON(value.prospecting),
    };
}

