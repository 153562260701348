/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelProtectedNaturalResource
 */
export interface ParcelProtectedNaturalResource {
    /**
     * 
     * @type {string}
     * @memberof ParcelProtectedNaturalResource
     */
    geometry?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelProtectedNaturalResource
     */
    surfaceM2?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelProtectedNaturalResource
     */
    type: ParcelProtectedNaturalResourceTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelProtectedNaturalResource
     */
    files?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum ParcelProtectedNaturalResourceTypeEnum {
    Biotope = 'Biotope',
    SpeciesHabitat = 'SpeciesHabitat'
}


/**
 * Check if a given object implements the ParcelProtectedNaturalResource interface.
 */
export function instanceOfParcelProtectedNaturalResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ParcelProtectedNaturalResourceFromJSON(json: any): ParcelProtectedNaturalResource {
    return ParcelProtectedNaturalResourceFromJSONTyped(json, false);
}

export function ParcelProtectedNaturalResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelProtectedNaturalResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometry': !exists(json, 'geometry') ? undefined : json['geometry'],
        'surfaceM2': !exists(json, 'surfaceM2') ? undefined : json['surfaceM2'],
        'type': json['type'],
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function ParcelProtectedNaturalResourceToJSON(value?: ParcelProtectedNaturalResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry': value.geometry,
        'surfaceM2': value.surfaceM2,
        'type': value.type,
        'files': value.files,
    };
}

