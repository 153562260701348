/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';

/**
 * 
 * @export
 * @interface CadastralExtractRequestCreate
 */
export interface CadastralExtractRequestCreate {
    /**
     * 
     * @type {MetadataCreate}
     * @memberof CadastralExtractRequestCreate
     */
    metadata: MetadataCreate;
    /**
     * 
     * @type {string}
     * @memberof CadastralExtractRequestCreate
     */
    opportunity: string;
}

/**
 * Check if a given object implements the CadastralExtractRequestCreate interface.
 */
export function instanceOfCadastralExtractRequestCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "opportunity" in value;

    return isInstance;
}

export function CadastralExtractRequestCreateFromJSON(json: any): CadastralExtractRequestCreate {
    return CadastralExtractRequestCreateFromJSONTyped(json, false);
}

export function CadastralExtractRequestCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CadastralExtractRequestCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCreateFromJSON(json['metadata']),
        'opportunity': json['opportunity'],
    };
}

export function CadastralExtractRequestCreateToJSON(value?: CadastralExtractRequestCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCreateToJSON(value.metadata),
        'opportunity': value.opportunity,
    };
}

