/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { UserFilterUpdate } from './UserFilterUpdate';
import {
    UserFilterUpdateFromJSON,
    UserFilterUpdateFromJSONTyped,
    UserFilterUpdateToJSON,
} from './UserFilterUpdate';
import type { UserFilterViewType } from './UserFilterViewType';
import {
    UserFilterViewTypeFromJSON,
    UserFilterViewTypeFromJSONTyped,
    UserFilterViewTypeToJSON,
} from './UserFilterViewType';
import type { UserPreferencesAllOf } from './UserPreferencesAllOf';
import {
    UserPreferencesAllOfFromJSON,
    UserPreferencesAllOfFromJSONTyped,
    UserPreferencesAllOfToJSON,
} from './UserPreferencesAllOf';

/**
 * 
 * @export
 * @interface UserFilter
 */
export interface UserFilter {
    /**
     * 
     * @type {Metadata}
     * @memberof UserFilter
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof UserFilter
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilter
     */
    affiliated?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilter
     */
    businessProviders?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilter
     */
    partners?: Array<string>;
    /**
     * 
     * @type {UserFilterViewType}
     * @memberof UserFilter
     */
    viewType?: UserFilterViewType;
}

/**
 * Check if a given object implements the UserFilter interface.
 */
export function instanceOfUserFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UserFilterFromJSON(json: any): UserFilter {
    return UserFilterFromJSONTyped(json, false);
}

export function UserFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'affiliated': !exists(json, 'affiliated') ? undefined : json['affiliated'],
        'businessProviders': !exists(json, 'businessProviders') ? undefined : json['businessProviders'],
        'partners': !exists(json, 'partners') ? undefined : json['partners'],
        'viewType': !exists(json, 'viewType') ? undefined : UserFilterViewTypeFromJSON(json['viewType']),
    };
}

export function UserFilterToJSON(value?: UserFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'affiliated': value.affiliated,
        'businessProviders': value.businessProviders,
        'partners': value.partners,
        'viewType': UserFilterViewTypeToJSON(value.viewType),
    };
}

