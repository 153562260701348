/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonAddress
 */
export interface PersonAddress {
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAddress
     */
    street?: string;
}

/**
 * Check if a given object implements the PersonAddress interface.
 */
export function instanceOfPersonAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonAddressFromJSON(json: any): PersonAddress {
    return PersonAddressFromJSONTyped(json, false);
}

export function PersonAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
    };
}

export function PersonAddressToJSON(value?: PersonAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'city': value.city,
        'locality': value.locality,
        'number': value.number,
        'postalCode': value.postalCode,
        'street': value.street,
    };
}

