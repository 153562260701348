/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuildingAlignment } from './BuildingAlignment';
import {
    BuildingAlignmentFromJSON,
    BuildingAlignmentFromJSONTyped,
    BuildingAlignmentToJSON,
} from './BuildingAlignment';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { OpportunityAllOf } from './OpportunityAllOf';
import {
    OpportunityAllOfFromJSON,
    OpportunityAllOfFromJSONTyped,
    OpportunityAllOfToJSON,
} from './OpportunityAllOf';
import type { OpportunityPreliminaryUrbanismStudyUpdate } from './OpportunityPreliminaryUrbanismStudyUpdate';
import {
    OpportunityPreliminaryUrbanismStudyUpdateFromJSON,
    OpportunityPreliminaryUrbanismStudyUpdateFromJSONTyped,
    OpportunityPreliminaryUrbanismStudyUpdateToJSON,
} from './OpportunityPreliminaryUrbanismStudyUpdate';

/**
 * 
 * @export
 * @interface OpportunityPreliminaryUrbanismStudy
 */
export interface OpportunityPreliminaryUrbanismStudy {
    /**
     * 
     * @type {Metadata}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    geometry: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    buildableGeometry: string;
    /**
     * 
     * @type {Array<BuildingAlignment>}
     * @memberof OpportunityPreliminaryUrbanismStudy
     */
    alignments: Array<BuildingAlignment>;
}

/**
 * Check if a given object implements the OpportunityPreliminaryUrbanismStudy interface.
 */
export function instanceOfOpportunityPreliminaryUrbanismStudy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "buildableGeometry" in value;
    isInstance = isInstance && "alignments" in value;

    return isInstance;
}

export function OpportunityPreliminaryUrbanismStudyFromJSON(json: any): OpportunityPreliminaryUrbanismStudy {
    return OpportunityPreliminaryUrbanismStudyFromJSONTyped(json, false);
}

export function OpportunityPreliminaryUrbanismStudyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityPreliminaryUrbanismStudy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'geometry': json['geometry'],
        'buildableGeometry': json['buildableGeometry'],
        'alignments': ((json['alignments'] as Array<any>).map(BuildingAlignmentFromJSON)),
    };
}

export function OpportunityPreliminaryUrbanismStudyToJSON(value?: OpportunityPreliminaryUrbanismStudy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'description': value.description,
        'geometry': value.geometry,
        'buildableGeometry': value.buildableGeometry,
        'alignments': ((value.alignments as Array<any>).map(BuildingAlignmentToJSON)),
    };
}

