/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileLocationCreateAllOf } from './FileLocationCreateAllOf';
import {
    FileLocationCreateAllOfFromJSON,
    FileLocationCreateAllOfFromJSONTyped,
    FileLocationCreateAllOfToJSON,
} from './FileLocationCreateAllOf';
import type { FileLocationUpdate } from './FileLocationUpdate';
import {
    FileLocationUpdateFromJSON,
    FileLocationUpdateFromJSONTyped,
    FileLocationUpdateToJSON,
} from './FileLocationUpdate';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';

/**
 * 
 * @export
 * @interface FileLocationCreate
 */
export interface FileLocationCreate {
    /**
     * 
     * @type {MetadataCreate}
     * @memberof FileLocationCreate
     */
    metadata: MetadataCreate;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileLocationCreate
     */
    path: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileLocationCreate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocationCreate
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FileLocationCreate
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof FileLocationCreate
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof FileLocationCreate
     */
    checksum?: string;
}

/**
 * Check if a given object implements the FileLocationCreate interface.
 */
export function instanceOfFileLocationCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function FileLocationCreateFromJSON(json: any): FileLocationCreate {
    return FileLocationCreateFromJSONTyped(json, false);
}

export function FileLocationCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileLocationCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCreateFromJSON(json['metadata']),
        'path': json['path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mimeType': json['mimeType'],
        'size': json['size'],
        'checksum': !exists(json, 'checksum') ? undefined : json['checksum'],
    };
}

export function FileLocationCreateToJSON(value?: FileLocationCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCreateToJSON(value.metadata),
        'path': value.path,
        'description': value.description,
        'type': value.type,
        'mimeType': value.mimeType,
        'size': value.size,
        'checksum': value.checksum,
    };
}

