/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParsedLocation } from './ParsedLocation';
import {
    ParsedLocationFromJSON,
    ParsedLocationFromJSONTyped,
    ParsedLocationToJSON,
} from './ParsedLocation';
import type { ParsedOwnerOrGroup } from './ParsedOwnerOrGroup';
import {
    ParsedOwnerOrGroupFromJSON,
    ParsedOwnerOrGroupFromJSONTyped,
    ParsedOwnerOrGroupToJSON,
} from './ParsedOwnerOrGroup';

/**
 * 
 * @export
 * @interface ParsedOwnerGroup
 */
export interface ParsedOwnerGroup {
    /**
     * 
     * @type {string}
     * @memberof ParsedOwnerGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwnerGroup
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParsedOwnerGroup
     */
    groups: Array<string>;
    /**
     * 
     * @type {ParsedLocation}
     * @memberof ParsedOwnerGroup
     */
    location: ParsedLocation;
}

/**
 * Check if a given object implements the ParsedOwnerGroup interface.
 */
export function instanceOfParsedOwnerGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function ParsedOwnerGroupFromJSON(json: any): ParsedOwnerGroup {
    return ParsedOwnerGroupFromJSONTyped(json, false);
}

export function ParsedOwnerGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedOwnerGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'groups': json['groups'],
        'location': ParsedLocationFromJSON(json['location']),
    };
}

export function ParsedOwnerGroupToJSON(value?: ParsedOwnerGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'groups': value.groups,
        'location': ParsedLocationToJSON(value.location),
    };
}

