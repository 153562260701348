import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import axios from "axios";
import {FirebaseOptions} from "@firebase/app";

type AppInfo = {
    name: string,
    realm: {
        id: string,
    },
    cluster: {
        url: string
    },
    firebaseConfig: FirebaseOptions
}

type AppInfoContext = {
    appInfo: AppInfo|undefined,
    setAppInfo: (app: AppInfo) => void
}

const appInfoContext = createContext<AppInfoContext>({
    appInfo: undefined,
    setAppInfo: () => {}
})

const getAppInfo = async() => {
    const {data} = await axios.get(`/api/app/preInitConfig?v=${process.env.REACT_APP_COMMIT_HASH || new Date().toISOString()}`, {responseType: "json"});
    return {...data} as AppInfo;
}

export function AppPreInitConfigProvider({children}: {children: ReactNode}) {
    const [appInfo, setAppInfo] = useState<AppInfo|undefined>(undefined);

    useEffect(() => {
        getAppInfo().then(setAppInfo);
    }, []);

    return <appInfoContext.Provider value={{appInfo, setAppInfo}}>
        <>{appInfo && children}</>
    </appInfoContext.Provider>
}

export const useRealmAppId = () => {
    const context = useContext(appInfoContext);
    return context.appInfo!.realm.id;
}

export const useClusterUrl = () => {
    const context = useContext(appInfoContext);
    return context.appInfo!.cluster.url;
}

export const useFirebaseConfig = () => {
    const context = useContext(appInfoContext);
    return context.appInfo!.firebaseConfig;
}