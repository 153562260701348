/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityDocument
 */
export interface OpportunityDocument {
    /**
     * 
     * @type {string}
     * @memberof OpportunityDocument
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityDocument
     */
    type: OpportunityDocumentTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityDocumentTypeEnum {
    CadastralExtracts = 'CadastralExtracts',
    GeneralDevelopmentPlanGraphicalPart = 'GeneralDevelopmentPlanGraphicalPart',
    GeneralDevelopmentPlanWrittenPart = 'GeneralDevelopmentPlanWrittenPart',
    NewDistrictDevelopmentPlanGraphicalPart = 'NewDistrictDevelopmentPlanGraphicalPart',
    NewDistrictDevelopmentPlanWrittenPart = 'NewDistrictDevelopmentPlanWrittenPart',
    SpecificDevelopmentPlanGraphicalPart = 'SpecificDevelopmentPlanGraphicalPart',
    SpecificDevelopmentPlanWrittenPart = 'SpecificDevelopmentPlanWrittenPart',
    ExecutionAgreement = 'ExecutionAgreement',
    Sketches = 'Sketches',
    AuthorizedPlans = 'AuthorizedPlans',
    Other = 'Other',
    DataRoomAccess = 'DataRoomAccess',
    VariousRoadAndNetworkExecutionPlan = 'VariousRoadAndNetworkExecutionPlan',
    PreliminaryPlans = 'PreliminaryPlans',
    BuildingPermitPlans = 'BuildingPermitPlans'
}


/**
 * Check if a given object implements the OpportunityDocument interface.
 */
export function instanceOfOpportunityDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function OpportunityDocumentFromJSON(json: any): OpportunityDocument {
    return OpportunityDocumentFromJSONTyped(json, false);
}

export function OpportunityDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': json['type'],
    };
}

export function OpportunityDocumentToJSON(value?: OpportunityDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'type': value.type,
    };
}

