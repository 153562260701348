/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PeopleRelationshipType } from './PeopleRelationshipType';
import {
    PeopleRelationshipTypeFromJSON,
    PeopleRelationshipTypeFromJSONTyped,
    PeopleRelationshipTypeToJSON,
} from './PeopleRelationshipType';

/**
 * 
 * @export
 * @interface PeopleRelationshipQueryFilter
 */
export interface PeopleRelationshipQueryFilter {
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipQueryFilter
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipQueryFilter
     */
    child?: string;
    /**
     * 
     * @type {PeopleRelationshipType}
     * @memberof PeopleRelationshipQueryFilter
     */
    type?: PeopleRelationshipType;
}

/**
 * Check if a given object implements the PeopleRelationshipQueryFilter interface.
 */
export function instanceOfPeopleRelationshipQueryFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PeopleRelationshipQueryFilterFromJSON(json: any): PeopleRelationshipQueryFilter {
    return PeopleRelationshipQueryFilterFromJSONTyped(json, false);
}

export function PeopleRelationshipQueryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeopleRelationshipQueryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'child': !exists(json, 'child') ? undefined : json['child'],
        'type': !exists(json, 'type') ? undefined : PeopleRelationshipTypeFromJSON(json['type']),
    };
}

export function PeopleRelationshipQueryFilterToJSON(value?: PeopleRelationshipQueryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent': value.parent,
        'child': value.child,
        'type': PeopleRelationshipTypeToJSON(value.type),
    };
}

