/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileStatus {
    Created = 'Created',
    Uploading = 'Uploading',
    Uploaded = 'Uploaded',
    Error = 'Error'
}


export function FileStatusFromJSON(json: any): FileStatus {
    return FileStatusFromJSONTyped(json, false);
}

export function FileStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileStatus {
    return json as FileStatus;
}

export function FileStatusToJSON(value?: FileStatus | null): any {
    return value as any;
}

