/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShareRatio
 */
export interface ShareRatio {
    /**
     * 
     * @type {number}
     * @memberof ShareRatio
     */
    numerator: number;
    /**
     * 
     * @type {number}
     * @memberof ShareRatio
     */
    denominator: number;
    /**
     * 
     * @type {number}
     * @memberof ShareRatio
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof ShareRatio
     */
    type: ShareRatioTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ShareRatioTypeEnum {
    FullOwnership = 'FullOwnership',
    BareOwnership = 'BareOwnership',
    Usufruct = 'Usufruct',
    Emphyteusis = 'Emphyteusis',
    Subsurface = 'Subsurface',
    Superficiary = 'Superficiary'
}


/**
 * Check if a given object implements the ShareRatio interface.
 */
export function instanceOfShareRatio(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numerator" in value;
    isInstance = isInstance && "denominator" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ShareRatioFromJSON(json: any): ShareRatio {
    return ShareRatioFromJSONTyped(json, false);
}

export function ShareRatioFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareRatio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numerator': json['numerator'],
        'denominator': json['denominator'],
        'value': json['value'],
        'type': json['type'],
    };
}

export function ShareRatioToJSON(value?: ShareRatio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numerator': value.numerator,
        'denominator': value.denominator,
        'value': value.value,
        'type': value.type,
    };
}

