/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserFilterViewType {
    Full = 'Full',
    Presentation = 'Presentation'
}


export function UserFilterViewTypeFromJSON(json: any): UserFilterViewType {
    return UserFilterViewTypeFromJSONTyped(json, false);
}

export function UserFilterViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFilterViewType {
    return json as UserFilterViewType;
}

export function UserFilterViewTypeToJSON(value?: UserFilterViewType | null): any {
    return value as any;
}

