/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonQueryAllOf } from './PersonQueryAllOf';
import {
    PersonQueryAllOfFromJSON,
    PersonQueryAllOfFromJSONTyped,
    PersonQueryAllOfToJSON,
} from './PersonQueryAllOf';
import type { PersonQueryFilter } from './PersonQueryFilter';
import {
    PersonQueryFilterFromJSON,
    PersonQueryFilterFromJSONTyped,
    PersonQueryFilterToJSON,
} from './PersonQueryFilter';
import type { Query } from './Query';
import {
    QueryFromJSON,
    QueryFromJSONTyped,
    QueryToJSON,
} from './Query';

/**
 * 
 * @export
 * @interface PersonQuery
 */
export interface PersonQuery {
    /**
     * 
     * @type {number}
     * @memberof PersonQuery
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonQuery
     */
    page?: number;
    /**
     * 
     * @type {PersonQueryFilter}
     * @memberof PersonQuery
     */
    filter: PersonQueryFilter;
}

/**
 * Check if a given object implements the PersonQuery interface.
 */
export function instanceOfPersonQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function PersonQueryFromJSON(json: any): PersonQuery {
    return PersonQueryFromJSONTyped(json, false);
}

export function PersonQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'filter': PersonQueryFilterFromJSON(json['filter']),
    };
}

export function PersonQueryToJSON(value?: PersonQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'page': value.page,
        'filter': PersonQueryFilterToJSON(value.filter),
    };
}

