/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityProspectingAllOf } from './OpportunityProspectingAllOf';
import {
    OpportunityProspectingAllOfFromJSON,
    OpportunityProspectingAllOfFromJSONTyped,
    OpportunityProspectingAllOfToJSON,
} from './OpportunityProspectingAllOf';
import type { OpportunityProspectingBusinessProviderOption } from './OpportunityProspectingBusinessProviderOption';
import {
    OpportunityProspectingBusinessProviderOptionFromJSON,
    OpportunityProspectingBusinessProviderOptionFromJSONTyped,
    OpportunityProspectingBusinessProviderOptionToJSON,
} from './OpportunityProspectingBusinessProviderOption';
import type { OpportunityProspectingSummary } from './OpportunityProspectingSummary';
import {
    OpportunityProspectingSummaryFromJSON,
    OpportunityProspectingSummaryFromJSONTyped,
    OpportunityProspectingSummaryToJSON,
} from './OpportunityProspectingSummary';

/**
 * 
 * @export
 * @interface OpportunityProspecting
 */
export interface OpportunityProspecting {
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspecting
     */
    partner?: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspecting
     */
    delegateAmbassador?: string;
    /**
     * 
     * @type {OpportunityProspectingBusinessProviderOption}
     * @memberof OpportunityProspecting
     */
    businessProviderOptions?: OpportunityProspectingBusinessProviderOption;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpportunityProspecting
     */
    intermediaries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspecting
     */
    type: OpportunityProspectingTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityProspectingTypeEnum {
    BusinessProvider = 'BusinessProvider',
    Direct = 'Direct'
}


/**
 * Check if a given object implements the OpportunityProspecting interface.
 */
export function instanceOfOpportunityProspecting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function OpportunityProspectingFromJSON(json: any): OpportunityProspecting {
    return OpportunityProspectingFromJSONTyped(json, false);
}

export function OpportunityProspectingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityProspecting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partner': !exists(json, 'partner') ? undefined : json['partner'],
        'delegateAmbassador': !exists(json, 'delegateAmbassador') ? undefined : json['delegateAmbassador'],
        'businessProviderOptions': !exists(json, 'businessProviderOptions') ? undefined : OpportunityProspectingBusinessProviderOptionFromJSON(json['businessProviderOptions']),
        'intermediaries': !exists(json, 'intermediaries') ? undefined : json['intermediaries'],
        'type': json['type'],
    };
}

export function OpportunityProspectingToJSON(value?: OpportunityProspecting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partner': value.partner,
        'delegateAmbassador': value.delegateAmbassador,
        'businessProviderOptions': OpportunityProspectingBusinessProviderOptionToJSON(value.businessProviderOptions),
        'intermediaries': value.intermediaries,
        'type': value.type,
    };
}

