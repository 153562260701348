import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import shape from "./shape";
import shadows, {customShadows} from "./shadows";
import componentsOverride from './overrides';
import {CssBaseline} from '@mui/material';
import {createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider as MuiThemeProvider} from '@mui/material';
import {ReactElement, ReactNode} from "react";
import {frFR} from '@mui/material/locale';
import GlobalStyles from "./globalStyles";
import {ProgressBarStyle} from "./LoadingScreen";

import 'simplebar-react/dist/simplebar.min.css';

const themeOptions: ThemeOptions = {
    palette: { ...palette.light, mode: 'light' },
    shape,
    typography,
    breakpoints,
    direction: 'ltr',
    shadows: shadows.light,
    customShadows: customShadows.light
};

const theme = createTheme(themeOptions, frFR);
theme.components = componentsOverride(theme);

export function ThemeProvider(props: {children: ReactNode}): ReactElement<any, any> {
    const {children} = props;
    return <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <GlobalStyles />
            <ProgressBarStyle />
            {children}
        </MuiThemeProvider>
    </StyledEngineProvider>
};