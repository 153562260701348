import {createContext, FunctionComponent, PropsWithChildren, useContext, useMemo, useState} from "react";
import {initializeApp} from 'firebase/app';
import {FirebaseApp} from "@firebase/app";
import {Auth, getAuth} from "firebase/auth";
import {useFirebaseConfig} from "./AppPreInitConfigProvider";
import { getMessaging, Messaging } from "firebase/messaging";

type FirebaseContext = {
    firebaseApp: FirebaseApp,
    auth: Auth,
    messaging: Messaging
}

const firebaseContext = createContext<FirebaseContext>({
    firebaseApp: null as unknown as FirebaseApp,
    auth: null as unknown as Auth,
    messaging: null as unknown as Messaging,
});

export const FirebaseProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
    const config = useFirebaseConfig();
    const [initialized, setInitialized] = useState(false);
    const firebaseApp = useMemo(() => initializeApp(config), []);
    const auth = getAuth(firebaseApp);
    const messaging = getMessaging(firebaseApp);
    auth.onAuthStateChanged(() => setInitialized(true));
    return <firebaseContext.Provider value={{firebaseApp, auth, messaging}}>{initialized && children}</firebaseContext.Provider>;
}

export const useFirebase = (): FirebaseApp => {
    const context = useContext(firebaseContext);
    return context.firebaseApp;
}

export const useFirebaseAuth = (): Auth => {
    const context = useContext(firebaseContext);
    return context.auth;
}

export const useFirebaseMessaging = (): Messaging => {
    const context = useContext(firebaseContext);
    return context.messaging;
}