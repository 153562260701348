/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalId } from './ExternalId';
import {
    ExternalIdFromJSON,
    ExternalIdFromJSONTyped,
    ExternalIdToJSON,
} from './ExternalId';
import type { MetadataAllOf } from './MetadataAllOf';
import {
    MetadataAllOfFromJSON,
    MetadataAllOfFromJSONTyped,
    MetadataAllOfToJSON,
} from './MetadataAllOf';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';

/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    revisionNumber: number;
    /**
     * 
     * @type {Array<ExternalId>}
     * @memberof Metadata
     */
    externalIds?: Array<ExternalId>;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    mergedWith?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    creator: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    dataModelVersion?: string;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    copyNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    copiedFrom?: string;
    /**
     * 
     * @type {Date}
     * @memberof Metadata
     */
    readonly creationTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof Metadata
     */
    readonly updateTime: Date;
}

/**
 * Check if a given object implements the Metadata interface.
 */
export function instanceOfMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "revisionNumber" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTime" in value;
    isInstance = isInstance && "updateTime" in value;

    return isInstance;
}

export function MetadataFromJSON(json: any): Metadata {
    return MetadataFromJSONTyped(json, false);
}

export function MetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'revisionNumber': json['revisionNumber'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdFromJSON)),
        'mergedWith': !exists(json, 'mergedWith') ? undefined : json['mergedWith'],
        'creator': json['creator'],
        'dataModelVersion': !exists(json, 'dataModelVersion') ? undefined : json['dataModelVersion'],
        'copyNumber': !exists(json, 'copyNumber') ? undefined : json['copyNumber'],
        'copiedFrom': !exists(json, 'copiedFrom') ? undefined : json['copiedFrom'],
        'creationTime': (new Date(json['creationTime'])),
        'updateTime': (new Date(json['updateTime'])),
    };
}

export function MetadataToJSON(value?: Metadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'revisionNumber': value.revisionNumber,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdToJSON)),
        'mergedWith': value.mergedWith,
        'creator': value.creator,
        'dataModelVersion': value.dataModelVersion,
        'copyNumber': value.copyNumber,
        'copiedFrom': value.copiedFrom,
    };
}

