/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityProspectingBusinessProviderOptionAllOf } from './OpportunityProspectingBusinessProviderOptionAllOf';
import {
    OpportunityProspectingBusinessProviderOptionAllOfFromJSON,
    OpportunityProspectingBusinessProviderOptionAllOfFromJSONTyped,
    OpportunityProspectingBusinessProviderOptionAllOfToJSON,
} from './OpportunityProspectingBusinessProviderOptionAllOf';
import type { OpportunityProspectingBusinessProviderOptionSummary } from './OpportunityProspectingBusinessProviderOptionSummary';
import {
    OpportunityProspectingBusinessProviderOptionSummaryFromJSON,
    OpportunityProspectingBusinessProviderOptionSummaryFromJSONTyped,
    OpportunityProspectingBusinessProviderOptionSummaryToJSON,
} from './OpportunityProspectingBusinessProviderOptionSummary';

/**
 * 
 * @export
 * @interface OpportunityProspectingBusinessProviderOption
 */
export interface OpportunityProspectingBusinessProviderOption {
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    commissionType?: OpportunityProspectingBusinessProviderOptionCommissionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    commissionValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    hasOwnerContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    isThereSignedNDA?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    ndaSignatureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    ndaTerms?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpportunityProspectingBusinessProviderOption
     */
    profitSharing?: Array<OpportunityProspectingBusinessProviderOptionProfitSharingEnum>;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityProspectingBusinessProviderOptionCommissionTypeEnum {
    SharedRatio = 'SharedRatio',
    Package = 'Package',
    Other = 'Other'
}
/**
* @export
* @enum {string}
*/
export enum OpportunityProspectingBusinessProviderOptionProfitSharingEnum {
    Development = 'Development',
    Profit = 'Profit',
    Sale = 'Sale'
}


/**
 * Check if a given object implements the OpportunityProspectingBusinessProviderOption interface.
 */
export function instanceOfOpportunityProspectingBusinessProviderOption(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityProspectingBusinessProviderOptionFromJSON(json: any): OpportunityProspectingBusinessProviderOption {
    return OpportunityProspectingBusinessProviderOptionFromJSONTyped(json, false);
}

export function OpportunityProspectingBusinessProviderOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityProspectingBusinessProviderOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'commissionType': !exists(json, 'commissionType') ? undefined : json['commissionType'],
        'commissionValue': !exists(json, 'commissionValue') ? undefined : json['commissionValue'],
        'hasOwnerContact': !exists(json, 'hasOwnerContact') ? undefined : json['hasOwnerContact'],
        'isThereSignedNDA': !exists(json, 'isThereSignedNDA') ? undefined : json['isThereSignedNDA'],
        'ndaSignatureDate': !exists(json, 'ndaSignatureDate') ? undefined : (new Date(json['ndaSignatureDate'])),
        'ndaTerms': !exists(json, 'ndaTerms') ? undefined : json['ndaTerms'],
        'profitSharing': !exists(json, 'profitSharing') ? undefined : json['profitSharing'],
    };
}

export function OpportunityProspectingBusinessProviderOptionToJSON(value?: OpportunityProspectingBusinessProviderOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'commissionType': value.commissionType,
        'commissionValue': value.commissionValue,
        'hasOwnerContact': value.hasOwnerContact,
        'isThereSignedNDA': value.isThereSignedNDA,
        'ndaSignatureDate': value.ndaSignatureDate === undefined ? undefined : (value.ndaSignatureDate.toISOString().substr(0,10)),
        'ndaTerms': value.ndaTerms,
        'profitSharing': value.profitSharing,
    };
}

