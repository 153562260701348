/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelPrimaryMasterPlans
 */
export interface ParcelPrimaryMasterPlans {
    /**
     * 
     * @type {string}
     * @memberof ParcelPrimaryMasterPlans
     */
    type: ParcelPrimaryMasterPlansTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ParcelPrimaryMasterPlansTypeEnum {
    Housing = 'Housing',
    HousingRightOfFirstRefusal = 'HousingRightOfFirstRefusal',
    Landscape = 'Landscape',
    Transportation = 'Transportation',
    EconomicActivityArea = 'EconomicActivityArea'
}


/**
 * Check if a given object implements the ParcelPrimaryMasterPlans interface.
 */
export function instanceOfParcelPrimaryMasterPlans(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ParcelPrimaryMasterPlansFromJSON(json: any): ParcelPrimaryMasterPlans {
    return ParcelPrimaryMasterPlansFromJSONTyped(json, false);
}

export function ParcelPrimaryMasterPlansFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelPrimaryMasterPlans {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function ParcelPrimaryMasterPlansToJSON(value?: ParcelPrimaryMasterPlans | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

