/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Parcel } from './Parcel';
import {
    ParcelFromJSON,
    ParcelFromJSONTyped,
    ParcelToJSON,
} from './Parcel';
import type { ParcelQueryResultAllOf } from './ParcelQueryResultAllOf';
import {
    ParcelQueryResultAllOfFromJSON,
    ParcelQueryResultAllOfFromJSONTyped,
    ParcelQueryResultAllOfToJSON,
} from './ParcelQueryResultAllOf';
import type { QueryResult } from './QueryResult';
import {
    QueryResultFromJSON,
    QueryResultFromJSONTyped,
    QueryResultToJSON,
} from './QueryResult';

/**
 * 
 * @export
 * @interface ParcelQueryResult
 */
export interface ParcelQueryResult {
    /**
     * 
     * @type {number}
     * @memberof ParcelQueryResult
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQueryResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQueryResult
     */
    page: number;
    /**
     * 
     * @type {Array<Parcel>}
     * @memberof ParcelQueryResult
     */
    result: Array<Parcel>;
}

/**
 * Check if a given object implements the ParcelQueryResult interface.
 */
export function instanceOfParcelQueryResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function ParcelQueryResultFromJSON(json: any): ParcelQueryResult {
    return ParcelQueryResultFromJSONTyped(json, false);
}

export function ParcelQueryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelQueryResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'total': json['total'],
        'page': json['page'],
        'result': ((json['result'] as Array<any>).map(ParcelFromJSON)),
    };
}

export function ParcelQueryResultToJSON(value?: ParcelQueryResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'total': value.total,
        'page': value.page,
        'result': ((value.result as Array<any>).map(ParcelToJSON)),
    };
}

