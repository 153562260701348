/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministrativeUnitAllOf } from './AdministrativeUnitAllOf';
import {
    AdministrativeUnitAllOfFromJSON,
    AdministrativeUnitAllOfFromJSONTyped,
    AdministrativeUnitAllOfToJSON,
} from './AdministrativeUnitAllOf';
import type { AdministrativeUnitType } from './AdministrativeUnitType';
import {
    AdministrativeUnitTypeFromJSON,
    AdministrativeUnitTypeFromJSONTyped,
    AdministrativeUnitTypeToJSON,
} from './AdministrativeUnitType';
import type { AdministrativeUnitUpdate } from './AdministrativeUnitUpdate';
import {
    AdministrativeUnitUpdateFromJSON,
    AdministrativeUnitUpdateFromJSONTyped,
    AdministrativeUnitUpdateToJSON,
} from './AdministrativeUnitUpdate';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';

/**
 * 
 * @export
 * @interface AdministrativeUnit
 */
export interface AdministrativeUnit {
    /**
     * 
     * @type {Metadata}
     * @memberof AdministrativeUnit
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnit
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnit
     */
    name: string;
    /**
     * 
     * @type {AdministrativeUnitType}
     * @memberof AdministrativeUnit
     */
    type: AdministrativeUnitType;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnit
     */
    geometry: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AdministrativeUnit
     */
    translatedName?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnit
     */
    capital?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdministrativeUnit
     */
    parents?: Array<string>;
}

/**
 * Check if a given object implements the AdministrativeUnit interface.
 */
export function instanceOfAdministrativeUnit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function AdministrativeUnitFromJSON(json: any): AdministrativeUnit {
    return AdministrativeUnitFromJSONTyped(json, false);
}

export function AdministrativeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministrativeUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'country': json['country'],
        'name': json['name'],
        'type': AdministrativeUnitTypeFromJSON(json['type']),
        'geometry': json['geometry'],
        'translatedName': !exists(json, 'translatedName') ? undefined : json['translatedName'],
        'capital': !exists(json, 'capital') ? undefined : json['capital'],
        'parents': !exists(json, 'parents') ? undefined : json['parents'],
    };
}

export function AdministrativeUnitToJSON(value?: AdministrativeUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'country': value.country,
        'name': value.name,
        'type': AdministrativeUnitTypeToJSON(value.type),
        'geometry': value.geometry,
        'translatedName': value.translatedName,
        'capital': value.capital,
        'parents': value.parents,
    };
}

