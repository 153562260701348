/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocationQualityHeightDifference
 */
export interface OpportunityLocationQualityHeightDifference {
    /**
     * 
     * @type {number}
     * @memberof OpportunityLocationQualityHeightDifference
     */
    drop?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationQualityHeightDifference
     */
    hasSloppingStreet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OpportunityLocationQualityHeightDifference
     */
    streetDrop?: number;
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationQualityHeightDifference
     */
    type?: OpportunityLocationQualityHeightDifferenceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityLocationQualityHeightDifferenceTypeEnum {
    FlatGround = 'FlatGround',
    SloppingGround = 'SloppingGround',
    SteeplySloppingGround = 'SteeplySloppingGround'
}


/**
 * Check if a given object implements the OpportunityLocationQualityHeightDifference interface.
 */
export function instanceOfOpportunityLocationQualityHeightDifference(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationQualityHeightDifferenceFromJSON(json: any): OpportunityLocationQualityHeightDifference {
    return OpportunityLocationQualityHeightDifferenceFromJSONTyped(json, false);
}

export function OpportunityLocationQualityHeightDifferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationQualityHeightDifference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drop': !exists(json, 'drop') ? undefined : json['drop'],
        'hasSloppingStreet': !exists(json, 'hasSloppingStreet') ? undefined : json['hasSloppingStreet'],
        'streetDrop': !exists(json, 'streetDrop') ? undefined : json['streetDrop'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function OpportunityLocationQualityHeightDifferenceToJSON(value?: OpportunityLocationQualityHeightDifference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'drop': value.drop,
        'hasSloppingStreet': value.hasSloppingStreet,
        'streetDrop': value.streetDrop,
        'type': value.type,
    };
}

