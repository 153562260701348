/* tslint:disable */
/* eslint-disable */
/**
 * Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileLocation,
} from '../models';
import {
    FileLocationFromJSON,
    FileLocationToJSON,
} from '../models';

export interface CreateBalanceSheetRequest {
    opportunity: string;
}

export interface UpdateBalanceSheetRequest {
    file: string;
}

/**
 * FinanceApi - interface
 * 
 * @export
 * @interface FinanceApiInterface
 */
export interface FinanceApiInterface {
    /**
     * 
     * @param {string} opportunity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApiInterface
     */
    createBalanceSheetRaw(requestParameters: CreateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    createBalanceSheet(requestParameters: CreateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApiInterface
     */
    updateBalanceSheetRaw(requestParameters: UpdateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    updateBalanceSheet(requestParameters: UpdateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

}

/**
 * 
 */
export class FinanceApi extends runtime.BaseAPI implements FinanceApiInterface {

    /**
     */
    async createBalanceSheetRaw(requestParameters: CreateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.opportunity === null || requestParameters.opportunity === undefined) {
            throw new runtime.RequiredError('opportunity','Required parameter requestParameters.opportunity was null or undefined when calling createBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/balance-sheets/opportunities/{opportunity}`.replace(`{${"opportunity"}}`, encodeURIComponent(String(requestParameters.opportunity))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async createBalanceSheet(requestParameters: CreateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.createBalanceSheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBalanceSheetRaw(requestParameters: UpdateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling updateBalanceSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/balance-sheets/{file}`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async updateBalanceSheet(requestParameters: UpdateBalanceSheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.updateBalanceSheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
