/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { OpportunityAuthorizations } from './OpportunityAuthorizations';
import {
    OpportunityAuthorizationsFromJSON,
    OpportunityAuthorizationsFromJSONTyped,
    OpportunityAuthorizationsToJSON,
} from './OpportunityAuthorizations';
import type { OpportunityBuilding } from './OpportunityBuilding';
import {
    OpportunityBuildingFromJSON,
    OpportunityBuildingFromJSONTyped,
    OpportunityBuildingToJSON,
} from './OpportunityBuilding';
import type { OpportunityDocument } from './OpportunityDocument';
import {
    OpportunityDocumentFromJSON,
    OpportunityDocumentFromJSONTyped,
    OpportunityDocumentToJSON,
} from './OpportunityDocument';
import type { OpportunityLocalisation } from './OpportunityLocalisation';
import {
    OpportunityLocalisationFromJSON,
    OpportunityLocalisationFromJSONTyped,
    OpportunityLocalisationToJSON,
} from './OpportunityLocalisation';
import type { OpportunityLocationQuality } from './OpportunityLocationQuality';
import {
    OpportunityLocationQualityFromJSON,
    OpportunityLocationQualityFromJSONTyped,
    OpportunityLocationQualityToJSON,
} from './OpportunityLocationQuality';
import type { OpportunityLocationRelease } from './OpportunityLocationRelease';
import {
    OpportunityLocationReleaseFromJSON,
    OpportunityLocationReleaseFromJSONTyped,
    OpportunityLocationReleaseToJSON,
} from './OpportunityLocationRelease';
import type { OpportunityMisc } from './OpportunityMisc';
import {
    OpportunityMiscFromJSON,
    OpportunityMiscFromJSONTyped,
    OpportunityMiscToJSON,
} from './OpportunityMisc';
import type { OpportunityPrice } from './OpportunityPrice';
import {
    OpportunityPriceFromJSON,
    OpportunityPriceFromJSONTyped,
    OpportunityPriceToJSON,
} from './OpportunityPrice';
import type { OpportunityPriority } from './OpportunityPriority';
import {
    OpportunityPriorityFromJSON,
    OpportunityPriorityFromJSONTyped,
    OpportunityPriorityToJSON,
} from './OpportunityPriority';
import type { OpportunityProspecting } from './OpportunityProspecting';
import {
    OpportunityProspectingFromJSON,
    OpportunityProspectingFromJSONTyped,
    OpportunityProspectingToJSON,
} from './OpportunityProspecting';
import type { OpportunityRepartition } from './OpportunityRepartition';
import {
    OpportunityRepartitionFromJSON,
    OpportunityRepartitionFromJSONTyped,
    OpportunityRepartitionToJSON,
} from './OpportunityRepartition';
import type { OpportunitySaleConditions } from './OpportunitySaleConditions';
import {
    OpportunitySaleConditionsFromJSON,
    OpportunitySaleConditionsFromJSONTyped,
    OpportunitySaleConditionsToJSON,
} from './OpportunitySaleConditions';
import type { OpportunityTransaction } from './OpportunityTransaction';
import {
    OpportunityTransactionFromJSON,
    OpportunityTransactionFromJSONTyped,
    OpportunityTransactionToJSON,
} from './OpportunityTransaction';

/**
 * 
 * @export
 * @interface OpportunityUpdate
 */
export interface OpportunityUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof OpportunityUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof OpportunityUpdate
     */
    name: string;
    /**
     * 
     * @type {OpportunityLocalisation}
     * @memberof OpportunityUpdate
     */
    localisation: OpportunityLocalisation;
    /**
     * 
     * @type {OpportunityProspecting}
     * @memberof OpportunityUpdate
     */
    prospecting?: OpportunityProspecting;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityUpdate
     */
    nameIsManual?: boolean;
    /**
     * 
     * @type {OpportunityAuthorizations}
     * @memberof OpportunityUpdate
     */
    authorizations?: OpportunityAuthorizations;
    /**
     * 
     * @type {OpportunityBuilding}
     * @memberof OpportunityUpdate
     */
    building?: OpportunityBuilding;
    /**
     * 
     * @type {Array<OpportunityDocument>}
     * @memberof OpportunityUpdate
     */
    documents?: Array<OpportunityDocument>;
    /**
     * 
     * @type {OpportunityLocationQuality}
     * @memberof OpportunityUpdate
     */
    locationQuality?: OpportunityLocationQuality;
    /**
     * 
     * @type {OpportunityLocationRelease}
     * @memberof OpportunityUpdate
     */
    locationRelease?: OpportunityLocationRelease;
    /**
     * 
     * @type {OpportunityMisc}
     * @memberof OpportunityUpdate
     */
    misc?: OpportunityMisc;
    /**
     * 
     * @type {OpportunityPrice}
     * @memberof OpportunityUpdate
     */
    price?: OpportunityPrice;
    /**
     * 
     * @type {OpportunityPriority}
     * @memberof OpportunityUpdate
     */
    priority?: OpportunityPriority;
    /**
     * 
     * @type {OpportunityRepartition}
     * @memberof OpportunityUpdate
     */
    repartition?: OpportunityRepartition;
    /**
     * 
     * @type {OpportunitySaleConditions}
     * @memberof OpportunityUpdate
     */
    saleConditions?: OpportunitySaleConditions;
    /**
     * 
     * @type {OpportunityTransaction}
     * @memberof OpportunityUpdate
     */
    transaction?: OpportunityTransaction;
}

/**
 * Check if a given object implements the OpportunityUpdate interface.
 */
export function instanceOfOpportunityUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "localisation" in value;

    return isInstance;
}

export function OpportunityUpdateFromJSON(json: any): OpportunityUpdate {
    return OpportunityUpdateFromJSONTyped(json, false);
}

export function OpportunityUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'name': json['name'],
        'localisation': OpportunityLocalisationFromJSON(json['localisation']),
        'prospecting': !exists(json, 'prospecting') ? undefined : OpportunityProspectingFromJSON(json['prospecting']),
        'nameIsManual': !exists(json, 'nameIsManual') ? undefined : json['nameIsManual'],
        'authorizations': !exists(json, 'authorizations') ? undefined : OpportunityAuthorizationsFromJSON(json['authorizations']),
        'building': !exists(json, 'building') ? undefined : OpportunityBuildingFromJSON(json['building']),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(OpportunityDocumentFromJSON)),
        'locationQuality': !exists(json, 'locationQuality') ? undefined : OpportunityLocationQualityFromJSON(json['locationQuality']),
        'locationRelease': !exists(json, 'locationRelease') ? undefined : OpportunityLocationReleaseFromJSON(json['locationRelease']),
        'misc': !exists(json, 'misc') ? undefined : OpportunityMiscFromJSON(json['misc']),
        'price': !exists(json, 'price') ? undefined : OpportunityPriceFromJSON(json['price']),
        'priority': !exists(json, 'priority') ? undefined : OpportunityPriorityFromJSON(json['priority']),
        'repartition': !exists(json, 'repartition') ? undefined : OpportunityRepartitionFromJSON(json['repartition']),
        'saleConditions': !exists(json, 'saleConditions') ? undefined : OpportunitySaleConditionsFromJSON(json['saleConditions']),
        'transaction': !exists(json, 'transaction') ? undefined : OpportunityTransactionFromJSON(json['transaction']),
    };
}

export function OpportunityUpdateToJSON(value?: OpportunityUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'name': value.name,
        'localisation': OpportunityLocalisationToJSON(value.localisation),
        'prospecting': OpportunityProspectingToJSON(value.prospecting),
        'nameIsManual': value.nameIsManual,
        'authorizations': OpportunityAuthorizationsToJSON(value.authorizations),
        'building': OpportunityBuildingToJSON(value.building),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(OpportunityDocumentToJSON)),
        'locationQuality': OpportunityLocationQualityToJSON(value.locationQuality),
        'locationRelease': OpportunityLocationReleaseToJSON(value.locationRelease),
        'misc': OpportunityMiscToJSON(value.misc),
        'price': OpportunityPriceToJSON(value.price),
        'priority': OpportunityPriorityToJSON(value.priority),
        'repartition': OpportunityRepartitionToJSON(value.repartition),
        'saleConditions': OpportunitySaleConditionsToJSON(value.saleConditions),
        'transaction': OpportunityTransactionToJSON(value.transaction),
    };
}

