/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParsedLocation } from './ParsedLocation';
import {
    ParsedLocationFromJSON,
    ParsedLocationFromJSONTyped,
    ParsedLocationToJSON,
} from './ParsedLocation';
import type { ParsedOwner } from './ParsedOwner';
import {
    ParsedOwnerFromJSON,
    ParsedOwnerFromJSONTyped,
    ParsedOwnerToJSON,
} from './ParsedOwner';
import type { ParsedOwnerGroup } from './ParsedOwnerGroup';
import {
    ParsedOwnerGroupFromJSON,
    ParsedOwnerGroupFromJSONTyped,
    ParsedOwnerGroupToJSON,
} from './ParsedOwnerGroup';

/**
 * 
 * @export
 * @interface ParsedParcel
 */
export interface ParsedParcel {
    /**
     * 
     * @type {string}
     * @memberof ParsedParcel
     */
    cadastralIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedParcel
     */
    additionalNumber: string;
    /**
     * 
     * @type {Array<ParsedOwner>}
     * @memberof ParsedParcel
     */
    owners: Array<ParsedOwner>;
    /**
     * 
     * @type {Array<ParsedOwnerGroup>}
     * @memberof ParsedParcel
     */
    groups: Array<ParsedOwnerGroup>;
    /**
     * 
     * @type {ParsedLocation}
     * @memberof ParsedParcel
     */
    location: ParsedLocation;
}

/**
 * Check if a given object implements the ParsedParcel interface.
 */
export function instanceOfParsedParcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cadastralIdentifier" in value;
    isInstance = isInstance && "additionalNumber" in value;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function ParsedParcelFromJSON(json: any): ParsedParcel {
    return ParsedParcelFromJSONTyped(json, false);
}

export function ParsedParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedParcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cadastralIdentifier': json['cadastralIdentifier'],
        'additionalNumber': json['additionalNumber'],
        'owners': ((json['owners'] as Array<any>).map(ParsedOwnerFromJSON)),
        'groups': ((json['groups'] as Array<any>).map(ParsedOwnerGroupFromJSON)),
        'location': ParsedLocationFromJSON(json['location']),
    };
}

export function ParsedParcelToJSON(value?: ParsedParcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cadastralIdentifier': value.cadastralIdentifier,
        'additionalNumber': value.additionalNumber,
        'owners': ((value.owners as Array<any>).map(ParsedOwnerToJSON)),
        'groups': ((value.groups as Array<any>).map(ParsedOwnerGroupToJSON)),
        'location': ParsedLocationToJSON(value.location),
    };
}

