/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdministrativeUnit,
  AdministrativeUnitCreate,
  AdministrativeUnitQuery,
  AdministrativeUnitQueryResult,
  AdministrativeUnitSummary,
  AdministrativeUnitUpdate,
} from '../models';
import {
    AdministrativeUnitFromJSON,
    AdministrativeUnitToJSON,
    AdministrativeUnitCreateFromJSON,
    AdministrativeUnitCreateToJSON,
    AdministrativeUnitQueryFromJSON,
    AdministrativeUnitQueryToJSON,
    AdministrativeUnitQueryResultFromJSON,
    AdministrativeUnitQueryResultToJSON,
    AdministrativeUnitSummaryFromJSON,
    AdministrativeUnitSummaryToJSON,
    AdministrativeUnitUpdateFromJSON,
    AdministrativeUnitUpdateToJSON,
} from '../models';

export interface CreateAdministrativeUnitRequest {
    administrativeUnitCreate: AdministrativeUnitCreate;
}

export interface GetAdministrativeUnitRequest {
    id: string;
}

export interface GetAdministrativeUnitSummaryRequest {
    id: string;
}

export interface QueryAdministrativeUnitsRequest {
    administrativeUnitQuery: AdministrativeUnitQuery;
}

export interface UpdateAdministrativeUnitRequest {
    id: string;
    administrativeUnitUpdate: AdministrativeUnitUpdate;
}

/**
 * AdministrativeUnitApi - interface
 * 
 * @export
 * @interface AdministrativeUnitApiInterface
 */
export interface AdministrativeUnitApiInterface {
    /**
     * 
     * @param {AdministrativeUnitCreate} administrativeUnitCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeUnitApiInterface
     */
    createAdministrativeUnitRaw(requestParameters: CreateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>>;

    /**
     */
    createAdministrativeUnit(requestParameters: CreateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeUnitApiInterface
     */
    getAdministrativeUnitRaw(requestParameters: GetAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>>;

    /**
     */
    getAdministrativeUnit(requestParameters: GetAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeUnitApiInterface
     */
    getAdministrativeUnitSummaryRaw(requestParameters: GetAdministrativeUnitSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnitSummary>>;

    /**
     */
    getAdministrativeUnitSummary(requestParameters: GetAdministrativeUnitSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnitSummary>;

    /**
     * 
     * @param {AdministrativeUnitQuery} administrativeUnitQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeUnitApiInterface
     */
    queryAdministrativeUnitsRaw(requestParameters: QueryAdministrativeUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnitQueryResult>>;

    /**
     */
    queryAdministrativeUnits(requestParameters: QueryAdministrativeUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnitQueryResult>;

    /**
     * 
     * @param {string} id 
     * @param {AdministrativeUnitUpdate} administrativeUnitUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeUnitApiInterface
     */
    updateAdministrativeUnitRaw(requestParameters: UpdateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>>;

    /**
     */
    updateAdministrativeUnit(requestParameters: UpdateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit>;

}

/**
 * 
 */
export class AdministrativeUnitApi extends runtime.BaseAPI implements AdministrativeUnitApiInterface {

    /**
     */
    async createAdministrativeUnitRaw(requestParameters: CreateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>> {
        if (requestParameters.administrativeUnitCreate === null || requestParameters.administrativeUnitCreate === undefined) {
            throw new runtime.RequiredError('administrativeUnitCreate','Required parameter requestParameters.administrativeUnitCreate was null or undefined when calling createAdministrativeUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/administrative-units`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdministrativeUnitCreateToJSON(requestParameters.administrativeUnitCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministrativeUnitFromJSON(jsonValue));
    }

    /**
     */
    async createAdministrativeUnit(requestParameters: CreateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit> {
        const response = await this.createAdministrativeUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAdministrativeUnitRaw(requestParameters: GetAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdministrativeUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/administrative-units/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministrativeUnitFromJSON(jsonValue));
    }

    /**
     */
    async getAdministrativeUnit(requestParameters: GetAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit> {
        const response = await this.getAdministrativeUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAdministrativeUnitSummaryRaw(requestParameters: GetAdministrativeUnitSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnitSummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdministrativeUnitSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/administrative-units/{id}/summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministrativeUnitSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getAdministrativeUnitSummary(requestParameters: GetAdministrativeUnitSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnitSummary> {
        const response = await this.getAdministrativeUnitSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryAdministrativeUnitsRaw(requestParameters: QueryAdministrativeUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnitQueryResult>> {
        if (requestParameters.administrativeUnitQuery === null || requestParameters.administrativeUnitQuery === undefined) {
            throw new runtime.RequiredError('administrativeUnitQuery','Required parameter requestParameters.administrativeUnitQuery was null or undefined when calling queryAdministrativeUnits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/administrative-units/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdministrativeUnitQueryToJSON(requestParameters.administrativeUnitQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministrativeUnitQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryAdministrativeUnits(requestParameters: QueryAdministrativeUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnitQueryResult> {
        const response = await this.queryAdministrativeUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAdministrativeUnitRaw(requestParameters: UpdateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministrativeUnit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAdministrativeUnit.');
        }

        if (requestParameters.administrativeUnitUpdate === null || requestParameters.administrativeUnitUpdate === undefined) {
            throw new runtime.RequiredError('administrativeUnitUpdate','Required parameter requestParameters.administrativeUnitUpdate was null or undefined when calling updateAdministrativeUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/administrative-units/{id}/summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdministrativeUnitUpdateToJSON(requestParameters.administrativeUnitUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministrativeUnitFromJSON(jsonValue));
    }

    /**
     */
    async updateAdministrativeUnit(requestParameters: UpdateAdministrativeUnitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministrativeUnit> {
        const response = await this.updateAdministrativeUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
