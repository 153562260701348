/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocationReleaseVacancy
 */
export interface OpportunityLocationReleaseVacancy {
    /**
     * 
     * @type {Date}
     * @memberof OpportunityLocationReleaseVacancy
     */
    estimatedVacancyDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationReleaseVacancy
     */
    isVacant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationReleaseVacancy
     */
    willBeVacantUponAcquisition?: boolean;
}

/**
 * Check if a given object implements the OpportunityLocationReleaseVacancy interface.
 */
export function instanceOfOpportunityLocationReleaseVacancy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationReleaseVacancyFromJSON(json: any): OpportunityLocationReleaseVacancy {
    return OpportunityLocationReleaseVacancyFromJSONTyped(json, false);
}

export function OpportunityLocationReleaseVacancyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationReleaseVacancy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimatedVacancyDate': !exists(json, 'estimatedVacancyDate') ? undefined : (new Date(json['estimatedVacancyDate'])),
        'isVacant': !exists(json, 'isVacant') ? undefined : json['isVacant'],
        'willBeVacantUponAcquisition': !exists(json, 'willBeVacantUponAcquisition') ? undefined : json['willBeVacantUponAcquisition'],
    };
}

export function OpportunityLocationReleaseVacancyToJSON(value?: OpportunityLocationReleaseVacancy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimatedVacancyDate': value.estimatedVacancyDate === undefined ? undefined : (value.estimatedVacancyDate.toISOString().substr(0,10)),
        'isVacant': value.isVacant,
        'willBeVacantUponAcquisition': value.willBeVacantUponAcquisition,
    };
}

