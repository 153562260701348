/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParsedLocation } from './ParsedLocation';
import {
    ParsedLocationFromJSON,
    ParsedLocationFromJSONTyped,
    ParsedLocationToJSON,
} from './ParsedLocation';
import type { ParsedOwnerAllOf } from './ParsedOwnerAllOf';
import {
    ParsedOwnerAllOfFromJSON,
    ParsedOwnerAllOfFromJSONTyped,
    ParsedOwnerAllOfToJSON,
} from './ParsedOwnerAllOf';
import type { ParsedOwnerOrGroup } from './ParsedOwnerOrGroup';
import {
    ParsedOwnerOrGroupFromJSON,
    ParsedOwnerOrGroupFromJSONTyped,
    ParsedOwnerOrGroupToJSON,
} from './ParsedOwnerOrGroup';
import type { ShareRatio } from './ShareRatio';
import {
    ShareRatioFromJSON,
    ShareRatioFromJSONTyped,
    ShareRatioToJSON,
} from './ShareRatio';

/**
 * 
 * @export
 * @interface ParsedOwner
 */
export interface ParsedOwner {
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParsedOwner
     */
    groups: Array<string>;
    /**
     * 
     * @type {ParsedLocation}
     * @memberof ParsedOwner
     */
    location: ParsedLocation;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedOwner
     */
    city?: string;
    /**
     * 
     * @type {Array<ShareRatio>}
     * @memberof ParsedOwner
     */
    shareRatios: Array<ShareRatio>;
}

/**
 * Check if a given object implements the ParsedOwner interface.
 */
export function instanceOfParsedOwner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "shareRatios" in value;

    return isInstance;
}

export function ParsedOwnerFromJSON(json: any): ParsedOwner {
    return ParsedOwnerFromJSONTyped(json, false);
}

export function ParsedOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedOwner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'groups': json['groups'],
        'location': ParsedLocationFromJSON(json['location']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'shareRatios': ((json['shareRatios'] as Array<any>).map(ShareRatioFromJSON)),
    };
}

export function ParsedOwnerToJSON(value?: ParsedOwner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'groups': value.groups,
        'location': ParsedLocationToJSON(value.location),
        'firstName': value.firstName,
        'familyName': value.familyName,
        'postalCode': value.postalCode,
        'city': value.city,
        'shareRatios': ((value.shareRatios as Array<any>).map(ShareRatioToJSON)),
    };
}

