/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunitySaleConditionsSuspensiveClause } from './OpportunitySaleConditionsSuspensiveClause';
import {
    OpportunitySaleConditionsSuspensiveClauseFromJSON,
    OpportunitySaleConditionsSuspensiveClauseFromJSONTyped,
    OpportunitySaleConditionsSuspensiveClauseToJSON,
} from './OpportunitySaleConditionsSuspensiveClause';

/**
 * 
 * @export
 * @interface OpportunitySaleConditions
 */
export interface OpportunitySaleConditions {
    /**
     * 
     * @type {string}
     * @memberof OpportunitySaleConditions
     */
    saleTime?: OpportunitySaleConditionsSaleTimeEnum;
    /**
     * 
     * @type {Array<OpportunitySaleConditionsSuspensiveClause>}
     * @memberof OpportunitySaleConditions
     */
    suspensiveClauses?: Array<OpportunitySaleConditionsSuspensiveClause>;
}

/**
* @export
* @enum {string}
*/
export enum OpportunitySaleConditionsSaleTimeEnum {
    SpecificDevelopmentPlanApproval = 'SpecificDevelopmentPlanApproval',
    BuildingPermitApproval = 'BuildingPermitApproval',
    SuspensiveClausesLifting = 'SuspensiveClausesLifting'
}


/**
 * Check if a given object implements the OpportunitySaleConditions interface.
 */
export function instanceOfOpportunitySaleConditions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunitySaleConditionsFromJSON(json: any): OpportunitySaleConditions {
    return OpportunitySaleConditionsFromJSONTyped(json, false);
}

export function OpportunitySaleConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunitySaleConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saleTime': !exists(json, 'saleTime') ? undefined : json['saleTime'],
        'suspensiveClauses': !exists(json, 'suspensiveClauses') ? undefined : ((json['suspensiveClauses'] as Array<any>).map(OpportunitySaleConditionsSuspensiveClauseFromJSON)),
    };
}

export function OpportunitySaleConditionsToJSON(value?: OpportunitySaleConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saleTime': value.saleTime,
        'suspensiveClauses': value.suspensiveClauses === undefined ? undefined : ((value.suspensiveClauses as Array<any>).map(OpportunitySaleConditionsSuspensiveClauseToJSON)),
    };
}

