/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityRepartitionDistribution } from './OpportunityRepartitionDistribution';
import {
    OpportunityRepartitionDistributionFromJSON,
    OpportunityRepartitionDistributionFromJSONTyped,
    OpportunityRepartitionDistributionToJSON,
} from './OpportunityRepartitionDistribution';
import type { OpportunityRepartitionSurface } from './OpportunityRepartitionSurface';
import {
    OpportunityRepartitionSurfaceFromJSON,
    OpportunityRepartitionSurfaceFromJSONTyped,
    OpportunityRepartitionSurfaceToJSON,
} from './OpportunityRepartitionSurface';

/**
 * 
 * @export
 * @interface OpportunityRepartition
 */
export interface OpportunityRepartition {
    /**
     * 
     * @type {OpportunityRepartitionDistribution}
     * @memberof OpportunityRepartition
     */
    distribution?: OpportunityRepartitionDistribution;
    /**
     * 
     * @type {OpportunityRepartitionSurface}
     * @memberof OpportunityRepartition
     */
    surface?: OpportunityRepartitionSurface;
}

/**
 * Check if a given object implements the OpportunityRepartition interface.
 */
export function instanceOfOpportunityRepartition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityRepartitionFromJSON(json: any): OpportunityRepartition {
    return OpportunityRepartitionFromJSONTyped(json, false);
}

export function OpportunityRepartitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityRepartition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distribution': !exists(json, 'distribution') ? undefined : OpportunityRepartitionDistributionFromJSON(json['distribution']),
        'surface': !exists(json, 'surface') ? undefined : OpportunityRepartitionSurfaceFromJSON(json['surface']),
    };
}

export function OpportunityRepartitionToJSON(value?: OpportunityRepartition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distribution': OpportunityRepartitionDistributionToJSON(value.distribution),
        'surface': OpportunityRepartitionSurfaceToJSON(value.surface),
    };
}

