/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocalisationSummary
 */
export interface OpportunityLocalisationSummary {
    /**
     * 
     * @type {Array<string>}
     * @memberof OpportunityLocalisationSummary
     */
    administrativeUnits: Array<string>;
}

/**
 * Check if a given object implements the OpportunityLocalisationSummary interface.
 */
export function instanceOfOpportunityLocalisationSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "administrativeUnits" in value;

    return isInstance;
}

export function OpportunityLocalisationSummaryFromJSON(json: any): OpportunityLocalisationSummary {
    return OpportunityLocalisationSummaryFromJSONTyped(json, false);
}

export function OpportunityLocalisationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocalisationSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'administrativeUnits': json['administrativeUnits'],
    };
}

export function OpportunityLocalisationSummaryToJSON(value?: OpportunityLocalisationSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'administrativeUnits': value.administrativeUnits,
    };
}

