/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityAuthorizationBuildingPermitDocument } from './OpportunityAuthorizationBuildingPermitDocument';
import {
    OpportunityAuthorizationBuildingPermitDocumentFromJSON,
    OpportunityAuthorizationBuildingPermitDocumentFromJSONTyped,
    OpportunityAuthorizationBuildingPermitDocumentToJSON,
} from './OpportunityAuthorizationBuildingPermitDocument';

/**
 * 
 * @export
 * @interface OpportunityAuthorizationsBuildingPermit
 */
export interface OpportunityAuthorizationsBuildingPermit {
    /**
     * 
     * @type {Date}
     * @memberof OpportunityAuthorizationsBuildingPermit
     */
    authorizationDate?: Date;
    /**
     * 
     * @type {Array<OpportunityAuthorizationBuildingPermitDocument>}
     * @memberof OpportunityAuthorizationsBuildingPermit
     */
    documents?: Array<OpportunityAuthorizationBuildingPermitDocument>;
}

/**
 * Check if a given object implements the OpportunityAuthorizationsBuildingPermit interface.
 */
export function instanceOfOpportunityAuthorizationsBuildingPermit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityAuthorizationsBuildingPermitFromJSON(json: any): OpportunityAuthorizationsBuildingPermit {
    return OpportunityAuthorizationsBuildingPermitFromJSONTyped(json, false);
}

export function OpportunityAuthorizationsBuildingPermitFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityAuthorizationsBuildingPermit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationDate': !exists(json, 'authorizationDate') ? undefined : (new Date(json['authorizationDate'])),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(OpportunityAuthorizationBuildingPermitDocumentFromJSON)),
    };
}

export function OpportunityAuthorizationsBuildingPermitToJSON(value?: OpportunityAuthorizationsBuildingPermit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationDate': value.authorizationDate === undefined ? undefined : (value.authorizationDate.toISOString().substr(0,10)),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(OpportunityAuthorizationBuildingPermitDocumentToJSON)),
    };
}

