/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelProtectedHeritage
 */
export interface ParcelProtectedHeritage {
    /**
     * 
     * @type {string}
     * @memberof ParcelProtectedHeritage
     */
    geometry?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelProtectedHeritage
     */
    surfaceM2?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelProtectedHeritage
     */
    type: ParcelProtectedHeritageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelProtectedHeritage
     */
    typeId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelProtectedHeritage
     */
    files?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum ParcelProtectedHeritageTypeEnum {
    WorldHeritage = 'WorldHeritage',
    ProtectedTemplate = 'ProtectedTemplate',
    ProtectedBuilding = 'ProtectedBuilding',
    ProtectedAlignment = 'ProtectedAlignment',
    SmallHeritage = 'SmallHeritage'
}


/**
 * Check if a given object implements the ParcelProtectedHeritage interface.
 */
export function instanceOfParcelProtectedHeritage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ParcelProtectedHeritageFromJSON(json: any): ParcelProtectedHeritage {
    return ParcelProtectedHeritageFromJSONTyped(json, false);
}

export function ParcelProtectedHeritageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelProtectedHeritage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometry': !exists(json, 'geometry') ? undefined : json['geometry'],
        'surfaceM2': !exists(json, 'surfaceM2') ? undefined : json['surfaceM2'],
        'type': json['type'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function ParcelProtectedHeritageToJSON(value?: ParcelProtectedHeritage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry': value.geometry,
        'surfaceM2': value.surfaceM2,
        'type': value.type,
        'typeId': value.typeId,
        'files': value.files,
    };
}

