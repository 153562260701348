/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalId } from './ExternalId';
import {
    ExternalIdFromJSON,
    ExternalIdFromJSONTyped,
    ExternalIdToJSON,
} from './ExternalId';

/**
 * 
 * @export
 * @interface MetadataCreate
 */
export interface MetadataCreate {
    /**
     * 
     * @type {string}
     * @memberof MetadataCreate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataCreate
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataCreate
     */
    creator?: string;
    /**
     * 
     * @type {Array<ExternalId>}
     * @memberof MetadataCreate
     */
    externalIds?: Array<ExternalId>;
    /**
     * 
     * @type {string}
     * @memberof MetadataCreate
     */
    dataModelVersion?: string;
    /**
     * 
     * @type {number}
     * @memberof MetadataCreate
     */
    copyNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataCreate
     */
    copiedFrom?: string;
}

/**
 * Check if a given object implements the MetadataCreate interface.
 */
export function instanceOfMetadataCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function MetadataCreateFromJSON(json: any): MetadataCreate {
    return MetadataCreateFromJSONTyped(json, false);
}

export function MetadataCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdFromJSON)),
        'dataModelVersion': !exists(json, 'dataModelVersion') ? undefined : json['dataModelVersion'],
        'copyNumber': !exists(json, 'copyNumber') ? undefined : json['copyNumber'],
        'copiedFrom': !exists(json, 'copiedFrom') ? undefined : json['copiedFrom'],
    };
}

export function MetadataCreateToJSON(value?: MetadataCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'creator': value.creator,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdToJSON)),
        'dataModelVersion': value.dataModelVersion,
        'copyNumber': value.copyNumber,
        'copiedFrom': value.copiedFrom,
    };
}

