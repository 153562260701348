/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PersonRole {
    Administrator = 'Administrator',
    Affiliated = 'Affiliated',
    AffiliatedAdministrator = 'AffiliatedAdministrator',
    Associate = 'Associate',
    BusinessProvider = 'BusinessProvider',
    Employee = 'Employee',
    Freelancer = 'Freelancer',
    LandOwner = 'LandOwner'
}


export function PersonRoleFromJSON(json: any): PersonRole {
    return PersonRoleFromJSONTyped(json, false);
}

export function PersonRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonRole {
    return json as PersonRole;
}

export function PersonRoleToJSON(value?: PersonRole | null): any {
    return value as any;
}

