/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParcelQueryAllOf } from './ParcelQueryAllOf';
import {
    ParcelQueryAllOfFromJSON,
    ParcelQueryAllOfFromJSONTyped,
    ParcelQueryAllOfToJSON,
} from './ParcelQueryAllOf';
import type { ParcelQueryFilter } from './ParcelQueryFilter';
import {
    ParcelQueryFilterFromJSON,
    ParcelQueryFilterFromJSONTyped,
    ParcelQueryFilterToJSON,
} from './ParcelQueryFilter';
import type { Query } from './Query';
import {
    QueryFromJSON,
    QueryFromJSONTyped,
    QueryToJSON,
} from './Query';

/**
 * 
 * @export
 * @interface ParcelQuery
 */
export interface ParcelQuery {
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelQuery
     */
    page?: number;
    /**
     * 
     * @type {ParcelQueryFilter}
     * @memberof ParcelQuery
     */
    filter: ParcelQueryFilter;
}

/**
 * Check if a given object implements the ParcelQuery interface.
 */
export function instanceOfParcelQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function ParcelQueryFromJSON(json: any): ParcelQuery {
    return ParcelQueryFromJSONTyped(json, false);
}

export function ParcelQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'filter': ParcelQueryFilterFromJSON(json['filter']),
    };
}

export function ParcelQueryToJSON(value?: ParcelQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'page': value.page,
        'filter': ParcelQueryFilterToJSON(value.filter),
    };
}

