/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalId } from './ExternalId';
import {
    ExternalIdFromJSON,
    ExternalIdFromJSONTyped,
    ExternalIdToJSON,
} from './ExternalId';

/**
 * 
 * @export
 * @interface MetadataUpdate
 */
export interface MetadataUpdate {
    /**
     * 
     * @type {string}
     * @memberof MetadataUpdate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataUpdate
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof MetadataUpdate
     */
    revisionNumber?: number;
    /**
     * 
     * @type {Array<ExternalId>}
     * @memberof MetadataUpdate
     */
    externalIds?: Array<ExternalId>;
    /**
     * 
     * @type {string}
     * @memberof MetadataUpdate
     */
    mergedWith?: string;
}

/**
 * Check if a given object implements the MetadataUpdate interface.
 */
export function instanceOfMetadataUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function MetadataUpdateFromJSON(json: any): MetadataUpdate {
    return MetadataUpdateFromJSONTyped(json, false);
}

export function MetadataUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'revisionNumber': !exists(json, 'revisionNumber') ? undefined : json['revisionNumber'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdFromJSON)),
        'mergedWith': !exists(json, 'mergedWith') ? undefined : json['mergedWith'],
    };
}

export function MetadataUpdateToJSON(value?: MetadataUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'revisionNumber': value.revisionNumber,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdToJSON)),
        'mergedWith': value.mergedWith,
    };
}

