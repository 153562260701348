/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityPriceCompetition } from './OpportunityPriceCompetition';
import {
    OpportunityPriceCompetitionFromJSON,
    OpportunityPriceCompetitionFromJSONTyped,
    OpportunityPriceCompetitionToJSON,
} from './OpportunityPriceCompetition';
import type { OpportunityPricePaymentType } from './OpportunityPricePaymentType';
import {
    OpportunityPricePaymentTypeFromJSON,
    OpportunityPricePaymentTypeFromJSONTyped,
    OpportunityPricePaymentTypeToJSON,
} from './OpportunityPricePaymentType';

/**
 * 
 * @export
 * @interface OpportunityPrice
 */
export interface OpportunityPrice {
    /**
     * 
     * @type {Array<OpportunityPriceCompetition>}
     * @memberof OpportunityPrice
     */
    competition?: Array<OpportunityPriceCompetition>;
    /**
     * 
     * @type {string}
     * @memberof OpportunityPrice
     */
    givenPrice?: OpportunityPriceGivenPriceEnum;
    /**
     * 
     * @type {number}
     * @memberof OpportunityPrice
     */
    landQuotaByBuildingM2?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityPrice
     */
    landQuotaByCadastralM2?: number;
    /**
     * 
     * @type {Array<OpportunityPricePaymentType>}
     * @memberof OpportunityPrice
     */
    paymentType?: Array<OpportunityPricePaymentType>;
    /**
     * 
     * @type {number}
     * @memberof OpportunityPrice
     */
    vendorPrice?: number;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityPriceGivenPriceEnum {
    LandQuotaByCadastralM2 = 'LandQuotaByCadastralM2',
    LandQuotaByBuildingM2 = 'LandQuotaByBuildingM2',
    VendorPrice = 'VendorPrice'
}


/**
 * Check if a given object implements the OpportunityPrice interface.
 */
export function instanceOfOpportunityPrice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityPriceFromJSON(json: any): OpportunityPrice {
    return OpportunityPriceFromJSONTyped(json, false);
}

export function OpportunityPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'competition': !exists(json, 'competition') ? undefined : ((json['competition'] as Array<any>).map(OpportunityPriceCompetitionFromJSON)),
        'givenPrice': !exists(json, 'givenPrice') ? undefined : json['givenPrice'],
        'landQuotaByBuildingM2': !exists(json, 'landQuotaByBuildingM2') ? undefined : json['landQuotaByBuildingM2'],
        'landQuotaByCadastralM2': !exists(json, 'landQuotaByCadastralM2') ? undefined : json['landQuotaByCadastralM2'],
        'paymentType': !exists(json, 'paymentType') ? undefined : ((json['paymentType'] as Array<any>).map(OpportunityPricePaymentTypeFromJSON)),
        'vendorPrice': !exists(json, 'vendorPrice') ? undefined : json['vendorPrice'],
    };
}

export function OpportunityPriceToJSON(value?: OpportunityPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'competition': value.competition === undefined ? undefined : ((value.competition as Array<any>).map(OpportunityPriceCompetitionToJSON)),
        'givenPrice': value.givenPrice,
        'landQuotaByBuildingM2': value.landQuotaByBuildingM2,
        'landQuotaByCadastralM2': value.landQuotaByCadastralM2,
        'paymentType': value.paymentType === undefined ? undefined : ((value.paymentType as Array<any>).map(OpportunityPricePaymentTypeToJSON)),
        'vendorPrice': value.vendorPrice,
    };
}

