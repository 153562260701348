/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministrativeUnitQueryAllOf } from './AdministrativeUnitQueryAllOf';
import {
    AdministrativeUnitQueryAllOfFromJSON,
    AdministrativeUnitQueryAllOfFromJSONTyped,
    AdministrativeUnitQueryAllOfToJSON,
} from './AdministrativeUnitQueryAllOf';
import type { AdministrativeUnitQueryFilter } from './AdministrativeUnitQueryFilter';
import {
    AdministrativeUnitQueryFilterFromJSON,
    AdministrativeUnitQueryFilterFromJSONTyped,
    AdministrativeUnitQueryFilterToJSON,
} from './AdministrativeUnitQueryFilter';
import type { Query } from './Query';
import {
    QueryFromJSON,
    QueryFromJSONTyped,
    QueryToJSON,
} from './Query';

/**
 * 
 * @export
 * @interface AdministrativeUnitQuery
 */
export interface AdministrativeUnitQuery {
    /**
     * 
     * @type {number}
     * @memberof AdministrativeUnitQuery
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeUnitQuery
     */
    page?: number;
    /**
     * 
     * @type {AdministrativeUnitQueryFilter}
     * @memberof AdministrativeUnitQuery
     */
    filter: AdministrativeUnitQueryFilter;
}

/**
 * Check if a given object implements the AdministrativeUnitQuery interface.
 */
export function instanceOfAdministrativeUnitQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filter" in value;

    return isInstance;
}

export function AdministrativeUnitQueryFromJSON(json: any): AdministrativeUnitQuery {
    return AdministrativeUnitQueryFromJSONTyped(json, false);
}

export function AdministrativeUnitQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministrativeUnitQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'filter': AdministrativeUnitQueryFilterFromJSON(json['filter']),
    };
}

export function AdministrativeUnitQueryToJSON(value?: AdministrativeUnitQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'page': value.page,
        'filter': AdministrativeUnitQueryFilterToJSON(value.filter),
    };
}

