import {DependencyList, EffectCallback, useEffect, useRef} from "react";
import * as _ from 'lodash';
import ObjectId from "bson-objectid";

export function useMemoDeep<T>(factory: (previous?: T) => T, deps: DependencyList | undefined): T {
    const ref = useRef<T>();
    const lastDeps = useRef<DependencyList | undefined>();

    if (!ref.current || !_.isEqual(lastDeps.current, deps)) {
        lastDeps.current = deps;
        const newRef = factory(ref.current);
        if (!_.isEqual(newRef, ref.current))
            ref.current = newRef;
    }

    return ref.current!;
}

export function useEffectDeep(effect: EffectCallback, deps: DependencyList | undefined) {
    const lastDeps = useRef<DependencyList | undefined>();
    const depsVersion = useRef(0);

    if (!_.isEqual(lastDeps.current, deps)) {
        lastDeps.current = deps;
        depsVersion.current++;
    }

    return useEffect(effect, [depsVersion.current]);
}

export function useUniqueId(deps: DependencyList | undefined): string {
    return useUniqueIdWithGenerator(() => new ObjectId().toHexString(), deps);
}

export function useUniqueIdWithGenerator(generator: () => string, deps: DependencyList | undefined): string {
    const lastDeps = useRef<DependencyList | undefined>();
    const id = useRef<string>();

    if (!id.current || !_.isEqual(lastDeps.current, deps)) {
        lastDeps.current = deps;
        id.current = generator();
    }

    return id.current;
}