/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { OpportunityInvolvement } from './OpportunityInvolvement';
import {
    OpportunityInvolvementFromJSON,
    OpportunityInvolvementFromJSONTyped,
    OpportunityInvolvementToJSON,
} from './OpportunityInvolvement';
import type { OpportunityStateCommon } from './OpportunityStateCommon';
import {
    OpportunityStateCommonFromJSON,
    OpportunityStateCommonFromJSONTyped,
    OpportunityStateCommonToJSON,
} from './OpportunityStateCommon';
import type { OpportunityStateUpdateAllOf } from './OpportunityStateUpdateAllOf';
import {
    OpportunityStateUpdateAllOfFromJSON,
    OpportunityStateUpdateAllOfFromJSONTyped,
    OpportunityStateUpdateAllOfToJSON,
} from './OpportunityStateUpdateAllOf';
import type { OpportunityStatus } from './OpportunityStatus';
import {
    OpportunityStatusFromJSON,
    OpportunityStatusFromJSONTyped,
    OpportunityStatusToJSON,
} from './OpportunityStatus';
import type { OpportunityStatusReason } from './OpportunityStatusReason';
import {
    OpportunityStatusReasonFromJSON,
    OpportunityStatusReasonFromJSONTyped,
    OpportunityStatusReasonToJSON,
} from './OpportunityStatusReason';

/**
 * 
 * @export
 * @interface OpportunityStateUpdate
 */
export interface OpportunityStateUpdate {
    /**
     * 
     * @type {OpportunityStatus}
     * @memberof OpportunityStateUpdate
     */
    status: OpportunityStatus;
    /**
     * 
     * @type {OpportunityInvolvement}
     * @memberof OpportunityStateUpdate
     */
    involvement: OpportunityInvolvement;
    /**
     * 
     * @type {number}
     * @memberof OpportunityStateUpdate
     */
    weight: number;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityStateUpdate
     */
    nextDueDate?: Date;
    /**
     * 
     * @type {Array<OpportunityStatusReason>}
     * @memberof OpportunityStateUpdate
     */
    statusReasons?: Array<OpportunityStatusReason>;
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof OpportunityStateUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof OpportunityStateUpdate
     */
    comment?: string;
}

/**
 * Check if a given object implements the OpportunityStateUpdate interface.
 */
export function instanceOfOpportunityStateUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "involvement" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function OpportunityStateUpdateFromJSON(json: any): OpportunityStateUpdate {
    return OpportunityStateUpdateFromJSONTyped(json, false);
}

export function OpportunityStateUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityStateUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': OpportunityStatusFromJSON(json['status']),
        'involvement': OpportunityInvolvementFromJSON(json['involvement']),
        'weight': json['weight'],
        'nextDueDate': !exists(json, 'nextDueDate') ? undefined : (new Date(json['nextDueDate'])),
        'statusReasons': !exists(json, 'statusReasons') ? undefined : ((json['statusReasons'] as Array<any>).map(OpportunityStatusReasonFromJSON)),
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function OpportunityStateUpdateToJSON(value?: OpportunityStateUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': OpportunityStatusToJSON(value.status),
        'involvement': OpportunityInvolvementToJSON(value.involvement),
        'weight': value.weight,
        'nextDueDate': value.nextDueDate === undefined ? undefined : (value.nextDueDate.toISOString()),
        'statusReasons': value.statusReasons === undefined ? undefined : ((value.statusReasons as Array<any>).map(OpportunityStatusReasonToJSON)),
        'metadata': MetadataUpdateToJSON(value.metadata),
        'comment': value.comment,
    };
}

