/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityRepartitionDistribution
 */
export interface OpportunityRepartitionDistribution {
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    apartments?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    exteriorsWithCarPort?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    housings?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    normalInteriors?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    parkLifts?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    pitches?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    singleFamilyHouses?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    twoFamilyHouses?: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityRepartitionDistribution
     */
    uncoveredExteriors?: number;
}

/**
 * Check if a given object implements the OpportunityRepartitionDistribution interface.
 */
export function instanceOfOpportunityRepartitionDistribution(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityRepartitionDistributionFromJSON(json: any): OpportunityRepartitionDistribution {
    return OpportunityRepartitionDistributionFromJSONTyped(json, false);
}

export function OpportunityRepartitionDistributionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityRepartitionDistribution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apartments': !exists(json, 'apartments') ? undefined : json['apartments'],
        'exteriorsWithCarPort': !exists(json, 'exteriorsWithCarPort') ? undefined : json['exteriorsWithCarPort'],
        'housings': !exists(json, 'housings') ? undefined : json['housings'],
        'normalInteriors': !exists(json, 'normalInteriors') ? undefined : json['normalInteriors'],
        'parkLifts': !exists(json, 'parkLifts') ? undefined : json['parkLifts'],
        'pitches': !exists(json, 'pitches') ? undefined : json['pitches'],
        'singleFamilyHouses': !exists(json, 'singleFamilyHouses') ? undefined : json['singleFamilyHouses'],
        'twoFamilyHouses': !exists(json, 'twoFamilyHouses') ? undefined : json['twoFamilyHouses'],
        'uncoveredExteriors': !exists(json, 'uncoveredExteriors') ? undefined : json['uncoveredExteriors'],
    };
}

export function OpportunityRepartitionDistributionToJSON(value?: OpportunityRepartitionDistribution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apartments': value.apartments,
        'exteriorsWithCarPort': value.exteriorsWithCarPort,
        'housings': value.housings,
        'normalInteriors': value.normalInteriors,
        'parkLifts': value.parkLifts,
        'pitches': value.pitches,
        'singleFamilyHouses': value.singleFamilyHouses,
        'twoFamilyHouses': value.twoFamilyHouses,
        'uncoveredExteriors': value.uncoveredExteriors,
    };
}

