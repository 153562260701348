/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministrativeUnitSummary } from './AdministrativeUnitSummary';
import {
    AdministrativeUnitSummaryFromJSON,
    AdministrativeUnitSummaryFromJSONTyped,
    AdministrativeUnitSummaryToJSON,
} from './AdministrativeUnitSummary';
import type { AdministrativeUnitType } from './AdministrativeUnitType';
import {
    AdministrativeUnitTypeFromJSON,
    AdministrativeUnitTypeFromJSONTyped,
    AdministrativeUnitTypeToJSON,
} from './AdministrativeUnitType';
import type { AdministrativeUnitUpdateAllOf } from './AdministrativeUnitUpdateAllOf';
import {
    AdministrativeUnitUpdateAllOfFromJSON,
    AdministrativeUnitUpdateAllOfFromJSONTyped,
    AdministrativeUnitUpdateAllOfToJSON,
} from './AdministrativeUnitUpdateAllOf';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';

/**
 * 
 * @export
 * @interface AdministrativeUnitUpdate
 */
export interface AdministrativeUnitUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof AdministrativeUnitUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitUpdate
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitUpdate
     */
    name: string;
    /**
     * 
     * @type {AdministrativeUnitType}
     * @memberof AdministrativeUnitUpdate
     */
    type: AdministrativeUnitType;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitUpdate
     */
    geometry: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AdministrativeUnitUpdate
     */
    translatedName?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitUpdate
     */
    capital?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdministrativeUnitUpdate
     */
    parents?: Array<string>;
}

/**
 * Check if a given object implements the AdministrativeUnitUpdate interface.
 */
export function instanceOfAdministrativeUnitUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function AdministrativeUnitUpdateFromJSON(json: any): AdministrativeUnitUpdate {
    return AdministrativeUnitUpdateFromJSONTyped(json, false);
}

export function AdministrativeUnitUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministrativeUnitUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'country': json['country'],
        'name': json['name'],
        'type': AdministrativeUnitTypeFromJSON(json['type']),
        'geometry': json['geometry'],
        'translatedName': !exists(json, 'translatedName') ? undefined : json['translatedName'],
        'capital': !exists(json, 'capital') ? undefined : json['capital'],
        'parents': !exists(json, 'parents') ? undefined : json['parents'],
    };
}

export function AdministrativeUnitUpdateToJSON(value?: AdministrativeUnitUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'country': value.country,
        'name': value.name,
        'type': AdministrativeUnitTypeToJSON(value.type),
        'geometry': value.geometry,
        'translatedName': value.translatedName,
        'capital': value.capital,
        'parents': value.parents,
    };
}

