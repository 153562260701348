/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BoundingBox } from './BoundingBox';
import {
    BoundingBoxFromJSON,
    BoundingBoxFromJSONTyped,
    BoundingBoxToJSON,
} from './BoundingBox';
import type { OpenInterval } from './OpenInterval';
import {
    OpenIntervalFromJSON,
    OpenIntervalFromJSONTyped,
    OpenIntervalToJSON,
} from './OpenInterval';
import type { OpportunityInvolvement } from './OpportunityInvolvement';
import {
    OpportunityInvolvementFromJSON,
    OpportunityInvolvementFromJSONTyped,
    OpportunityInvolvementToJSON,
} from './OpportunityInvolvement';
import type { OpportunityStatus } from './OpportunityStatus';
import {
    OpportunityStatusFromJSON,
    OpportunityStatusFromJSONTyped,
    OpportunityStatusToJSON,
} from './OpportunityStatus';

/**
 * 
 * @export
 * @interface OpportunityQueryFilter
 */
export interface OpportunityQueryFilter {
    /**
     * 
     * @type {Array<OpportunityStatus>}
     * @memberof OpportunityQueryFilter
     */
    status?: Array<OpportunityStatus>;
    /**
     * 
     * @type {OpportunityInvolvement}
     * @memberof OpportunityQueryFilter
     */
    involvement?: OpportunityInvolvement;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityQueryFilter
     */
    includeArchived?: boolean;
    /**
     * 
     * @type {OpenInterval}
     * @memberof OpportunityQueryFilter
     */
    overdueInterval?: OpenInterval;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpportunityQueryFilter
     */
    user?: Array<string>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof OpportunityQueryFilter
     */
    userAndOr?: Array<Array<string>>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpportunityQueryFilter
     */
    parcel?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpportunityQueryFilter
     */
    text?: string;
    /**
     * 
     * @type {BoundingBox}
     * @memberof OpportunityQueryFilter
     */
    boundingBox?: BoundingBox;
}

/**
 * Check if a given object implements the OpportunityQueryFilter interface.
 */
export function instanceOfOpportunityQueryFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityQueryFilterFromJSON(json: any): OpportunityQueryFilter {
    return OpportunityQueryFilterFromJSONTyped(json, false);
}

export function OpportunityQueryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityQueryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(OpportunityStatusFromJSON)),
        'involvement': !exists(json, 'involvement') ? undefined : OpportunityInvolvementFromJSON(json['involvement']),
        'includeArchived': !exists(json, 'includeArchived') ? undefined : json['includeArchived'],
        'overdueInterval': !exists(json, 'overdueInterval') ? undefined : OpenIntervalFromJSON(json['overdueInterval']),
        'user': !exists(json, 'user') ? undefined : json['user'],
        'userAndOr': !exists(json, 'userAndOr') ? undefined : json['userAndOr'],
        'parcel': !exists(json, 'parcel') ? undefined : json['parcel'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'boundingBox': !exists(json, 'boundingBox') ? undefined : BoundingBoxFromJSON(json['boundingBox']),
    };
}

export function OpportunityQueryFilterToJSON(value?: OpportunityQueryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(OpportunityStatusToJSON)),
        'involvement': OpportunityInvolvementToJSON(value.involvement),
        'includeArchived': value.includeArchived,
        'overdueInterval': OpenIntervalToJSON(value.overdueInterval),
        'user': value.user,
        'userAndOr': value.userAndOr,
        'parcel': value.parcel,
        'text': value.text,
        'boundingBox': BoundingBoxToJSON(value.boundingBox),
    };
}

