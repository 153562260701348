/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityLocationQualityDisturbance } from './OpportunityLocationQualityDisturbance';
import {
    OpportunityLocationQualityDisturbanceFromJSON,
    OpportunityLocationQualityDisturbanceFromJSONTyped,
    OpportunityLocationQualityDisturbanceToJSON,
} from './OpportunityLocationQualityDisturbance';
import type { OpportunityLocationQualityEvaluation } from './OpportunityLocationQualityEvaluation';
import {
    OpportunityLocationQualityEvaluationFromJSON,
    OpportunityLocationQualityEvaluationFromJSONTyped,
    OpportunityLocationQualityEvaluationToJSON,
} from './OpportunityLocationQualityEvaluation';
import type { OpportunityLocationQualityHeightDifference } from './OpportunityLocationQualityHeightDifference';
import {
    OpportunityLocationQualityHeightDifferenceFromJSON,
    OpportunityLocationQualityHeightDifferenceFromJSONTyped,
    OpportunityLocationQualityHeightDifferenceToJSON,
} from './OpportunityLocationQualityHeightDifference';

/**
 * 
 * @export
 * @interface OpportunityLocationQuality
 */
export interface OpportunityLocationQuality {
    /**
     * 
     * @type {Array<OpportunityLocationQualityDisturbance>}
     * @memberof OpportunityLocationQuality
     */
    disturbances?: Array<OpportunityLocationQualityDisturbance>;
    /**
     * 
     * @type {OpportunityLocationQualityEvaluation}
     * @memberof OpportunityLocationQuality
     */
    evaluation?: OpportunityLocationQualityEvaluation;
    /**
     * 
     * @type {OpportunityLocationQualityHeightDifference}
     * @memberof OpportunityLocationQuality
     */
    heightDifference?: OpportunityLocationQualityHeightDifference;
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationQuality
     */
    landAccess?: OpportunityLocationQualityLandAccessEnum;
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationQuality
     */
    otherRealEstateProjects?: string;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityLocationQualityLandAccessEnum {
    Roadside = 'Roadside',
    Landlocked = 'Landlocked',
    Easement = 'Easement'
}


/**
 * Check if a given object implements the OpportunityLocationQuality interface.
 */
export function instanceOfOpportunityLocationQuality(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationQualityFromJSON(json: any): OpportunityLocationQuality {
    return OpportunityLocationQualityFromJSONTyped(json, false);
}

export function OpportunityLocationQualityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationQuality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disturbances': !exists(json, 'disturbances') ? undefined : ((json['disturbances'] as Array<any>).map(OpportunityLocationQualityDisturbanceFromJSON)),
        'evaluation': !exists(json, 'evaluation') ? undefined : OpportunityLocationQualityEvaluationFromJSON(json['evaluation']),
        'heightDifference': !exists(json, 'heightDifference') ? undefined : OpportunityLocationQualityHeightDifferenceFromJSON(json['heightDifference']),
        'landAccess': !exists(json, 'landAccess') ? undefined : json['landAccess'],
        'otherRealEstateProjects': !exists(json, 'otherRealEstateProjects') ? undefined : json['otherRealEstateProjects'],
    };
}

export function OpportunityLocationQualityToJSON(value?: OpportunityLocationQuality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disturbances': value.disturbances === undefined ? undefined : ((value.disturbances as Array<any>).map(OpportunityLocationQualityDisturbanceToJSON)),
        'evaluation': OpportunityLocationQualityEvaluationToJSON(value.evaluation),
        'heightDifference': OpportunityLocationQualityHeightDifferenceToJSON(value.heightDifference),
        'landAccess': value.landAccess,
        'otherRealEstateProjects': value.otherRealEstateProjects,
    };
}

