/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';

/**
 * 
 * @export
 * @interface CommentCreate
 */
export interface CommentCreate {
    /**
     * 
     * @type {MetadataCreate}
     * @memberof CommentCreate
     */
    metadata: MetadataCreate;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    comment: string;
}

/**
 * Check if a given object implements the CommentCreate interface.
 */
export function instanceOfCommentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function CommentCreateFromJSON(json: any): CommentCreate {
    return CommentCreateFromJSONTyped(json, false);
}

export function CommentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCreateFromJSON(json['metadata']),
        'comment': json['comment'],
    };
}

export function CommentCreateToJSON(value?: CommentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCreateToJSON(value.metadata),
        'comment': value.comment,
    };
}

