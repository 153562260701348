/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpenMinMax } from './OpenMinMax';
import {
    OpenMinMaxFromJSON,
    OpenMinMaxFromJSONTyped,
    OpenMinMaxToJSON,
} from './OpenMinMax';

/**
 * 
 * @export
 * @interface ParcelSection
 */
export interface ParcelSection {
    /**
     * 
     * @type {string}
     * @memberof ParcelSection
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelSection
     */
    geometry?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelSection
     */
    surfaceM2?: number;
    /**
     * 
     * @type {OpenMinMax}
     * @memberof ParcelSection
     */
    floorSealingCoefficient?: OpenMinMax;
    /**
     * 
     * @type {OpenMinMax}
     * @memberof ParcelSection
     */
    housingDensity?: OpenMinMax;
    /**
     * 
     * @type {OpenMinMax}
     * @memberof ParcelSection
     */
    landOccupationCoefficient?: OpenMinMax;
    /**
     * 
     * @type {OpenMinMax}
     * @memberof ParcelSection
     */
    landUseCoefficient?: OpenMinMax;
    /**
     * 
     * @type {string}
     * @memberof ParcelSection
     */
    districtOrFreeAreaType: ParcelSectionDistrictOrFreeAreaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelSection
     */
    urbanizedAreaType?: ParcelSectionUrbanizedAreaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ParcelSection
     */
    typeId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelSection
     */
    files?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum ParcelSectionDistrictOrFreeAreaTypeEnum {
    ExistingDistrict = 'ExistingDistrict',
    NewDistrict = 'NewDistrict',
    Agricultural = 'Agricultural',
    Forest = 'Forest',
    PublicParc = 'PublicParc',
    GreenArea = 'GreenArea',
    WineGrowingArea = 'WineGrowingArea',
    Horticultural = 'Horticultural',
    Other = 'Other'
}
/**
* @export
* @enum {string}
*/
export enum ParcelSectionUrbanizedAreaTypeEnum {
    UrbanMixed = 'UrbanMixed',
    CentralUrbanMixed = 'CentralUrbanMixed',
    LivingArea1 = 'LivingArea1',
    LivingArea2 = 'LivingArea2',
    VillageMixed = 'VillageMixed',
    RuralMixed = 'RuralMixed',
    PublicEquipmentsAndFacilities = 'PublicEquipmentsAndFacilities',
    CommunalEconomicActivity = 'CommunalEconomicActivity',
    RegionalEconomicActivity = 'RegionalEconomicActivity',
    NationalEconomicActivity = 'NationalEconomicActivity',
    TertiaryEconomicActivity = 'TertiaryEconomicActivity',
    Commercial = 'Commercial',
    SportsAndLeisure = 'SportsAndLeisure',
    Gardens = 'Gardens',
    Military = 'Military',
    Airfield = 'Airfield',
    Harbor = 'Harbor',
    Station = 'Station',
    Special = 'Special',
    Other = 'Other'
}


/**
 * Check if a given object implements the ParcelSection interface.
 */
export function instanceOfParcelSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "districtOrFreeAreaType" in value;

    return isInstance;
}

export function ParcelSectionFromJSON(json: any): ParcelSection {
    return ParcelSectionFromJSONTyped(json, false);
}

export function ParcelSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'geometry': !exists(json, 'geometry') ? undefined : json['geometry'],
        'surfaceM2': !exists(json, 'surfaceM2') ? undefined : json['surfaceM2'],
        'floorSealingCoefficient': !exists(json, 'floorSealingCoefficient') ? undefined : OpenMinMaxFromJSON(json['floorSealingCoefficient']),
        'housingDensity': !exists(json, 'housingDensity') ? undefined : OpenMinMaxFromJSON(json['housingDensity']),
        'landOccupationCoefficient': !exists(json, 'landOccupationCoefficient') ? undefined : OpenMinMaxFromJSON(json['landOccupationCoefficient']),
        'landUseCoefficient': !exists(json, 'landUseCoefficient') ? undefined : OpenMinMaxFromJSON(json['landUseCoefficient']),
        'districtOrFreeAreaType': json['districtOrFreeAreaType'],
        'urbanizedAreaType': !exists(json, 'urbanizedAreaType') ? undefined : json['urbanizedAreaType'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function ParcelSectionToJSON(value?: ParcelSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'geometry': value.geometry,
        'surfaceM2': value.surfaceM2,
        'floorSealingCoefficient': OpenMinMaxToJSON(value.floorSealingCoefficient),
        'housingDensity': OpenMinMaxToJSON(value.housingDensity),
        'landOccupationCoefficient': OpenMinMaxToJSON(value.landOccupationCoefficient),
        'landUseCoefficient': OpenMinMaxToJSON(value.landUseCoefficient),
        'districtOrFreeAreaType': value.districtOrFreeAreaType,
        'urbanizedAreaType': value.urbanizedAreaType,
        'typeId': value.typeId,
        'files': value.files,
    };
}

