/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelArchaeologicalHeritage
 */
export interface ParcelArchaeologicalHeritage {
    /**
     * 
     * @type {boolean}
     * @memberof ParcelArchaeologicalHeritage
     */
    hasPotential?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParcelArchaeologicalHeritage
     */
    requirements?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelArchaeologicalHeritage
     */
    sensitivity?: ParcelArchaeologicalHeritageSensitivityEnum;
}

/**
* @export
* @enum {string}
*/
export enum ParcelArchaeologicalHeritageSensitivityEnum {
    Weak = 'Weak',
    Sensitive = 'Sensitive',
    High = 'High'
}


/**
 * Check if a given object implements the ParcelArchaeologicalHeritage interface.
 */
export function instanceOfParcelArchaeologicalHeritage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcelArchaeologicalHeritageFromJSON(json: any): ParcelArchaeologicalHeritage {
    return ParcelArchaeologicalHeritageFromJSONTyped(json, false);
}

export function ParcelArchaeologicalHeritageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelArchaeologicalHeritage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasPotential': !exists(json, 'hasPotential') ? undefined : json['hasPotential'],
        'requirements': !exists(json, 'requirements') ? undefined : json['requirements'],
        'sensitivity': !exists(json, 'sensitivity') ? undefined : json['sensitivity'],
    };
}

export function ParcelArchaeologicalHeritageToJSON(value?: ParcelArchaeologicalHeritage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasPotential': value.hasPotential,
        'requirements': value.requirements,
        'sensitivity': value.sensitivity,
    };
}

