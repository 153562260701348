/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { PersonRole } from './PersonRole';
import {
    PersonRoleFromJSON,
    PersonRoleFromJSONTyped,
    PersonRoleToJSON,
} from './PersonRole';
import type { PersonSummaryAllOf } from './PersonSummaryAllOf';
import {
    PersonSummaryAllOfFromJSON,
    PersonSummaryAllOfFromJSONTyped,
    PersonSummaryAllOfToJSON,
} from './PersonSummaryAllOf';
import type { PersonSummaryUpdate } from './PersonSummaryUpdate';
import {
    PersonSummaryUpdateFromJSON,
    PersonSummaryUpdateFromJSONTyped,
    PersonSummaryUpdateToJSON,
} from './PersonSummaryUpdate';
import type { PersonType } from './PersonType';
import {
    PersonTypeFromJSON,
    PersonTypeFromJSONTyped,
    PersonTypeToJSON,
} from './PersonType';

/**
 * 
 * @export
 * @interface PersonSummary
 */
export interface PersonSummary {
    /**
     * 
     * @type {Metadata}
     * @memberof PersonSummary
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof PersonSummary
     */
    name: string;
    /**
     * 
     * @type {PersonType}
     * @memberof PersonSummary
     */
    type: PersonType;
    /**
     * 
     * @type {PersonRole}
     * @memberof PersonSummary
     */
    role: PersonRole;
    /**
     * 
     * @type {boolean}
     * @memberof PersonSummary
     */
    isUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonSummary
     */
    disabled?: boolean;
}

/**
 * Check if a given object implements the PersonSummary interface.
 */
export function instanceOfPersonSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function PersonSummaryFromJSON(json: any): PersonSummary {
    return PersonSummaryFromJSONTyped(json, false);
}

export function PersonSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'type': PersonTypeFromJSON(json['type']),
        'role': PersonRoleFromJSON(json['role']),
        'isUser': !exists(json, 'isUser') ? undefined : json['isUser'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function PersonSummaryToJSON(value?: PersonSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'type': PersonTypeToJSON(value.type),
        'role': PersonRoleToJSON(value.role),
        'isUser': value.isUser,
        'disabled': value.disabled,
    };
}

