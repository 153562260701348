/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PeopleRelationship,
  PeopleRelationshipCreate,
  PeopleRelationshipQuery,
  PeopleRelationshipQueryResult,
  PeopleRelationshipUpdate,
} from '../models';
import {
    PeopleRelationshipFromJSON,
    PeopleRelationshipToJSON,
    PeopleRelationshipCreateFromJSON,
    PeopleRelationshipCreateToJSON,
    PeopleRelationshipQueryFromJSON,
    PeopleRelationshipQueryToJSON,
    PeopleRelationshipQueryResultFromJSON,
    PeopleRelationshipQueryResultToJSON,
    PeopleRelationshipUpdateFromJSON,
    PeopleRelationshipUpdateToJSON,
} from '../models';

export interface CreatePeopleRelationshipRequest {
    peopleRelationshipCreate: PeopleRelationshipCreate;
}

export interface DeletePeopleRelationshipRequest {
    id: string;
}

export interface QueryPeopleRelationshipRequest {
    peopleRelationshipQuery: PeopleRelationshipQuery;
}

export interface UpdatePeopleRelationshipRequest {
    id: string;
    peopleRelationshipUpdate: PeopleRelationshipUpdate;
}

/**
 * PeopleRelationshipApi - interface
 * 
 * @export
 * @interface PeopleRelationshipApiInterface
 */
export interface PeopleRelationshipApiInterface {
    /**
     * 
     * @param {PeopleRelationshipCreate} peopleRelationshipCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleRelationshipApiInterface
     */
    createPeopleRelationshipRaw(requestParameters: CreatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationship>>;

    /**
     */
    createPeopleRelationship(requestParameters: CreatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationship>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleRelationshipApiInterface
     */
    deletePeopleRelationshipRaw(requestParameters: DeletePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    deletePeopleRelationship(requestParameters: DeletePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {PeopleRelationshipQuery} peopleRelationshipQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleRelationshipApiInterface
     */
    queryPeopleRelationshipRaw(requestParameters: QueryPeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationshipQueryResult>>;

    /**
     */
    queryPeopleRelationship(requestParameters: QueryPeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationshipQueryResult>;

    /**
     * 
     * @param {string} id 
     * @param {PeopleRelationshipUpdate} peopleRelationshipUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleRelationshipApiInterface
     */
    updatePeopleRelationshipRaw(requestParameters: UpdatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationship>>;

    /**
     */
    updatePeopleRelationship(requestParameters: UpdatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationship>;

}

/**
 * 
 */
export class PeopleRelationshipApi extends runtime.BaseAPI implements PeopleRelationshipApiInterface {

    /**
     */
    async createPeopleRelationshipRaw(requestParameters: CreatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationship>> {
        if (requestParameters.peopleRelationshipCreate === null || requestParameters.peopleRelationshipCreate === undefined) {
            throw new runtime.RequiredError('peopleRelationshipCreate','Required parameter requestParameters.peopleRelationshipCreate was null or undefined when calling createPeopleRelationship.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/relationship`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeopleRelationshipCreateToJSON(requestParameters.peopleRelationshipCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PeopleRelationshipFromJSON(jsonValue));
    }

    /**
     */
    async createPeopleRelationship(requestParameters: CreatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationship> {
        const response = await this.createPeopleRelationshipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePeopleRelationshipRaw(requestParameters: DeletePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePeopleRelationship.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/relationship/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePeopleRelationship(requestParameters: DeletePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePeopleRelationshipRaw(requestParameters, initOverrides);
    }

    /**
     */
    async queryPeopleRelationshipRaw(requestParameters: QueryPeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationshipQueryResult>> {
        if (requestParameters.peopleRelationshipQuery === null || requestParameters.peopleRelationshipQuery === undefined) {
            throw new runtime.RequiredError('peopleRelationshipQuery','Required parameter requestParameters.peopleRelationshipQuery was null or undefined when calling queryPeopleRelationship.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/relationship/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeopleRelationshipQueryToJSON(requestParameters.peopleRelationshipQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PeopleRelationshipQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryPeopleRelationship(requestParameters: QueryPeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationshipQueryResult> {
        const response = await this.queryPeopleRelationshipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePeopleRelationshipRaw(requestParameters: UpdatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PeopleRelationship>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePeopleRelationship.');
        }

        if (requestParameters.peopleRelationshipUpdate === null || requestParameters.peopleRelationshipUpdate === undefined) {
            throw new runtime.RequiredError('peopleRelationshipUpdate','Required parameter requestParameters.peopleRelationshipUpdate was null or undefined when calling updatePeopleRelationship.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/people/relationship/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PeopleRelationshipUpdateToJSON(requestParameters.peopleRelationshipUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PeopleRelationshipFromJSON(jsonValue));
    }

    /**
     */
    async updatePeopleRelationship(requestParameters: UpdatePeopleRelationshipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PeopleRelationship> {
        const response = await this.updatePeopleRelationshipRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
