/* tslint:disable */
/* eslint-disable */
/**
 * Files
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileLocation,
  FileLocationCopy,
  FileLocationCreate,
  FileLocationUpdate,
  FileLocationWebViewLink,
  ParcelFilesQuery,
} from '../models';
import {
    FileLocationFromJSON,
    FileLocationToJSON,
    FileLocationCopyFromJSON,
    FileLocationCopyToJSON,
    FileLocationCreateFromJSON,
    FileLocationCreateToJSON,
    FileLocationUpdateFromJSON,
    FileLocationUpdateToJSON,
    FileLocationWebViewLinkFromJSON,
    FileLocationWebViewLinkToJSON,
    ParcelFilesQueryFromJSON,
    ParcelFilesQueryToJSON,
} from '../models';

export interface CopyFileRequest {
    fileLocationCopy: FileLocationCopy;
}

export interface CreateFileRequest {
    fileLocationCreate: FileLocationCreate;
}

export interface DeleteFileRequest {
    file: string;
}

export interface DownloadFileRequest {
    file: string;
}

export interface DownloadFileThumbnailRequest {
    file: string;
}

export interface GetFileRequest {
    file: string;
}

export interface GetFileUploadInfoRequest {
    file: string;
}

export interface GetFileWebViewLinkRequest {
    file: string;
}

export interface GetOpportunityFilesRequest {
    opportunity: string;
}

export interface GetOpportunityLocationRequest {
    opportunity: string;
}

export interface GetParcelFilesRequest {
    parcel: string;
}

export interface QueryParcelFilesRequest {
    parcelFilesQuery: ParcelFilesQuery;
}

export interface ResumeFileUploadRequest {
    file: string;
    body: Blob;
}

export interface TouchFileRequest {
    file: string;
}

export interface UpdateFileRequest {
    file: string;
    fileLocationUpdate: FileLocationUpdate;
}

export interface UpdateOpportunityLocationRequest {
    opportunity: string;
}

/**
 * FileApi - interface
 * 
 * @export
 * @interface FileApiInterface
 */
export interface FileApiInterface {
    /**
     * 
     * @param {FileLocationCopy} fileLocationCopy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    copyFileRaw(requestParameters: CopyFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    copyFile(requestParameters: CopyFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {FileLocationCreate} fileLocationCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    createFileRaw(requestParameters: CreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    createFile(requestParameters: CreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    deleteFile(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    downloadFileRaw(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    downloadFile(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    downloadFileThumbnailRaw(requestParameters: DownloadFileThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    downloadFileThumbnail(requestParameters: DownloadFileThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getFileRaw(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    getFile(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getFileUploadInfoRaw(requestParameters: GetFileUploadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    getFileUploadInfo(requestParameters: GetFileUploadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getFileWebViewLinkRaw(requestParameters: GetFileWebViewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocationWebViewLink>>;

    /**
     */
    getFileWebViewLink(requestParameters: GetFileWebViewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocationWebViewLink>;

    /**
     * 
     * @param {string} opportunity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getOpportunityFilesRaw(requestParameters: GetOpportunityFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>>;

    /**
     */
    getOpportunityFiles(requestParameters: GetOpportunityFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>>;

    /**
     * 
     * @param {string} opportunity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getOpportunityLocationRaw(requestParameters: GetOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    getOpportunityLocation(requestParameters: GetOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} parcel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    getParcelFilesRaw(requestParameters: GetParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>>;

    /**
     */
    getParcelFiles(requestParameters: GetParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>>;

    /**
     * 
     * @param {ParcelFilesQuery} parcelFilesQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    queryParcelFilesRaw(requestParameters: QueryParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>>;

    /**
     */
    queryParcelFiles(requestParameters: QueryParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>>;

    /**
     * 
     * @param {string} file 
     * @param {Blob} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    resumeFileUploadRaw(requestParameters: ResumeFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    resumeFileUpload(requestParameters: ResumeFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    touchFileRaw(requestParameters: TouchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    touchFile(requestParameters: TouchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} file 
     * @param {FileLocationUpdate} fileLocationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    updateFileRaw(requestParameters: UpdateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    updateFile(requestParameters: UpdateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

    /**
     * 
     * @param {string} opportunity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    updateOpportunityLocationRaw(requestParameters: UpdateOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>>;

    /**
     */
    updateOpportunityLocation(requestParameters: UpdateOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation>;

}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI implements FileApiInterface {

    /**
     */
    async copyFileRaw(requestParameters: CopyFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.fileLocationCopy === null || requestParameters.fileLocationCopy === undefined) {
            throw new runtime.RequiredError('fileLocationCopy','Required parameter requestParameters.fileLocationCopy was null or undefined when calling copyFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileLocationCopyToJSON(requestParameters.fileLocationCopy),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async copyFile(requestParameters: CopyFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.copyFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createFileRaw(requestParameters: CreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.fileLocationCreate === null || requestParameters.fileLocationCreate === undefined) {
            throw new runtime.RequiredError('fileLocationCreate','Required parameter requestParameters.fileLocationCreate was null or undefined when calling createFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileLocationCreateToJSON(requestParameters.fileLocationCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async createFile(requestParameters: CreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.createFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling deleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async deleteFile(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.deleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/download`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadFile(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downloadFileThumbnailRaw(requestParameters: DownloadFileThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling downloadFileThumbnail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/thumbnail`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadFileThumbnail(requestParameters: DownloadFileThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFileThumbnailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileRaw(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling getFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async getFile(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.getFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileUploadInfoRaw(requestParameters: GetFileUploadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling getFileUploadInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/upload`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async getFileUploadInfo(requestParameters: GetFileUploadInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.getFileUploadInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileWebViewLinkRaw(requestParameters: GetFileWebViewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocationWebViewLink>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling getFileWebViewLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/webViewLink`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationWebViewLinkFromJSON(jsonValue));
    }

    /**
     */
    async getFileWebViewLink(requestParameters: GetFileWebViewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocationWebViewLink> {
        const response = await this.getFileWebViewLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunityFilesRaw(requestParameters: GetOpportunityFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>> {
        if (requestParameters.opportunity === null || requestParameters.opportunity === undefined) {
            throw new runtime.RequiredError('opportunity','Required parameter requestParameters.opportunity was null or undefined when calling getOpportunityFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/opportunities/{opportunity}`.replace(`{${"opportunity"}}`, encodeURIComponent(String(requestParameters.opportunity))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileLocationFromJSON));
    }

    /**
     */
    async getOpportunityFiles(requestParameters: GetOpportunityFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>> {
        const response = await this.getOpportunityFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpportunityLocationRaw(requestParameters: GetOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.opportunity === null || requestParameters.opportunity === undefined) {
            throw new runtime.RequiredError('opportunity','Required parameter requestParameters.opportunity was null or undefined when calling getOpportunityLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/opportunities/{opportunity}/folder`.replace(`{${"opportunity"}}`, encodeURIComponent(String(requestParameters.opportunity))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async getOpportunityLocation(requestParameters: GetOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.getOpportunityLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getParcelFilesRaw(requestParameters: GetParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>> {
        if (requestParameters.parcel === null || requestParameters.parcel === undefined) {
            throw new runtime.RequiredError('parcel','Required parameter requestParameters.parcel was null or undefined when calling getParcelFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/parcels/{parcel}`.replace(`{${"parcel"}}`, encodeURIComponent(String(requestParameters.parcel))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileLocationFromJSON));
    }

    /**
     */
    async getParcelFiles(requestParameters: GetParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>> {
        const response = await this.getParcelFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryParcelFilesRaw(requestParameters: QueryParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileLocation>>> {
        if (requestParameters.parcelFilesQuery === null || requestParameters.parcelFilesQuery === undefined) {
            throw new runtime.RequiredError('parcelFilesQuery','Required parameter requestParameters.parcelFilesQuery was null or undefined when calling queryParcelFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/parcels/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelFilesQueryToJSON(requestParameters.parcelFilesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileLocationFromJSON));
    }

    /**
     */
    async queryParcelFiles(requestParameters: QueryParcelFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileLocation>> {
        const response = await this.queryParcelFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resumeFileUploadRaw(requestParameters: ResumeFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling resumeFileUpload.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling resumeFileUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/upload`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async resumeFileUpload(requestParameters: ResumeFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.resumeFileUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async touchFileRaw(requestParameters: TouchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling touchFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}/touch`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async touchFile(requestParameters: TouchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.touchFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFileRaw(requestParameters: UpdateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling updateFile.');
        }

        if (requestParameters.fileLocationUpdate === null || requestParameters.fileLocationUpdate === undefined) {
            throw new runtime.RequiredError('fileLocationUpdate','Required parameter requestParameters.fileLocationUpdate was null or undefined when calling updateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file}`.replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FileLocationUpdateToJSON(requestParameters.fileLocationUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async updateFile(requestParameters: UpdateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.updateFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOpportunityLocationRaw(requestParameters: UpdateOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileLocation>> {
        if (requestParameters.opportunity === null || requestParameters.opportunity === undefined) {
            throw new runtime.RequiredError('opportunity','Required parameter requestParameters.opportunity was null or undefined when calling updateOpportunityLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/opportunities/{opportunity}/folder`.replace(`{${"opportunity"}}`, encodeURIComponent(String(requestParameters.opportunity))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileLocationFromJSON(jsonValue));
    }

    /**
     */
    async updateOpportunityLocation(requestParameters: UpdateOpportunityLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileLocation> {
        const response = await this.updateOpportunityLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
