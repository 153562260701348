/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonQueryResultAllOf } from './PersonQueryResultAllOf';
import {
    PersonQueryResultAllOfFromJSON,
    PersonQueryResultAllOfFromJSONTyped,
    PersonQueryResultAllOfToJSON,
} from './PersonQueryResultAllOf';
import type { PersonQuerySummary } from './PersonQuerySummary';
import {
    PersonQuerySummaryFromJSON,
    PersonQuerySummaryFromJSONTyped,
    PersonQuerySummaryToJSON,
} from './PersonQuerySummary';
import type { QueryResult } from './QueryResult';
import {
    QueryResultFromJSON,
    QueryResultFromJSONTyped,
    QueryResultToJSON,
} from './QueryResult';

/**
 * 
 * @export
 * @interface PersonQueryResult
 */
export interface PersonQueryResult {
    /**
     * 
     * @type {number}
     * @memberof PersonQueryResult
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PersonQueryResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PersonQueryResult
     */
    page: number;
    /**
     * 
     * @type {Array<PersonQuerySummary>}
     * @memberof PersonQueryResult
     */
    result: Array<PersonQuerySummary>;
}

/**
 * Check if a given object implements the PersonQueryResult interface.
 */
export function instanceOfPersonQueryResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function PersonQueryResultFromJSON(json: any): PersonQueryResult {
    return PersonQueryResultFromJSONTyped(json, false);
}

export function PersonQueryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonQueryResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'total': json['total'],
        'page': json['page'],
        'result': ((json['result'] as Array<any>).map(PersonQuerySummaryFromJSON)),
    };
}

export function PersonQueryResultToJSON(value?: PersonQueryResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'total': value.total,
        'page': value.page,
        'result': ((value.result as Array<any>).map(PersonQuerySummaryToJSON)),
    };
}

