/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CadastralExtractRequestAllOf } from './CadastralExtractRequestAllOf';
import {
    CadastralExtractRequestAllOfFromJSON,
    CadastralExtractRequestAllOfFromJSONTyped,
    CadastralExtractRequestAllOfToJSON,
} from './CadastralExtractRequestAllOf';
import type { CadastralExtractRequestCreate } from './CadastralExtractRequestCreate';
import {
    CadastralExtractRequestCreateFromJSON,
    CadastralExtractRequestCreateFromJSONTyped,
    CadastralExtractRequestCreateToJSON,
} from './CadastralExtractRequestCreate';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { ParsedDocument } from './ParsedDocument';
import {
    ParsedDocumentFromJSON,
    ParsedDocumentFromJSONTyped,
    ParsedDocumentToJSON,
} from './ParsedDocument';

/**
 * 
 * @export
 * @interface CadastralExtractRequest
 */
export interface CadastralExtractRequest {
    /**
     * 
     * @type {Metadata}
     * @memberof CadastralExtractRequest
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof CadastralExtractRequest
     */
    opportunity: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CadastralExtractRequest
     */
    readonly parcels: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CadastralExtractRequest
     */
    readonly sendingMail: string;
    /**
     * 
     * @type {Date}
     * @memberof CadastralExtractRequest
     */
    readonly sendDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof CadastralExtractRequest
     */
    readonly status: CadastralExtractRequestStatusEnum;
    /**
     * 
     * @type {ParsedDocument}
     * @memberof CadastralExtractRequest
     */
    result?: ParsedDocument;
    /**
     * 
     * @type {string}
     * @memberof CadastralExtractRequest
     */
    error?: string;
}

/**
* @export
* @enum {string}
*/
export enum CadastralExtractRequestStatusEnum {
    Sent = 'Sent',
    ConfirmationReceived = 'ConfirmationReceived',
    Received = 'Received',
    Parsed = 'Parsed',
    Rejected = 'Rejected',
    ParsingError = 'ParsingError',
    Error = 'Error'
}


/**
 * Check if a given object implements the CadastralExtractRequest interface.
 */
export function instanceOfCadastralExtractRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "opportunity" in value;
    isInstance = isInstance && "parcels" in value;
    isInstance = isInstance && "sendingMail" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CadastralExtractRequestFromJSON(json: any): CadastralExtractRequest {
    return CadastralExtractRequestFromJSONTyped(json, false);
}

export function CadastralExtractRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CadastralExtractRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'opportunity': json['opportunity'],
        'parcels': json['parcels'],
        'sendingMail': json['sendingMail'],
        'sendDateTime': !exists(json, 'sendDateTime') ? undefined : (new Date(json['sendDateTime'])),
        'status': json['status'],
        'result': !exists(json, 'result') ? undefined : ParsedDocumentFromJSON(json['result']),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function CadastralExtractRequestToJSON(value?: CadastralExtractRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'opportunity': value.opportunity,
        'result': ParsedDocumentToJSON(value.result),
        'error': value.error,
    };
}

