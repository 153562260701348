/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityLocationReleaseTenancy } from './OpportunityLocationReleaseTenancy';
import {
    OpportunityLocationReleaseTenancyFromJSON,
    OpportunityLocationReleaseTenancyFromJSONTyped,
    OpportunityLocationReleaseTenancyToJSON,
} from './OpportunityLocationReleaseTenancy';
import type { OpportunityLocationReleaseVacancy } from './OpportunityLocationReleaseVacancy';
import {
    OpportunityLocationReleaseVacancyFromJSON,
    OpportunityLocationReleaseVacancyFromJSONTyped,
    OpportunityLocationReleaseVacancyToJSON,
} from './OpportunityLocationReleaseVacancy';

/**
 * 
 * @export
 * @interface OpportunityLocationRelease
 */
export interface OpportunityLocationRelease {
    /**
     * 
     * @type {OpportunityLocationReleaseTenancy}
     * @memberof OpportunityLocationRelease
     */
    tenancy?: OpportunityLocationReleaseTenancy;
    /**
     * 
     * @type {OpportunityLocationReleaseVacancy}
     * @memberof OpportunityLocationRelease
     */
    vacancy?: OpportunityLocationReleaseVacancy;
}

/**
 * Check if a given object implements the OpportunityLocationRelease interface.
 */
export function instanceOfOpportunityLocationRelease(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationReleaseFromJSON(json: any): OpportunityLocationRelease {
    return OpportunityLocationReleaseFromJSONTyped(json, false);
}

export function OpportunityLocationReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationRelease {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenancy': !exists(json, 'tenancy') ? undefined : OpportunityLocationReleaseTenancyFromJSON(json['tenancy']),
        'vacancy': !exists(json, 'vacancy') ? undefined : OpportunityLocationReleaseVacancyFromJSON(json['vacancy']),
    };
}

export function OpportunityLocationReleaseToJSON(value?: OpportunityLocationRelease | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenancy': OpportunityLocationReleaseTenancyToJSON(value.tenancy),
        'vacancy': OpportunityLocationReleaseVacancyToJSON(value.vacancy),
    };
}

