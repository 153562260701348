/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelQueryFilter
 */
export interface ParcelQueryFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelQueryFilter
     */
    id?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelQueryFilter
     */
    externalId?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParcelQueryFilter
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelQueryFilter
     */
    geometry?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParcelQueryFilter
     */
    includeInternalOnly?: boolean;
}

/**
 * Check if a given object implements the ParcelQueryFilter interface.
 */
export function instanceOfParcelQueryFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcelQueryFilterFromJSON(json: any): ParcelQueryFilter {
    return ParcelQueryFilterFromJSONTyped(json, false);
}

export function ParcelQueryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelQueryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'geometry': !exists(json, 'geometry') ? undefined : json['geometry'],
        'includeInternalOnly': !exists(json, 'includeInternalOnly') ? undefined : json['includeInternalOnly'],
    };
}

export function ParcelQueryFilterToJSON(value?: ParcelQueryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'text': value.text,
        'geometry': value.geometry,
        'includeInternalOnly': value.includeInternalOnly,
    };
}

