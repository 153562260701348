/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataCopy } from './MetadataCopy';
import {
    MetadataCopyFromJSON,
    MetadataCopyFromJSONTyped,
    MetadataCopyToJSON,
} from './MetadataCopy';

/**
 * 
 * @export
 * @interface FileLocationCopy
 */
export interface FileLocationCopy {
    /**
     * 
     * @type {MetadataCopy}
     * @memberof FileLocationCopy
     */
    metadata: MetadataCopy;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileLocationCopy
     */
    path?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FileLocationCopy
     */
    description?: string;
}

/**
 * Check if a given object implements the FileLocationCopy interface.
 */
export function instanceOfFileLocationCopy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function FileLocationCopyFromJSON(json: any): FileLocationCopy {
    return FileLocationCopyFromJSONTyped(json, false);
}

export function FileLocationCopyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileLocationCopy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCopyFromJSON(json['metadata']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function FileLocationCopyToJSON(value?: FileLocationCopy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCopyToJSON(value.metadata),
        'path': value.path,
        'description': value.description,
    };
}

