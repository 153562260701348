/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateMyPasswordRequest,
  User,
  UserCreate,
  UserFilter,
  UserFilterCreate,
  UserFilterUpdate,
  UserPreferences,
  UserPreferencesUpdate,
} from '../models';
import {
    UpdateMyPasswordRequestFromJSON,
    UpdateMyPasswordRequestToJSON,
    UserFromJSON,
    UserToJSON,
    UserCreateFromJSON,
    UserCreateToJSON,
    UserFilterFromJSON,
    UserFilterToJSON,
    UserFilterCreateFromJSON,
    UserFilterCreateToJSON,
    UserFilterUpdateFromJSON,
    UserFilterUpdateToJSON,
    UserPreferencesFromJSON,
    UserPreferencesToJSON,
    UserPreferencesUpdateFromJSON,
    UserPreferencesUpdateToJSON,
} from '../models';

export interface CreateMyUserFilterRequest {
    userFilterCreate: UserFilterCreate;
}

export interface CreateUserRequest {
    userCreate: UserCreate;
}

export interface GetMyUserFilterRequest {
    id: string;
}

export interface GetUserRequest {
    id: string;
}

export interface UpdateMyPasswordOperationRequest {
    updateMyPasswordRequest: UpdateMyPasswordRequest;
}

export interface UpdateMyUserFilterRequest {
    id: string;
    userFilterUpdate: UserFilterUpdate;
}

export interface UpdateMyUserPreferencesRequest {
    userPreferencesUpdate: UserPreferencesUpdate;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * 
     * @param {UserFilterCreate} userFilterCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    createMyUserFilterRaw(requestParameters: CreateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>>;

    /**
     */
    createMyUserFilter(requestParameters: CreateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter>;

    /**
     * 
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     */
    createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     */
    getMyUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyUserFilterRaw(requestParameters: GetMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>>;

    /**
     */
    getMyUserFilter(requestParameters: GetMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyUserPreferencesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPreferences>>;

    /**
     */
    getMyUserPreferences(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPreferences>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     */
    getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    listUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>>;

    /**
     */
    listUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>>;

    /**
     * 
     * @param {UpdateMyPasswordRequest} updateMyPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMyPasswordRaw(requestParameters: UpdateMyPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    updateMyPassword(requestParameters: UpdateMyPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UserFilterUpdate} userFilterUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMyUserFilterRaw(requestParameters: UpdateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>>;

    /**
     */
    updateMyUserFilter(requestParameters: UpdateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter>;

    /**
     * 
     * @param {UserPreferencesUpdate} userPreferencesUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMyUserPreferencesRaw(requestParameters: UpdateMyUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPreferences>>;

    /**
     */
    updateMyUserPreferences(requestParameters: UpdateMyUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPreferences>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     */
    async createMyUserFilterRaw(requestParameters: CreateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>> {
        if (requestParameters.userFilterCreate === null || requestParameters.userFilterCreate === undefined) {
            throw new runtime.RequiredError('userFilterCreate','Required parameter requestParameters.userFilterCreate was null or undefined when calling createMyUserFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/filters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserFilterCreateToJSON(requestParameters.userFilterCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFilterFromJSON(jsonValue));
    }

    /**
     */
    async createMyUserFilter(requestParameters: CreateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter> {
        const response = await this.createMyUserFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getMyUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getMyUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyUserFilterRaw(requestParameters: GetMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMyUserFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/filters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFilterFromJSON(jsonValue));
    }

    /**
     */
    async getMyUserFilter(requestParameters: GetMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter> {
        const response = await this.getMyUserFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyUserPreferencesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPreferences>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferencesFromJSON(jsonValue));
    }

    /**
     */
    async getMyUserPreferences(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPreferences> {
        const response = await this.getMyUserPreferencesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async listUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.listUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMyPasswordRaw(requestParameters: UpdateMyPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateMyPasswordRequest === null || requestParameters.updateMyPasswordRequest === undefined) {
            throw new runtime.RequiredError('updateMyPasswordRequest','Required parameter requestParameters.updateMyPasswordRequest was null or undefined when calling updateMyPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyPasswordRequestToJSON(requestParameters.updateMyPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateMyPassword(requestParameters: UpdateMyPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMyPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateMyUserFilterRaw(requestParameters: UpdateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFilter>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMyUserFilter.');
        }

        if (requestParameters.userFilterUpdate === null || requestParameters.userFilterUpdate === undefined) {
            throw new runtime.RequiredError('userFilterUpdate','Required parameter requestParameters.userFilterUpdate was null or undefined when calling updateMyUserFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/filters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserFilterUpdateToJSON(requestParameters.userFilterUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFilterFromJSON(jsonValue));
    }

    /**
     */
    async updateMyUserFilter(requestParameters: UpdateMyUserFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFilter> {
        const response = await this.updateMyUserFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMyUserPreferencesRaw(requestParameters: UpdateMyUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPreferences>> {
        if (requestParameters.userPreferencesUpdate === null || requestParameters.userPreferencesUpdate === undefined) {
            throw new runtime.RequiredError('userPreferencesUpdate','Required parameter requestParameters.userPreferencesUpdate was null or undefined when calling updateMyUserPreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferencesUpdateToJSON(requestParameters.userPreferencesUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferencesFromJSON(jsonValue));
    }

    /**
     */
    async updateMyUserPreferences(requestParameters: UpdateMyUserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPreferences> {
        const response = await this.updateMyUserPreferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
