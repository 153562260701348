/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministrativeUnitType } from './AdministrativeUnitType';
import {
    AdministrativeUnitTypeFromJSON,
    AdministrativeUnitTypeFromJSONTyped,
    AdministrativeUnitTypeToJSON,
} from './AdministrativeUnitType';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';

/**
 * 
 * @export
 * @interface AdministrativeUnitSummary
 */
export interface AdministrativeUnitSummary {
    /**
     * 
     * @type {Metadata}
     * @memberof AdministrativeUnitSummary
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitSummary
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitSummary
     */
    name: string;
    /**
     * 
     * @type {AdministrativeUnitType}
     * @memberof AdministrativeUnitSummary
     */
    type: AdministrativeUnitType;
}

/**
 * Check if a given object implements the AdministrativeUnitSummary interface.
 */
export function instanceOfAdministrativeUnitSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function AdministrativeUnitSummaryFromJSON(json: any): AdministrativeUnitSummary {
    return AdministrativeUnitSummaryFromJSONTyped(json, false);
}

export function AdministrativeUnitSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministrativeUnitSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'country': json['country'],
        'name': json['name'],
        'type': AdministrativeUnitTypeFromJSON(json['type']),
    };
}

export function AdministrativeUnitSummaryToJSON(value?: AdministrativeUnitSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'country': value.country,
        'name': value.name,
        'type': AdministrativeUnitTypeToJSON(value.type),
    };
}

