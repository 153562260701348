/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { ParcelAddress } from './ParcelAddress';
import {
    ParcelAddressFromJSON,
    ParcelAddressFromJSONTyped,
    ParcelAddressToJSON,
} from './ParcelAddress';
import type { ParcelArchaeologicalHeritage } from './ParcelArchaeologicalHeritage';
import {
    ParcelArchaeologicalHeritageFromJSON,
    ParcelArchaeologicalHeritageFromJSONTyped,
    ParcelArchaeologicalHeritageToJSON,
} from './ParcelArchaeologicalHeritage';
import type { ParcelConstrainedArea } from './ParcelConstrainedArea';
import {
    ParcelConstrainedAreaFromJSON,
    ParcelConstrainedAreaFromJSONTyped,
    ParcelConstrainedAreaToJSON,
} from './ParcelConstrainedArea';
import type { ParcelFloodRisk } from './ParcelFloodRisk';
import {
    ParcelFloodRiskFromJSON,
    ParcelFloodRiskFromJSONTyped,
    ParcelFloodRiskToJSON,
} from './ParcelFloodRisk';
import type { ParcelIdentifier } from './ParcelIdentifier';
import {
    ParcelIdentifierFromJSON,
    ParcelIdentifierFromJSONTyped,
    ParcelIdentifierToJSON,
} from './ParcelIdentifier';
import type { ParcelOwner } from './ParcelOwner';
import {
    ParcelOwnerFromJSON,
    ParcelOwnerFromJSONTyped,
    ParcelOwnerToJSON,
} from './ParcelOwner';
import type { ParcelOwnerGroup } from './ParcelOwnerGroup';
import {
    ParcelOwnerGroupFromJSON,
    ParcelOwnerGroupFromJSONTyped,
    ParcelOwnerGroupToJSON,
} from './ParcelOwnerGroup';
import type { ParcelPrimaryMasterPlans } from './ParcelPrimaryMasterPlans';
import {
    ParcelPrimaryMasterPlansFromJSON,
    ParcelPrimaryMasterPlansFromJSONTyped,
    ParcelPrimaryMasterPlansToJSON,
} from './ParcelPrimaryMasterPlans';
import type { ParcelProtectedHeritage } from './ParcelProtectedHeritage';
import {
    ParcelProtectedHeritageFromJSON,
    ParcelProtectedHeritageFromJSONTyped,
    ParcelProtectedHeritageToJSON,
} from './ParcelProtectedHeritage';
import type { ParcelProtectedNaturalResource } from './ParcelProtectedNaturalResource';
import {
    ParcelProtectedNaturalResourceFromJSON,
    ParcelProtectedNaturalResourceFromJSONTyped,
    ParcelProtectedNaturalResourceToJSON,
} from './ParcelProtectedNaturalResource';
import type { ParcelSection } from './ParcelSection';
import {
    ParcelSectionFromJSON,
    ParcelSectionFromJSONTyped,
    ParcelSectionToJSON,
} from './ParcelSection';
import type { ParcelSummary } from './ParcelSummary';
import {
    ParcelSummaryFromJSON,
    ParcelSummaryFromJSONTyped,
    ParcelSummaryToJSON,
} from './ParcelSummary';
import type { ParcelUpdateAllOf } from './ParcelUpdateAllOf';
import {
    ParcelUpdateAllOfFromJSON,
    ParcelUpdateAllOfFromJSONTyped,
    ParcelUpdateAllOfToJSON,
} from './ParcelUpdateAllOf';

/**
 * 
 * @export
 * @interface ParcelUpdate
 */
export interface ParcelUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof ParcelUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {ParcelAddress}
     * @memberof ParcelUpdate
     */
    address: ParcelAddress;
    /**
     * 
     * @type {ParcelIdentifier}
     * @memberof ParcelUpdate
     */
    identifier: ParcelIdentifier;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdate
     */
    geometry: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelUpdate
     */
    surfaceM2: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelUpdate
     */
    administrativeUnits: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelUpdate
     */
    cadastralDivisionUnits: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParcelUpdate
     */
    mainOwner?: string;
    /**
     * 
     * @type {Array<ParcelOwner>}
     * @memberof ParcelUpdate
     */
    owners?: Array<ParcelOwner>;
    /**
     * 
     * @type {Array<ParcelOwnerGroup>}
     * @memberof ParcelUpdate
     */
    ownerGroups?: Array<ParcelOwnerGroup>;
    /**
     * 
     * @type {Array<ParcelSection>}
     * @memberof ParcelUpdate
     */
    sections?: Array<ParcelSection>;
    /**
     * 
     * @type {Array<ParcelConstrainedArea>}
     * @memberof ParcelUpdate
     */
    constrainedAreas?: Array<ParcelConstrainedArea>;
    /**
     * 
     * @type {ParcelArchaeologicalHeritage}
     * @memberof ParcelUpdate
     */
    archaeologicalHeritage?: ParcelArchaeologicalHeritage;
    /**
     * 
     * @type {ParcelFloodRisk}
     * @memberof ParcelUpdate
     */
    floodRisk?: ParcelFloodRisk;
    /**
     * 
     * @type {Array<ParcelProtectedHeritage>}
     * @memberof ParcelUpdate
     */
    protectedHeritage?: Array<ParcelProtectedHeritage>;
    /**
     * 
     * @type {Array<ParcelProtectedNaturalResource>}
     * @memberof ParcelUpdate
     */
    protectedNaturalResources?: Array<ParcelProtectedNaturalResource>;
    /**
     * 
     * @type {Array<ParcelPrimaryMasterPlans>}
     * @memberof ParcelUpdate
     */
    primaryMasterPlans?: Array<ParcelPrimaryMasterPlans>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParcelUpdate
     */
    files?: Array<string>;
}

/**
 * Check if a given object implements the ParcelUpdate interface.
 */
export function instanceOfParcelUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "surfaceM2" in value;
    isInstance = isInstance && "administrativeUnits" in value;
    isInstance = isInstance && "cadastralDivisionUnits" in value;

    return isInstance;
}

export function ParcelUpdateFromJSON(json: any): ParcelUpdate {
    return ParcelUpdateFromJSONTyped(json, false);
}

export function ParcelUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'address': ParcelAddressFromJSON(json['address']),
        'identifier': ParcelIdentifierFromJSON(json['identifier']),
        'geometry': json['geometry'],
        'surfaceM2': json['surfaceM2'],
        'administrativeUnits': json['administrativeUnits'],
        'cadastralDivisionUnits': json['cadastralDivisionUnits'],
        'mainOwner': !exists(json, 'mainOwner') ? undefined : json['mainOwner'],
        'owners': !exists(json, 'owners') ? undefined : ((json['owners'] as Array<any>).map(ParcelOwnerFromJSON)),
        'ownerGroups': !exists(json, 'ownerGroups') ? undefined : ((json['ownerGroups'] as Array<any>).map(ParcelOwnerGroupFromJSON)),
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(ParcelSectionFromJSON)),
        'constrainedAreas': !exists(json, 'constrainedAreas') ? undefined : ((json['constrainedAreas'] as Array<any>).map(ParcelConstrainedAreaFromJSON)),
        'archaeologicalHeritage': !exists(json, 'archaeologicalHeritage') ? undefined : ParcelArchaeologicalHeritageFromJSON(json['archaeologicalHeritage']),
        'floodRisk': !exists(json, 'floodRisk') ? undefined : ParcelFloodRiskFromJSON(json['floodRisk']),
        'protectedHeritage': !exists(json, 'protectedHeritage') ? undefined : ((json['protectedHeritage'] as Array<any>).map(ParcelProtectedHeritageFromJSON)),
        'protectedNaturalResources': !exists(json, 'protectedNaturalResources') ? undefined : ((json['protectedNaturalResources'] as Array<any>).map(ParcelProtectedNaturalResourceFromJSON)),
        'primaryMasterPlans': !exists(json, 'primaryMasterPlans') ? undefined : ((json['primaryMasterPlans'] as Array<any>).map(ParcelPrimaryMasterPlansFromJSON)),
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function ParcelUpdateToJSON(value?: ParcelUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'address': ParcelAddressToJSON(value.address),
        'identifier': ParcelIdentifierToJSON(value.identifier),
        'geometry': value.geometry,
        'surfaceM2': value.surfaceM2,
        'administrativeUnits': value.administrativeUnits,
        'cadastralDivisionUnits': value.cadastralDivisionUnits,
        'mainOwner': value.mainOwner,
        'owners': value.owners === undefined ? undefined : ((value.owners as Array<any>).map(ParcelOwnerToJSON)),
        'ownerGroups': value.ownerGroups === undefined ? undefined : ((value.ownerGroups as Array<any>).map(ParcelOwnerGroupToJSON)),
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(ParcelSectionToJSON)),
        'constrainedAreas': value.constrainedAreas === undefined ? undefined : ((value.constrainedAreas as Array<any>).map(ParcelConstrainedAreaToJSON)),
        'archaeologicalHeritage': ParcelArchaeologicalHeritageToJSON(value.archaeologicalHeritage),
        'floodRisk': ParcelFloodRiskToJSON(value.floodRisk),
        'protectedHeritage': value.protectedHeritage === undefined ? undefined : ((value.protectedHeritage as Array<any>).map(ParcelProtectedHeritageToJSON)),
        'protectedNaturalResources': value.protectedNaturalResources === undefined ? undefined : ((value.protectedNaturalResources as Array<any>).map(ParcelProtectedNaturalResourceToJSON)),
        'primaryMasterPlans': value.primaryMasterPlans === undefined ? undefined : ((value.primaryMasterPlans as Array<any>).map(ParcelPrimaryMasterPlansToJSON)),
        'files': value.files,
    };
}

