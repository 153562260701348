/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { UserFilterViewType } from './UserFilterViewType';
import {
    UserFilterViewTypeFromJSON,
    UserFilterViewTypeFromJSONTyped,
    UserFilterViewTypeToJSON,
} from './UserFilterViewType';

/**
 * 
 * @export
 * @interface UserFilterUpdate
 */
export interface UserFilterUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof UserFilterUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof UserFilterUpdate
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilterUpdate
     */
    affiliated?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilterUpdate
     */
    businessProviders?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserFilterUpdate
     */
    partners?: Array<string>;
    /**
     * 
     * @type {UserFilterViewType}
     * @memberof UserFilterUpdate
     */
    viewType?: UserFilterViewType;
}

/**
 * Check if a given object implements the UserFilterUpdate interface.
 */
export function instanceOfUserFilterUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UserFilterUpdateFromJSON(json: any): UserFilterUpdate {
    return UserFilterUpdateFromJSONTyped(json, false);
}

export function UserFilterUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFilterUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'name': json['name'],
        'affiliated': !exists(json, 'affiliated') ? undefined : json['affiliated'],
        'businessProviders': !exists(json, 'businessProviders') ? undefined : json['businessProviders'],
        'partners': !exists(json, 'partners') ? undefined : json['partners'],
        'viewType': !exists(json, 'viewType') ? undefined : UserFilterViewTypeFromJSON(json['viewType']),
    };
}

export function UserFilterUpdateToJSON(value?: UserFilterUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'name': value.name,
        'affiliated': value.affiliated,
        'businessProviders': value.businessProviders,
        'partners': value.partners,
        'viewType': UserFilterViewTypeToJSON(value.viewType),
    };
}

