/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityQueryResultAllOf } from './OpportunityQueryResultAllOf';
import {
    OpportunityQueryResultAllOfFromJSON,
    OpportunityQueryResultAllOfFromJSONTyped,
    OpportunityQueryResultAllOfToJSON,
} from './OpportunityQueryResultAllOf';
import type { OpportunityQuerySummary } from './OpportunityQuerySummary';
import {
    OpportunityQuerySummaryFromJSON,
    OpportunityQuerySummaryFromJSONTyped,
    OpportunityQuerySummaryToJSON,
} from './OpportunityQuerySummary';
import type { QueryResult } from './QueryResult';
import {
    QueryResultFromJSON,
    QueryResultFromJSONTyped,
    QueryResultToJSON,
} from './QueryResult';

/**
 * 
 * @export
 * @interface OpportunityQueryResult
 */
export interface OpportunityQueryResult {
    /**
     * 
     * @type {number}
     * @memberof OpportunityQueryResult
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityQueryResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof OpportunityQueryResult
     */
    page: number;
    /**
     * 
     * @type {Array<OpportunityQuerySummary>}
     * @memberof OpportunityQueryResult
     */
    result: Array<OpportunityQuerySummary>;
}

/**
 * Check if a given object implements the OpportunityQueryResult interface.
 */
export function instanceOfOpportunityQueryResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function OpportunityQueryResultFromJSON(json: any): OpportunityQueryResult {
    return OpportunityQueryResultFromJSONTyped(json, false);
}

export function OpportunityQueryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityQueryResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'total': json['total'],
        'page': json['page'],
        'result': ((json['result'] as Array<any>).map(OpportunityQuerySummaryFromJSON)),
    };
}

export function OpportunityQueryResultToJSON(value?: OpportunityQueryResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'total': value.total,
        'page': value.page,
        'result': ((value.result as Array<any>).map(OpportunityQuerySummaryToJSON)),
    };
}

