import React from "react";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./features/user/UserProvider";
import * as serviceWorker from "./serviceWorker";
import { CollapseDrawerProvider } from "./lib/layout/CollapseDrawerContext";
import { ThemeProvider } from "./lib/layout/ThemeProvider";
import { HelmetProvider } from "react-helmet-async";
import NotistackProvider from "./lib/layout/components/NotistackProvider";
import { AppPreInitConfigProvider } from "./app/AppPreInitConfigProvider";
import { FirebaseProvider } from "./app/FirebaseProvider";
import { createRoot } from "react-dom/client";
import { TranslationProvider } from "./lib/layout/components/TranslationProvider";

const App = React.lazy(() => import("./App")); // to let initialization occur before executing any code

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <ErrorBoundary>
      <AppPreInitConfigProvider>
        <FirebaseProvider>
          <HelmetProvider>
            <TranslationProvider>
              <CollapseDrawerProvider>
                <UserProvider>
                  <BrowserRouter>
                    <NotistackProvider>
                      <App />
                    </NotistackProvider>
                  </BrowserRouter>
                </UserProvider>
              </CollapseDrawerProvider>
            </TranslationProvider>
          </HelmetProvider>
        </FirebaseProvider>
      </AppPreInitConfigProvider>
    </ErrorBoundary>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
