/* tslint:disable */
/* eslint-disable */
/**
 * Activities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Activity,
  ActivityQuery,
  ActivityQueryResult,
} from '../models';
import {
    ActivityFromJSON,
    ActivityToJSON,
    ActivityQueryFromJSON,
    ActivityQueryToJSON,
    ActivityQueryResultFromJSON,
    ActivityQueryResultToJSON,
} from '../models';

export interface GetActivityRequest {
    reference: string;
}

export interface QueryActivitiesRequest {
    activityQuery: ActivityQuery;
}

/**
 * ActivityApi - interface
 * 
 * @export
 * @interface ActivityApiInterface
 */
export interface ActivityApiInterface {
    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApiInterface
     */
    getActivityRaw(requestParameters: GetActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Activity>>;

    /**
     */
    getActivity(requestParameters: GetActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Activity>;

    /**
     * 
     * @param {ActivityQuery} activityQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApiInterface
     */
    queryActivitiesRaw(requestParameters: QueryActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityQueryResult>>;

    /**
     */
    queryActivities(requestParameters: QueryActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityQueryResult>;

}

/**
 * 
 */
export class ActivityApi extends runtime.BaseAPI implements ActivityApiInterface {

    /**
     */
    async getActivityRaw(requestParameters: GetActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async getActivity(requestParameters: GetActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Activity> {
        const response = await this.getActivityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryActivitiesRaw(requestParameters: QueryActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityQueryResult>> {
        if (requestParameters.activityQuery === null || requestParameters.activityQuery === undefined) {
            throw new runtime.RequiredError('activityQuery','Required parameter requestParameters.activityQuery was null or undefined when calling queryActivities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activaties/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityQueryToJSON(requestParameters.activityQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryActivities(requestParameters: QueryActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityQueryResult> {
        const response = await this.queryActivitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
