/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonMail
 */
export interface PersonMail {
    /**
     * 
     * @type {boolean}
     * @memberof PersonMail
     */
    default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonMail
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof PersonMail
     */
    type?: PersonMailTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PersonMailTypeEnum {
    Private = 'Private',
    Office = 'Office'
}


/**
 * Check if a given object implements the PersonMail interface.
 */
export function instanceOfPersonMail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mail" in value;

    return isInstance;
}

export function PersonMailFromJSON(json: any): PersonMail {
    return PersonMailFromJSONTyped(json, false);
}

export function PersonMailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonMail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default': !exists(json, 'default') ? undefined : json['default'],
        'mail': json['mail'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PersonMailToJSON(value?: PersonMail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default': value.default,
        'mail': value.mail,
        'type': value.type,
    };
}

