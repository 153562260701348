/* tslint:disable */
/* eslint-disable */
/**
 * Files
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FileType {
    OpportunityFolder = 'OpportunityFolder',
    BalanceSheet = 'BalanceSheet',
    CadastralExtract = 'CadastralExtract',
    CadastralExtractWithPlan = 'CadastralExtractWithPlan',
    CadastralExtractPlan = 'CadastralExtractPlan',
    MasterPlanWrittenPart = 'MasterPlanWrittenPart',
    MasterPlanGraphicalPart = 'MasterPlanGraphicalPart',
    ExistingDistrictSpecificDevelopmentPlanWrittenPart = 'ExistingDistrictSpecificDevelopmentPlanWrittenPart',
    ExistingDistrictSpecificDevelopmentPlanGraphicalPart = 'ExistingDistrictSpecificDevelopmentPlanGraphicalPart',
    NewDistrictSpecificDevelopmentPlanWrittenPart = 'NewDistrictSpecificDevelopmentPlanWrittenPart',
    NewDistrictSpecificDevelopmentPlanGraphicalPart = 'NewDistrictSpecificDevelopmentPlanGraphicalPart',
    GeneralDevelopmentPlanWrittenPart = 'GeneralDevelopmentPlanWrittenPart',
    GeneralDevelopmentPlanGraphicalPart = 'GeneralDevelopmentPlanGraphicalPart'
}


export function FileTypeFromJSON(json: any): FileType {
    return FileTypeFromJSONTyped(json, false);
}

export function FileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileType {
    return json as FileType;
}

export function FileTypeToJSON(value?: FileType | null): any {
    return value as any;
}

