/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocationReleaseTenancy
 */
export interface OpportunityLocationReleaseTenancy {
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationReleaseTenancy
     */
    areThereAgreements?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationReleaseTenancy
     */
    areThereTenants?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpportunityLocationReleaseTenancy
     */
    estimatedReleaseDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationReleaseTenancy
     */
    isThereALeaseContractCopy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationReleaseTenancy
     */
    leaseType?: OpportunityLocationReleaseTenancyLeaseTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityLocationReleaseTenancyLeaseTypeEnum {
    Residential = 'Residential',
    Commercial = 'Commercial'
}


/**
 * Check if a given object implements the OpportunityLocationReleaseTenancy interface.
 */
export function instanceOfOpportunityLocationReleaseTenancy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationReleaseTenancyFromJSON(json: any): OpportunityLocationReleaseTenancy {
    return OpportunityLocationReleaseTenancyFromJSONTyped(json, false);
}

export function OpportunityLocationReleaseTenancyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationReleaseTenancy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areThereAgreements': !exists(json, 'areThereAgreements') ? undefined : json['areThereAgreements'],
        'areThereTenants': !exists(json, 'areThereTenants') ? undefined : json['areThereTenants'],
        'estimatedReleaseDate': !exists(json, 'estimatedReleaseDate') ? undefined : (new Date(json['estimatedReleaseDate'])),
        'isThereALeaseContractCopy': !exists(json, 'isThereALeaseContractCopy') ? undefined : json['isThereALeaseContractCopy'],
        'leaseType': !exists(json, 'leaseType') ? undefined : json['leaseType'],
    };
}

export function OpportunityLocationReleaseTenancyToJSON(value?: OpportunityLocationReleaseTenancy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areThereAgreements': value.areThereAgreements,
        'areThereTenants': value.areThereTenants,
        'estimatedReleaseDate': value.estimatedReleaseDate === undefined ? undefined : (value.estimatedReleaseDate.toISOString().substr(0,10)),
        'isThereALeaseContractCopy': value.isThereALeaseContractCopy,
        'leaseType': value.leaseType,
    };
}

