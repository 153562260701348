import {BaseAPI, Configuration, ConfigurationParameters, Person, PersonApi} from "../../openapi";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as runtime from "../../openapi/runtime";
import {useClusterUrl} from "../../app/AppPreInitConfigProvider";
import {useFirebaseToken} from "../../features/user/UserProvider";
import {io, Socket} from "socket.io-client";
import ObjectId from "bson-objectid";

import * as _ from 'lodash';
import {useEffectDeep, useUniqueIdWithGenerator} from "../../lib/misc/memo";

type GetFirstArgumentOfAnyFunction<T> = T extends (
        first: infer FirstArgument,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ) => any
    ? FirstArgument extends RequestInit | runtime.InitOverrideFunction ? never : FirstArgument : never

type GetReturnTypeOfAnyPromiseFunction<T> = T extends (
        ...args: any[]
    ) => Promise<infer R>
    ? R
    : never

export function useOpenApiQuery<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]> & never, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M): {error: Error|undefined, data: R|undefined, loading: boolean, refetch: () => void};
export function useOpenApiQuery<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M, param: Q): {error: Error|undefined, data: R|undefined, loading: boolean, refetch: () => void};
export function useOpenApiQuery<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M, param?: Q): {error: Error|undefined, data: R|undefined, loading: boolean, refetch: () => void}{
    const api = useOpenApi(apiCls);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<R>();
    const [loading, setLoading] = useState(true);
    const [refetchCount, setRefetchCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const controller = new AbortController();
        const signal = controller.signal;
        const params = param === undefined ? [{signal}] : [param, {signal}];
        (api as unknown as {[k in M]: (...args: any) => Promise<R>})[method].apply(api, params)
            .then(setData)
            .catch(e => {
                if (!signal.aborted) setError(e);
            })
            .finally(() => {
                if (!signal.aborted) setLoading(false)
            });
        return () => controller.abort()
    }, [method, param, refetchCount]);

    const s = useMemo(() => {
        return {error, data, loading, refetch: () => setRefetchCount(refetchCount + 1)};
    }, [error, data, loading, refetchCount]);

    return s;
}

export function useOpenApiQueryLazy<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M): {error: Error|undefined, data: R|undefined, loading: boolean, refetch: (param: Q) => Promise<R>};
export function useOpenApiQueryLazy<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M): {error: Error|undefined, data: R|undefined, loading: boolean, refetch: (param: Q) => Promise<R>}{
    const api = useOpenApi(apiCls);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<R>();
    const [loading, setLoading] = useState(true);
    const [controller, setController] = useState<AbortController>();

    const refetch = useCallback((param: Q) => {
        const queryController = new AbortController();
        setController(queryController);
        setLoading(true);
        const signal = queryController.signal;
        const params = param === undefined ? [{signal}] : [param, {signal}];
        return (api as unknown as { [k in M]: (...args: any) => Promise<R> })[method].apply(api, params)
            .then(d => {
                setData(d);
                return d;
            })
            .catch(e => {
                if (!signal.aborted) setError(e);
                throw e;
            })
            .finally(() => {
                if (!signal.aborted) setLoading(false)
            });
    }, []);

    useEffect(() => {
        return () => controller && controller.abort();
    }, [controller]);

    const s = useMemo(() => {
        return {error, data, loading, refetch};
    }, [error, data, loading, refetch]);

    return s;
}

export function useOpenApiQueryWithSubscription<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]> & never, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M, jsonConverter: (i: any) => R): {error: Error|undefined, data: R|undefined, loading: boolean};
export function useOpenApiQueryWithSubscription<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M, jsonConverter: (i: any) => R, param: Q): {error: Error|undefined, data: R|undefined, loading: boolean};
export function useOpenApiQueryWithSubscription<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M, jsonConverter: (i: any) => R, param?: Q): {error: Error|undefined, data: R|undefined, loading: boolean, connected: boolean}{
    const api = useOpenApi(apiCls);
    const socket = useSocketIO();
    const [socketId, setSocketId] = useState(socket.id);
    const [socketConnected, setSocketConnected] = useState(socket.connected);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<R>();
    const [loading, setLoading] = useState(true);
    const [refetchCount, setRefetchCount] = useState(0);
    const subscriptionId = useUniqueIdWithGenerator(() => `${apiCls.name}-${method}-${new ObjectId().toHexString()}`,
        [method, param, refetchCount, socketId, socketConnected]);

    useEffect(() => {
        const socketDisconnectListener = () => {
            setSocketConnected(false);
        }

        const socketConnectListener = () => {
            setSocketConnected(true);
            setSocketId(socket.id);
        }

        socket.on('disconnect', socketDisconnectListener);
        socket.on('connect', socketConnectListener);
        return () => {
            socket.off('disconnect', socketDisconnectListener);
            socket.off('connect', socketConnectListener);
        }
    }, [socket]);

    useEffectDeep(() => {
        if (!subscriptionId) return;
        setLoading(true);
        const controller = new AbortController();
        const signal = controller.signal;
        let requestInit: RequestInit | runtime.InitOverrideFunction = {signal};
        let dataSender: string|undefined;

        const socketDataListener: (from: {id: string}, room: string, data: any) => void = ({id: from}, dataSubscriptionId, data) => {
            if (dataSubscriptionId == subscriptionId) {
                dataSender = from;
                setData(jsonConverter(data));
            }
        };
        const socketLeaveListener: (room: string, socket: {id: string}) => void = (subscription, {id: clientId}) => {
            if (subscription == subscriptionId && clientId == dataSender) {
                console.warn(`sending service ${clientId} for subscription ${subscriptionId} seems disconnected`);
                setRefetchCount(refetchCount + 1);
            }
        }
        if (socketConnected) {
            requestInit = async ({init}) => {
                return {
                    signal,
                    headers: {
                        ...init.headers,
                        "X-Subscription-Client-ID": socketId,
                        "X-Subscription-ID": subscriptionId

                    }
                }
            }
            socket.emit("join", subscriptionId);
            socket.on("data", socketDataListener);
            socket.on("leave", socketLeaveListener);
        }
        const params = param === undefined ? [requestInit] : [param, requestInit];
        (api as unknown as {[k in M]: (...args: any) => Promise<R>})[method].apply(api, params)
            .then(setData)
            .catch(e => {
                if (!signal.aborted) setError(e);
            })
            .finally(() => {
                if (!signal.aborted) setLoading(false)
            });
        return () => {
            controller.abort();
            if (socketConnected) {
                socket.emit("leave", subscriptionId);
                socket.off("data", socketDataListener);
                socket.off("leave", socketLeaveListener);
            }
        }
    }, [method, param, refetchCount, subscriptionId, socketId, socketConnected]);

    const s = useMemo(() => {
        return {error, data, loading: loading && !data, connected: socketConnected};
    }, [error, data, loading, socketConnected]);

    return s;
}

export function useOpenApiMutation<T extends BaseAPI, M extends string & keyof T, Q extends GetFirstArgumentOfAnyFunction<T[M]>, R extends GetReturnTypeOfAnyPromiseFunction<T[M]>>(apiCls: {new(c: Configuration): T}, method: M) {
    const api = useOpenApi(apiCls);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<R>();
    const [loading, setLoading] = useState(false);
    const [lastController, setLastController] = useState<AbortController>()

    const send: (p: Q) => Promise<R> = useCallback((param: Q) => {
        setLoading(true);
        setError(undefined);
        if (lastController) lastController.abort()
        const controller = new AbortController();
        setLastController(controller);

        const signal = controller.signal;
        const p = (api as unknown as {[k in M]: (p: Q, init?: RequestInit | runtime.InitOverrideFunction) => Promise<R>})[method](param, {signal});
            p.then(setData)
            .catch(e => {
                if (!signal.aborted) setError(e);
            })
            .finally(() => {
                if (!signal.aborted) setLoading(false)
            });
        return p;
    }, [lastController]);

    const s = useMemo(() => {
        return {error, data, loading, send};
    }, [error, data, loading, send]);

    return s;
}

const configParameters: ConfigurationParameters = {};
const config = new Configuration(configParameters);
const apiCache: {cls: {new(c: Configuration): any}, instance: BaseAPI}[] = [];

export function setOpenApiToken(accessToken: string) {
    if (configParameters.accessToken != accessToken) {
        configParameters.accessToken = accessToken;
    }
}

export function useOpenApi<T extends BaseAPI>(cls: {new(c: Configuration): T}) {
    const appEngineUrl = useClusterUrl();
    const accessToken = useFirebaseToken();
    if (appEngineUrl && configParameters.basePath != appEngineUrl) {
        configParameters.basePath = appEngineUrl;
    }
    accessToken && setOpenApiToken(accessToken);

    const inCache = apiCache.find(({cls: inCacheCls}) => inCacheCls == cls);
    if (inCache) return inCache.instance as T;

    const instance = new cls(config);
    apiCache.push({cls, instance});
    return instance;
}

let socket: Socket;
function useSocketIO() {
    const appEngineUrl = useClusterUrl();
    if (!socket) {
        socket = io(`${appEngineUrl}/ws`);
        socket.on("connect", () => {
            console.log(`Socket.io connected with id ${socket.id}`);
        });
        socket.on("disconnect", () => {
            console.warn("Socket.io disconnected");
        });
    }
    return socket;
}

export const referenceToId = (ref: string): string => _.last(ref.split("/")) || '';