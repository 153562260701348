/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { PersonQuerySummaryAllOf } from './PersonQuerySummaryAllOf';
import {
    PersonQuerySummaryAllOfFromJSON,
    PersonQuerySummaryAllOfFromJSONTyped,
    PersonQuerySummaryAllOfToJSON,
} from './PersonQuerySummaryAllOf';
import type { PersonRole } from './PersonRole';
import {
    PersonRoleFromJSON,
    PersonRoleFromJSONTyped,
    PersonRoleToJSON,
} from './PersonRole';
import type { PersonSummary } from './PersonSummary';
import {
    PersonSummaryFromJSON,
    PersonSummaryFromJSONTyped,
    PersonSummaryToJSON,
} from './PersonSummary';
import type { PersonType } from './PersonType';
import {
    PersonTypeFromJSON,
    PersonTypeFromJSONTyped,
    PersonTypeToJSON,
} from './PersonType';

/**
 * 
 * @export
 * @interface PersonQuerySummary
 */
export interface PersonQuerySummary {
    /**
     * 
     * @type {Metadata}
     * @memberof PersonQuerySummary
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof PersonQuerySummary
     */
    name: string;
    /**
     * 
     * @type {PersonType}
     * @memberof PersonQuerySummary
     */
    type: PersonType;
    /**
     * 
     * @type {PersonRole}
     * @memberof PersonQuerySummary
     */
    role: PersonRole;
    /**
     * 
     * @type {boolean}
     * @memberof PersonQuerySummary
     */
    isUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonQuerySummary
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersonQuerySummary
     */
    highlight?: string;
}

/**
 * Check if a given object implements the PersonQuerySummary interface.
 */
export function instanceOfPersonQuerySummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function PersonQuerySummaryFromJSON(json: any): PersonQuerySummary {
    return PersonQuerySummaryFromJSONTyped(json, false);
}

export function PersonQuerySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonQuerySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataFromJSON(json['metadata']),
        'name': json['name'],
        'type': PersonTypeFromJSON(json['type']),
        'role': PersonRoleFromJSON(json['role']),
        'isUser': !exists(json, 'isUser') ? undefined : json['isUser'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'highlight': !exists(json, 'highlight') ? undefined : json['highlight'],
    };
}

export function PersonQuerySummaryToJSON(value?: PersonQuerySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataToJSON(value.metadata),
        'name': value.name,
        'type': PersonTypeToJSON(value.type),
        'role': PersonRoleToJSON(value.role),
        'isUser': value.isUser,
        'disabled': value.disabled,
        'highlight': value.highlight,
    };
}

