/* tslint:disable */
/* eslint-disable */
/**
 * Users
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { PersonAddress } from './PersonAddress';
import {
    PersonAddressFromJSON,
    PersonAddressFromJSONTyped,
    PersonAddressToJSON,
} from './PersonAddress';
import type { PersonBusinessProviderOptions } from './PersonBusinessProviderOptions';
import {
    PersonBusinessProviderOptionsFromJSON,
    PersonBusinessProviderOptionsFromJSONTyped,
    PersonBusinessProviderOptionsToJSON,
} from './PersonBusinessProviderOptions';
import type { PersonMail } from './PersonMail';
import {
    PersonMailFromJSON,
    PersonMailFromJSONTyped,
    PersonMailToJSON,
} from './PersonMail';
import type { PersonPhone } from './PersonPhone';
import {
    PersonPhoneFromJSON,
    PersonPhoneFromJSONTyped,
    PersonPhoneToJSON,
} from './PersonPhone';
import type { PersonProspecting } from './PersonProspecting';
import {
    PersonProspectingFromJSON,
    PersonProspectingFromJSONTyped,
    PersonProspectingToJSON,
} from './PersonProspecting';
import type { PersonRole } from './PersonRole';
import {
    PersonRoleFromJSON,
    PersonRoleFromJSONTyped,
    PersonRoleToJSON,
} from './PersonRole';
import type { PersonSex } from './PersonSex';
import {
    PersonSexFromJSON,
    PersonSexFromJSONTyped,
    PersonSexToJSON,
} from './PersonSex';
import type { PersonSummaryUpdate } from './PersonSummaryUpdate';
import {
    PersonSummaryUpdateFromJSON,
    PersonSummaryUpdateFromJSONTyped,
    PersonSummaryUpdateToJSON,
} from './PersonSummaryUpdate';
import type { PersonType } from './PersonType';
import {
    PersonTypeFromJSON,
    PersonTypeFromJSONTyped,
    PersonTypeToJSON,
} from './PersonType';
import type { PersonUpdateAllOf } from './PersonUpdateAllOf';
import {
    PersonUpdateAllOfFromJSON,
    PersonUpdateAllOfFromJSONTyped,
    PersonUpdateAllOfToJSON,
} from './PersonUpdateAllOf';

/**
 * 
 * @export
 * @interface PersonUpdate
 */
export interface PersonUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof PersonUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    name: string;
    /**
     * 
     * @type {PersonType}
     * @memberof PersonUpdate
     */
    type: PersonType;
    /**
     * 
     * @type {PersonRole}
     * @memberof PersonUpdate
     */
    role: PersonRole;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    familyName?: string;
    /**
     * 
     * @type {PersonSex}
     * @memberof PersonUpdate
     */
    sex?: PersonSex;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    title?: string;
    /**
     * 
     * @type {PersonAddress}
     * @memberof PersonUpdate
     */
    address?: PersonAddress;
    /**
     * 
     * @type {Array<PersonMail>}
     * @memberof PersonUpdate
     */
    emails?: Array<PersonMail>;
    /**
     * 
     * @type {Array<PersonPhone>}
     * @memberof PersonUpdate
     */
    phones?: Array<PersonPhone>;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    mainContact?: string;
    /**
     * 
     * @type {PersonBusinessProviderOptions}
     * @memberof PersonUpdate
     */
    businessProviderOptions?: PersonBusinessProviderOptions;
    /**
     * 
     * @type {PersonProspecting}
     * @memberof PersonUpdate
     */
    prospecting?: PersonProspecting;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdate
     */
    notes?: string;
}

/**
 * Check if a given object implements the PersonUpdate interface.
 */
export function instanceOfPersonUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function PersonUpdateFromJSON(json: any): PersonUpdate {
    return PersonUpdateFromJSONTyped(json, false);
}

export function PersonUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'name': json['name'],
        'type': PersonTypeFromJSON(json['type']),
        'role': PersonRoleFromJSON(json['role']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'sex': !exists(json, 'sex') ? undefined : PersonSexFromJSON(json['sex']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'address': !exists(json, 'address') ? undefined : PersonAddressFromJSON(json['address']),
        'emails': !exists(json, 'emails') ? undefined : ((json['emails'] as Array<any>).map(PersonMailFromJSON)),
        'phones': !exists(json, 'phones') ? undefined : ((json['phones'] as Array<any>).map(PersonPhoneFromJSON)),
        'mainContact': !exists(json, 'mainContact') ? undefined : json['mainContact'],
        'businessProviderOptions': !exists(json, 'businessProviderOptions') ? undefined : PersonBusinessProviderOptionsFromJSON(json['businessProviderOptions']),
        'prospecting': !exists(json, 'prospecting') ? undefined : PersonProspectingFromJSON(json['prospecting']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function PersonUpdateToJSON(value?: PersonUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'name': value.name,
        'type': PersonTypeToJSON(value.type),
        'role': PersonRoleToJSON(value.role),
        'firstName': value.firstName,
        'middleName': value.middleName,
        'familyName': value.familyName,
        'sex': PersonSexToJSON(value.sex),
        'title': value.title,
        'address': PersonAddressToJSON(value.address),
        'emails': value.emails === undefined ? undefined : ((value.emails as Array<any>).map(PersonMailToJSON)),
        'phones': value.phones === undefined ? undefined : ((value.phones as Array<any>).map(PersonPhoneToJSON)),
        'mainContact': value.mainContact,
        'businessProviderOptions': PersonBusinessProviderOptionsToJSON(value.businessProviderOptions),
        'prospecting': PersonProspectingToJSON(value.prospecting),
        'notes': value.notes,
    };
}

