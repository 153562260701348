/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BuildingAlignmentLocation {
    Front = 'Front',
    Back = 'Back',
    Lateral = 'Lateral'
}


export function BuildingAlignmentLocationFromJSON(json: any): BuildingAlignmentLocation {
    return BuildingAlignmentLocationFromJSONTyped(json, false);
}

export function BuildingAlignmentLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingAlignmentLocation {
    return json as BuildingAlignmentLocation;
}

export function BuildingAlignmentLocationToJSON(value?: BuildingAlignmentLocation | null): any {
    return value as any;
}

