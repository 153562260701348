import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";

export function ucfirst(str: string): string {
    if (!str || str.length === 0) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function capitalize(str: string): string {
    if (!str || str.length === 0) return str;
    return str.split(' ').map(ucfirst).join(' ');
}

export function useTranslationWithFormats(ns: string | string[]) {
    const {t} = useTranslation(ns);
    // @ts-ignore
    const Tt: TFunction<string|string[], undefined> = (...args) => ucfirst(t.apply(t, args) as string);
    // @ts-ignore
    const T: TFunction<string|string[], undefined> = (...args) => (t.apply(t, args) as string).toUpperCase()
    // @ts-ignore
    const TT: TFunction<string|string[], undefined> = (...args) => capitalize(t.apply(t, args) as string);
    return {t, Tt, T, TT};
}
