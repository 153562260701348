/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityAuthorizationExecutionAgreementDocument } from './OpportunityAuthorizationExecutionAgreementDocument';
import {
    OpportunityAuthorizationExecutionAgreementDocumentFromJSON,
    OpportunityAuthorizationExecutionAgreementDocumentFromJSONTyped,
    OpportunityAuthorizationExecutionAgreementDocumentToJSON,
} from './OpportunityAuthorizationExecutionAgreementDocument';

/**
 * 
 * @export
 * @interface OpportunityAuthorizationsExecutionAgreement
 */
export interface OpportunityAuthorizationsExecutionAgreement {
    /**
     * 
     * @type {Date}
     * @memberof OpportunityAuthorizationsExecutionAgreement
     */
    authorizationDate?: Date;
    /**
     * 
     * @type {Array<OpportunityAuthorizationExecutionAgreementDocument>}
     * @memberof OpportunityAuthorizationsExecutionAgreement
     */
    documents?: Array<OpportunityAuthorizationExecutionAgreementDocument>;
}

/**
 * Check if a given object implements the OpportunityAuthorizationsExecutionAgreement interface.
 */
export function instanceOfOpportunityAuthorizationsExecutionAgreement(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityAuthorizationsExecutionAgreementFromJSON(json: any): OpportunityAuthorizationsExecutionAgreement {
    return OpportunityAuthorizationsExecutionAgreementFromJSONTyped(json, false);
}

export function OpportunityAuthorizationsExecutionAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityAuthorizationsExecutionAgreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationDate': !exists(json, 'authorizationDate') ? undefined : (new Date(json['authorizationDate'])),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(OpportunityAuthorizationExecutionAgreementDocumentFromJSON)),
    };
}

export function OpportunityAuthorizationsExecutionAgreementToJSON(value?: OpportunityAuthorizationsExecutionAgreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationDate': value.authorizationDate === undefined ? undefined : (value.authorizationDate.toISOString().substr(0,10)),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(OpportunityAuthorizationExecutionAgreementDocumentToJSON)),
    };
}

