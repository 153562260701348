/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelOwnerShare
 */
export interface ParcelOwnerShare {
    /**
     * 
     * @type {number}
     * @memberof ParcelOwnerShare
     */
    denominator: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelOwnerShare
     */
    numerator: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelOwnerShare
     */
    ratio: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelOwnerShare
     */
    type: ParcelOwnerShareTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ParcelOwnerShareTypeEnum {
    FullOwnership = 'FullOwnership',
    BareOwnership = 'BareOwnership',
    Usufruct = 'Usufruct',
    Emphyteusis = 'Emphyteusis',
    Subsurface = 'Subsurface',
    Superficiary = 'Superficiary'
}


/**
 * Check if a given object implements the ParcelOwnerShare interface.
 */
export function instanceOfParcelOwnerShare(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "denominator" in value;
    isInstance = isInstance && "numerator" in value;
    isInstance = isInstance && "ratio" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ParcelOwnerShareFromJSON(json: any): ParcelOwnerShare {
    return ParcelOwnerShareFromJSONTyped(json, false);
}

export function ParcelOwnerShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelOwnerShare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominator': json['denominator'],
        'numerator': json['numerator'],
        'ratio': json['ratio'],
        'type': json['type'],
    };
}

export function ParcelOwnerShareToJSON(value?: ParcelOwnerShare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominator': value.denominator,
        'numerator': value.numerator,
        'ratio': value.ratio,
        'type': value.type,
    };
}

