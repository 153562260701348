/* tslint:disable */
/* eslint-disable */
/**
 * Administrative units
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministrativeUnitCreateAllOf } from './AdministrativeUnitCreateAllOf';
import {
    AdministrativeUnitCreateAllOfFromJSON,
    AdministrativeUnitCreateAllOfFromJSONTyped,
    AdministrativeUnitCreateAllOfToJSON,
} from './AdministrativeUnitCreateAllOf';
import type { AdministrativeUnitType } from './AdministrativeUnitType';
import {
    AdministrativeUnitTypeFromJSON,
    AdministrativeUnitTypeFromJSONTyped,
    AdministrativeUnitTypeToJSON,
} from './AdministrativeUnitType';
import type { AdministrativeUnitUpdate } from './AdministrativeUnitUpdate';
import {
    AdministrativeUnitUpdateFromJSON,
    AdministrativeUnitUpdateFromJSONTyped,
    AdministrativeUnitUpdateToJSON,
} from './AdministrativeUnitUpdate';
import type { MetadataCreate } from './MetadataCreate';
import {
    MetadataCreateFromJSON,
    MetadataCreateFromJSONTyped,
    MetadataCreateToJSON,
} from './MetadataCreate';

/**
 * 
 * @export
 * @interface AdministrativeUnitCreate
 */
export interface AdministrativeUnitCreate {
    /**
     * 
     * @type {MetadataCreate}
     * @memberof AdministrativeUnitCreate
     */
    metadata: MetadataCreate;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitCreate
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitCreate
     */
    name: string;
    /**
     * 
     * @type {AdministrativeUnitType}
     * @memberof AdministrativeUnitCreate
     */
    type: AdministrativeUnitType;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitCreate
     */
    geometry: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AdministrativeUnitCreate
     */
    translatedName?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AdministrativeUnitCreate
     */
    capital?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdministrativeUnitCreate
     */
    parents?: Array<string>;
}

/**
 * Check if a given object implements the AdministrativeUnitCreate interface.
 */
export function instanceOfAdministrativeUnitCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function AdministrativeUnitCreateFromJSON(json: any): AdministrativeUnitCreate {
    return AdministrativeUnitCreateFromJSONTyped(json, false);
}

export function AdministrativeUnitCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministrativeUnitCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataCreateFromJSON(json['metadata']),
        'country': json['country'],
        'name': json['name'],
        'type': AdministrativeUnitTypeFromJSON(json['type']),
        'geometry': json['geometry'],
        'translatedName': !exists(json, 'translatedName') ? undefined : json['translatedName'],
        'capital': !exists(json, 'capital') ? undefined : json['capital'],
        'parents': !exists(json, 'parents') ? undefined : json['parents'],
    };
}

export function AdministrativeUnitCreateToJSON(value?: AdministrativeUnitCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataCreateToJSON(value.metadata),
        'country': value.country,
        'name': value.name,
        'type': AdministrativeUnitTypeToJSON(value.type),
        'geometry': value.geometry,
        'translatedName': value.translatedName,
        'capital': value.capital,
        'parents': value.parents,
    };
}

