/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Parcel,
  ParcelCreate,
  ParcelQuery,
  ParcelQueryResult,
  ParcelUpdate,
} from '../models';
import {
    ParcelFromJSON,
    ParcelToJSON,
    ParcelCreateFromJSON,
    ParcelCreateToJSON,
    ParcelQueryFromJSON,
    ParcelQueryToJSON,
    ParcelQueryResultFromJSON,
    ParcelQueryResultToJSON,
    ParcelUpdateFromJSON,
    ParcelUpdateToJSON,
} from '../models';

export interface CreateParcelRequest {
    parcelCreate: ParcelCreate;
}

export interface FillParcelCadastralInfoRequest {
    parcelUpdate: ParcelUpdate;
}

export interface GetOrCreateParcelRequest {
    parcelCreate: ParcelCreate;
}

export interface GetParcelRequest {
    id: string;
}

export interface GetParcelAtLocationRequest {
    lon: number;
    lat: number;
}

export interface QueryParcelsRequest {
    parcelQuery: ParcelQuery;
}

export interface RefreshParcelCadastralInfoRequest {
    id: string;
}

export interface UpdateParcelRequest {
    id: string;
    parcelUpdate: ParcelUpdate;
}

/**
 * ParcelApi - interface
 * 
 * @export
 * @interface ParcelApiInterface
 */
export interface ParcelApiInterface {
    /**
     * 
     * @param {ParcelCreate} parcelCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    createParcelRaw(requestParameters: CreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    createParcel(requestParameters: CreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

    /**
     * 
     * @param {ParcelUpdate} parcelUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    fillParcelCadastralInfoRaw(requestParameters: FillParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcelUpdate>>;

    /**
     */
    fillParcelCadastralInfo(requestParameters: FillParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcelUpdate>;

    /**
     * 
     * @param {ParcelCreate} parcelCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    getOrCreateParcelRaw(requestParameters: GetOrCreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    getOrCreateParcel(requestParameters: GetOrCreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    getParcelRaw(requestParameters: GetParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    getParcel(requestParameters: GetParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

    /**
     * 
     * @param {number} lon 
     * @param {number} lat 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    getParcelAtLocationRaw(requestParameters: GetParcelAtLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    getParcelAtLocation(requestParameters: GetParcelAtLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

    /**
     * 
     * @param {ParcelQuery} parcelQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    queryParcelsRaw(requestParameters: QueryParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcelQueryResult>>;

    /**
     */
    queryParcels(requestParameters: QueryParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcelQueryResult>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    refreshParcelCadastralInfoRaw(requestParameters: RefreshParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    refreshParcelCadastralInfo(requestParameters: RefreshParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

    /**
     * 
     * @param {string} id 
     * @param {ParcelUpdate} parcelUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParcelApiInterface
     */
    updateParcelRaw(requestParameters: UpdateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>>;

    /**
     */
    updateParcel(requestParameters: UpdateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel>;

}

/**
 * 
 */
export class ParcelApi extends runtime.BaseAPI implements ParcelApiInterface {

    /**
     */
    async createParcelRaw(requestParameters: CreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.parcelCreate === null || requestParameters.parcelCreate === undefined) {
            throw new runtime.RequiredError('parcelCreate','Required parameter requestParameters.parcelCreate was null or undefined when calling createParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelCreateToJSON(requestParameters.parcelCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async createParcel(requestParameters: CreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.createParcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fillParcelCadastralInfoRaw(requestParameters: FillParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcelUpdate>> {
        if (requestParameters.parcelUpdate === null || requestParameters.parcelUpdate === undefined) {
            throw new runtime.RequiredError('parcelUpdate','Required parameter requestParameters.parcelUpdate was null or undefined when calling fillParcelCadastralInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/cadastre/fill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelUpdateToJSON(requestParameters.parcelUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelUpdateFromJSON(jsonValue));
    }

    /**
     */
    async fillParcelCadastralInfo(requestParameters: FillParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcelUpdate> {
        const response = await this.fillParcelCadastralInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrCreateParcelRaw(requestParameters: GetOrCreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.parcelCreate === null || requestParameters.parcelCreate === undefined) {
            throw new runtime.RequiredError('parcelCreate','Required parameter requestParameters.parcelCreate was null or undefined when calling getOrCreateParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelCreateToJSON(requestParameters.parcelCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async getOrCreateParcel(requestParameters: GetOrCreateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.getOrCreateParcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getParcelRaw(requestParameters: GetParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async getParcel(requestParameters: GetParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.getParcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getParcelAtLocationRaw(requestParameters: GetParcelAtLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.lon === null || requestParameters.lon === undefined) {
            throw new runtime.RequiredError('lon','Required parameter requestParameters.lon was null or undefined when calling getParcelAtLocation.');
        }

        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling getParcelAtLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/at/{lon}/{lat}`.replace(`{${"lon"}}`, encodeURIComponent(String(requestParameters.lon))).replace(`{${"lat"}}`, encodeURIComponent(String(requestParameters.lat))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async getParcelAtLocation(requestParameters: GetParcelAtLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.getParcelAtLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async queryParcelsRaw(requestParameters: QueryParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcelQueryResult>> {
        if (requestParameters.parcelQuery === null || requestParameters.parcelQuery === undefined) {
            throw new runtime.RequiredError('parcelQuery','Required parameter requestParameters.parcelQuery was null or undefined when calling queryParcels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelQueryToJSON(requestParameters.parcelQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelQueryResultFromJSON(jsonValue));
    }

    /**
     */
    async queryParcels(requestParameters: QueryParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcelQueryResult> {
        const response = await this.queryParcelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshParcelCadastralInfoRaw(requestParameters: RefreshParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling refreshParcelCadastralInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/{id}/cadastre/refresh`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async refreshParcelCadastralInfo(requestParameters: RefreshParcelCadastralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.refreshParcelCadastralInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateParcelRaw(requestParameters: UpdateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Parcel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateParcel.');
        }

        if (requestParameters.parcelUpdate === null || requestParameters.parcelUpdate === undefined) {
            throw new runtime.RequiredError('parcelUpdate','Required parameter requestParameters.parcelUpdate was null or undefined when calling updateParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parcels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParcelUpdateToJSON(requestParameters.parcelUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelFromJSON(jsonValue));
    }

    /**
     */
    async updateParcel(requestParameters: UpdateParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Parcel> {
        const response = await this.updateParcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
