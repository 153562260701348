/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityAuthorizationOtherAuthorization } from './OpportunityAuthorizationOtherAuthorization';
import {
    OpportunityAuthorizationOtherAuthorizationFromJSON,
    OpportunityAuthorizationOtherAuthorizationFromJSONTyped,
    OpportunityAuthorizationOtherAuthorizationToJSON,
} from './OpportunityAuthorizationOtherAuthorization';
import type { OpportunityAuthorizationsBuildingPermit } from './OpportunityAuthorizationsBuildingPermit';
import {
    OpportunityAuthorizationsBuildingPermitFromJSON,
    OpportunityAuthorizationsBuildingPermitFromJSONTyped,
    OpportunityAuthorizationsBuildingPermitToJSON,
} from './OpportunityAuthorizationsBuildingPermit';
import type { OpportunityAuthorizationsExecutionAgreement } from './OpportunityAuthorizationsExecutionAgreement';
import {
    OpportunityAuthorizationsExecutionAgreementFromJSON,
    OpportunityAuthorizationsExecutionAgreementFromJSONTyped,
    OpportunityAuthorizationsExecutionAgreementToJSON,
} from './OpportunityAuthorizationsExecutionAgreement';
import type { OpportunityAuthorizationsSpecificDevelopmentPlan } from './OpportunityAuthorizationsSpecificDevelopmentPlan';
import {
    OpportunityAuthorizationsSpecificDevelopmentPlanFromJSON,
    OpportunityAuthorizationsSpecificDevelopmentPlanFromJSONTyped,
    OpportunityAuthorizationsSpecificDevelopmentPlanToJSON,
} from './OpportunityAuthorizationsSpecificDevelopmentPlan';

/**
 * 
 * @export
 * @interface OpportunityAuthorizations
 */
export interface OpportunityAuthorizations {
    /**
     * 
     * @type {OpportunityAuthorizationsBuildingPermit}
     * @memberof OpportunityAuthorizations
     */
    buildingPermit?: OpportunityAuthorizationsBuildingPermit;
    /**
     * 
     * @type {OpportunityAuthorizationsExecutionAgreement}
     * @memberof OpportunityAuthorizations
     */
    executionAgreement?: OpportunityAuthorizationsExecutionAgreement;
    /**
     * 
     * @type {Array<OpportunityAuthorizationOtherAuthorization>}
     * @memberof OpportunityAuthorizations
     */
    otherAuthorizations?: Array<OpportunityAuthorizationOtherAuthorization>;
    /**
     * 
     * @type {OpportunityAuthorizationsSpecificDevelopmentPlan}
     * @memberof OpportunityAuthorizations
     */
    specificDevelopmentPlan?: OpportunityAuthorizationsSpecificDevelopmentPlan;
}

/**
 * Check if a given object implements the OpportunityAuthorizations interface.
 */
export function instanceOfOpportunityAuthorizations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityAuthorizationsFromJSON(json: any): OpportunityAuthorizations {
    return OpportunityAuthorizationsFromJSONTyped(json, false);
}

export function OpportunityAuthorizationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityAuthorizations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingPermit': !exists(json, 'buildingPermit') ? undefined : OpportunityAuthorizationsBuildingPermitFromJSON(json['buildingPermit']),
        'executionAgreement': !exists(json, 'executionAgreement') ? undefined : OpportunityAuthorizationsExecutionAgreementFromJSON(json['executionAgreement']),
        'otherAuthorizations': !exists(json, 'otherAuthorizations') ? undefined : ((json['otherAuthorizations'] as Array<any>).map(OpportunityAuthorizationOtherAuthorizationFromJSON)),
        'specificDevelopmentPlan': !exists(json, 'specificDevelopmentPlan') ? undefined : OpportunityAuthorizationsSpecificDevelopmentPlanFromJSON(json['specificDevelopmentPlan']),
    };
}

export function OpportunityAuthorizationsToJSON(value?: OpportunityAuthorizations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingPermit': OpportunityAuthorizationsBuildingPermitToJSON(value.buildingPermit),
        'executionAgreement': OpportunityAuthorizationsExecutionAgreementToJSON(value.executionAgreement),
        'otherAuthorizations': value.otherAuthorizations === undefined ? undefined : ((value.otherAuthorizations as Array<any>).map(OpportunityAuthorizationOtherAuthorizationToJSON)),
        'specificDevelopmentPlan': OpportunityAuthorizationsSpecificDevelopmentPlanToJSON(value.specificDevelopmentPlan),
    };
}

