/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpportunityStatus {
    Archived = 'Archived',
    Detection = 'Detection',
    InitialAppointmentWithOwner = 'InitialAppointmentWithOwner',
    InitialContactWithOwner = 'InitialContactWithOwner',
    InterestCommittee = 'InterestCommittee',
    Negotiation = 'Negotiation',
    OfferCollection = 'OfferCollection',
    OfferEstimation = 'OfferEstimation',
    OfferSelection = 'OfferSelection',
    Preparation = 'Preparation',
    Presentation = 'Presentation',
    Signature = 'Signature',
    Sleeping = 'Sleeping',
    TechnicalAnalysis = 'TechnicalAnalysis',
    Deleted = 'Deleted'
}


export function OpportunityStatusFromJSON(json: any): OpportunityStatus {
    return OpportunityStatusFromJSONTyped(json, false);
}

export function OpportunityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityStatus {
    return json as OpportunityStatus;
}

export function OpportunityStatusToJSON(value?: OpportunityStatus | null): any {
    return value as any;
}

