/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonRole } from './PersonRole';
import {
    PersonRoleFromJSON,
    PersonRoleFromJSONTyped,
    PersonRoleToJSON,
} from './PersonRole';
import type { PersonType } from './PersonType';
import {
    PersonTypeFromJSON,
    PersonTypeFromJSONTyped,
    PersonTypeToJSON,
} from './PersonType';

/**
 * 
 * @export
 * @interface PersonQueryFilter
 */
export interface PersonQueryFilter {
    /**
     * 
     * @type {string}
     * @memberof PersonQueryFilter
     */
    text?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonQueryFilter
     */
    id?: Array<string>;
    /**
     * 
     * @type {Array<PersonType>}
     * @memberof PersonQueryFilter
     */
    type?: Array<PersonType>;
    /**
     * 
     * @type {Array<PersonRole>}
     * @memberof PersonQueryFilter
     */
    role?: Array<PersonRole>;
    /**
     * 
     * @type {boolean}
     * @memberof PersonQueryFilter
     */
    usersOnly?: boolean;
}

/**
 * Check if a given object implements the PersonQueryFilter interface.
 */
export function instanceOfPersonQueryFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonQueryFilterFromJSON(json: any): PersonQueryFilter {
    return PersonQueryFilterFromJSONTyped(json, false);
}

export function PersonQueryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonQueryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ((json['type'] as Array<any>).map(PersonTypeFromJSON)),
        'role': !exists(json, 'role') ? undefined : ((json['role'] as Array<any>).map(PersonRoleFromJSON)),
        'usersOnly': !exists(json, 'usersOnly') ? undefined : json['usersOnly'],
    };
}

export function PersonQueryFilterToJSON(value?: PersonQueryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'id': value.id,
        'type': value.type === undefined ? undefined : ((value.type as Array<any>).map(PersonTypeToJSON)),
        'role': value.role === undefined ? undefined : ((value.role as Array<any>).map(PersonRoleToJSON)),
        'usersOnly': value.usersOnly,
    };
}

