/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ParcelFloodRisk {
    _10Years = '10Years',
    _100Years = '100Years',
    _1000Years = '1000Years'
}


export function ParcelFloodRiskFromJSON(json: any): ParcelFloodRisk {
    return ParcelFloodRiskFromJSONTyped(json, false);
}

export function ParcelFloodRiskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelFloodRisk {
    return json as ParcelFloodRisk;
}

export function ParcelFloodRiskToJSON(value?: ParcelFloodRisk | null): any {
    return value as any;
}

