/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParsedLocation
 */
export interface ParsedLocation {
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    table?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    column: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    row: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedLocation
     */
    height: number;
}

/**
 * Check if a given object implements the ParsedLocation interface.
 */
export function instanceOfParsedLocation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "x" in value;
    isInstance = isInstance && "y" in value;
    isInstance = isInstance && "column" in value;
    isInstance = isInstance && "row" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;

    return isInstance;
}

export function ParsedLocationFromJSON(json: any): ParsedLocation {
    return ParsedLocationFromJSONTyped(json, false);
}

export function ParsedLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsedLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'table': !exists(json, 'table') ? undefined : json['table'],
        'x': json['x'],
        'y': json['y'],
        'column': json['column'],
        'row': json['row'],
        'width': json['width'],
        'height': json['height'],
    };
}

export function ParsedLocationToJSON(value?: ParsedLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'table': value.table,
        'x': value.x,
        'y': value.y,
        'column': value.column,
        'row': value.row,
        'width': value.width,
        'height': value.height,
    };
}

