/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpportunityAuthorizationSpecificDevelopmentPlanDocument } from './OpportunityAuthorizationSpecificDevelopmentPlanDocument';
import {
    OpportunityAuthorizationSpecificDevelopmentPlanDocumentFromJSON,
    OpportunityAuthorizationSpecificDevelopmentPlanDocumentFromJSONTyped,
    OpportunityAuthorizationSpecificDevelopmentPlanDocumentToJSON,
} from './OpportunityAuthorizationSpecificDevelopmentPlanDocument';

/**
 * 
 * @export
 * @interface OpportunityAuthorizationsSpecificDevelopmentPlan
 */
export interface OpportunityAuthorizationsSpecificDevelopmentPlan {
    /**
     * 
     * @type {Date}
     * @memberof OpportunityAuthorizationsSpecificDevelopmentPlan
     */
    authorizationDate?: Date;
    /**
     * 
     * @type {Array<OpportunityAuthorizationSpecificDevelopmentPlanDocument>}
     * @memberof OpportunityAuthorizationsSpecificDevelopmentPlan
     */
    documents?: Array<OpportunityAuthorizationSpecificDevelopmentPlanDocument>;
}

/**
 * Check if a given object implements the OpportunityAuthorizationsSpecificDevelopmentPlan interface.
 */
export function instanceOfOpportunityAuthorizationsSpecificDevelopmentPlan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityAuthorizationsSpecificDevelopmentPlanFromJSON(json: any): OpportunityAuthorizationsSpecificDevelopmentPlan {
    return OpportunityAuthorizationsSpecificDevelopmentPlanFromJSONTyped(json, false);
}

export function OpportunityAuthorizationsSpecificDevelopmentPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityAuthorizationsSpecificDevelopmentPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationDate': !exists(json, 'authorizationDate') ? undefined : (new Date(json['authorizationDate'])),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(OpportunityAuthorizationSpecificDevelopmentPlanDocumentFromJSON)),
    };
}

export function OpportunityAuthorizationsSpecificDevelopmentPlanToJSON(value?: OpportunityAuthorizationsSpecificDevelopmentPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationDate': value.authorizationDate === undefined ? undefined : (value.authorizationDate.toISOString().substr(0,10)),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(OpportunityAuthorizationSpecificDevelopmentPlanDocumentToJSON)),
    };
}

