import React, {
    createContext,
    FunctionComponent,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import LoadingScreen from "../../lib/layout/LoadingScreen";
import {signInWithEmailAndPassword, User as FirebaseUser} from "firebase/auth";
import {useFirebaseAuth} from "../../app/FirebaseProvider";
import {setOpenApiToken, useOpenApiMutation} from "../../models/queries/openapi";
import {Person, User, UserApi} from "../../openapi";

export interface UserContext {
    firebaseUser: FirebaseUser | null;
    firebaseToken: string|null;
    user: User|null,
    setCurrentFirebaseUser: (currentUser: FirebaseUser | null) => void;
    setFirebaseToken: (token: string|null) => void;
    setUser: (user: User|null) => void;
}

const userContext = createContext<UserContext>({
    firebaseUser: null,
    firebaseToken: null,
    user: null,
    setCurrentFirebaseUser: () => {
        return;
    },
    setFirebaseToken: () => {
        return;
    },
    setUser: () => {
        return;
    }
});

export const UserProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
    const firebaseAuth = useFirebaseAuth();
    const [firebaseUser, setFirebaseUser] = useState<FirebaseUser|null>(firebaseAuth.currentUser);
    const [firebaseToken, setFirebaseToken] = useState<string|null>(null);
    const [user, setUser] = useState<User|null>(null);
    const [loading, setLoading] = useState(true);

    const { send: getMyUser} = useOpenApiMutation(UserApi, 'getMyUser');

    const initUser = async () => {
        if (firebaseAuth.currentUser) {
            try {
                const token = await firebaseAuth.currentUser.getIdToken();
                setFirebaseToken(token);
                setOpenApiToken(token);
                const user = await getMyUser(undefined);
                setUser(user);
            } catch (e) {
                console.error("User not logged in correctly", e);
                await firebaseAuth.updateCurrentUser(null);
                setFirebaseUser(null);
                setFirebaseToken(null);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        initUser();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (firebaseAuth.currentUser) {
                const token = await firebaseAuth.currentUser.getIdToken();
                setFirebaseToken(token);
            }
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return <LoadingScreen/>
    }
    return <userContext.Provider value={{setCurrentFirebaseUser: setFirebaseUser, firebaseUser, firebaseToken, setFirebaseToken, user, setUser}}>{children}</userContext.Provider>
}

export const useUser = (): Person | null => {
    const {user} = useContext(userContext);
    return user?.person as Person;
}

export const usePermissions = (): {permissions: Set<string>, hasPermission: (p: string) => boolean, hasAnyPermission: (p: string[]|Set<string>) => boolean} => {
    const {user} = useContext(userContext);
    const permissions = useMemo(() => new Set(user?.permissions), [user]);
    const hasPermission = useCallback((permission: string) => permissions.has(permission), [permissions]);
    const hasAnyPermission = useCallback((checkedPermissions: Set<string>|string[]) => {
        for (const permission in checkedPermissions) {
            if (hasPermission(permission)) return true;
        }
        return false;
    }, [permissions]);
    return {permissions, hasPermission, hasAnyPermission};
}

export const useHasPermission = (permission: string) => {
    const {hasPermission} = usePermissions();
    return useMemo(() => hasPermission(permission), [hasPermission, permission]);
}

export const useUserCredentials = () => {
    const {firebaseUser} = useContext(userContext);
    const {send: updateFirebasePassword} = useOpenApiMutation(UserApi, 'updateMyPassword');
    const changePassword = async (password: string) => {
        await updateFirebasePassword({
            updateMyPasswordRequest: {
                password
            }
        });
    }
    return {id: firebaseUser!.uid, login: firebaseUser!.email!, changePassword}
}

export const useFirebaseToken = () => {
    const {firebaseToken} = useContext(userContext);
    return firebaseToken;
}

export const useLogin = () => {
    const {setCurrentFirebaseUser, setFirebaseToken, setUser} = useContext(userContext);
    const firebaseAuth = useFirebaseAuth();
    const [loginError, setLoginError] = useState(undefined as Error | undefined);
    const { send: getMyUser} = useOpenApiMutation(UserApi, 'getMyUser');

    const login = async (username: string, password: string) => {
        setLoginError(undefined);
        try {
            const firebaseCreds = await signInWithEmailAndPassword(firebaseAuth, username, password);
            const firebaseLoggedIn = firebaseCreds.user;
            const token = await firebaseLoggedIn.getIdToken();
            setOpenApiToken(token);
            const user = await getMyUser(undefined);
            setFirebaseToken(token);
            setCurrentFirebaseUser(firebaseLoggedIn);
            setUser(user);
        } catch (e) {
            setLoginError(e as Error);
        }
    }

    return {login, loginError};
}

export const useLogout = () => {
    const {setCurrentFirebaseUser, setFirebaseToken, setUser} = useContext(userContext);
    const firebaseApp = useFirebaseAuth();

    return async () => {
        await firebaseApp.updateCurrentUser(null);
        setCurrentFirebaseUser(null);
        setFirebaseToken(null);
        setUser(null);
    };
}