/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';

/**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof BoundingBox
     */
    coordinates: Array<Coordinate>;
}

/**
 * Check if a given object implements the BoundingBox interface.
 */
export function instanceOfBoundingBox(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function BoundingBoxFromJSON(json: any): BoundingBox {
    return BoundingBoxFromJSONTyped(json, false);
}

export function BoundingBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoundingBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': ((json['coordinates'] as Array<any>).map(CoordinateFromJSON)),
    };
}

export function BoundingBoxToJSON(value?: BoundingBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': ((value.coordinates as Array<any>).map(CoordinateToJSON)),
    };
}

