/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocationQualityEvaluation
 */
export interface OpportunityLocationQualityEvaluation {
    /**
     * 
     * @type {number}
     * @memberof OpportunityLocationQualityEvaluation
     */
    globalEvaluation?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpportunityLocationQualityEvaluation
     */
    needsDemolition?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationQualityEvaluation
     */
    neighborhood?: string;
    /**
     * 
     * @type {number}
     * @memberof OpportunityLocationQualityEvaluation
     */
    noiseLevel?: number;
}

/**
 * Check if a given object implements the OpportunityLocationQualityEvaluation interface.
 */
export function instanceOfOpportunityLocationQualityEvaluation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpportunityLocationQualityEvaluationFromJSON(json: any): OpportunityLocationQualityEvaluation {
    return OpportunityLocationQualityEvaluationFromJSONTyped(json, false);
}

export function OpportunityLocationQualityEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationQualityEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalEvaluation': !exists(json, 'globalEvaluation') ? undefined : json['globalEvaluation'],
        'needsDemolition': !exists(json, 'needsDemolition') ? undefined : json['needsDemolition'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'noiseLevel': !exists(json, 'noiseLevel') ? undefined : json['noiseLevel'],
    };
}

export function OpportunityLocationQualityEvaluationToJSON(value?: OpportunityLocationQualityEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalEvaluation': value.globalEvaluation,
        'needsDemolition': value.needsDemolition,
        'neighborhood': value.neighborhood,
        'noiseLevel': value.noiseLevel,
    };
}

