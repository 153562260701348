/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpportunityInvolvement {
    None = 'None',
    Promotion = 'Promotion',
    Intermediation = 'Intermediation'
}


export function OpportunityInvolvementFromJSON(json: any): OpportunityInvolvement {
    return OpportunityInvolvementFromJSONTyped(json, false);
}

export function OpportunityInvolvementFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityInvolvement {
    return json as OpportunityInvolvement;
}

export function OpportunityInvolvementToJSON(value?: OpportunityInvolvement | null): any {
    return value as any;
}

