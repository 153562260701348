/* tslint:disable */
/* eslint-disable */
/**
 * People
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetadataUpdate } from './MetadataUpdate';
import {
    MetadataUpdateFromJSON,
    MetadataUpdateFromJSONTyped,
    MetadataUpdateToJSON,
} from './MetadataUpdate';
import type { PeopleRelationshipType } from './PeopleRelationshipType';
import {
    PeopleRelationshipTypeFromJSON,
    PeopleRelationshipTypeFromJSONTyped,
    PeopleRelationshipTypeToJSON,
} from './PeopleRelationshipType';

/**
 * 
 * @export
 * @interface PeopleRelationshipUpdate
 */
export interface PeopleRelationshipUpdate {
    /**
     * 
     * @type {MetadataUpdate}
     * @memberof PeopleRelationshipUpdate
     */
    metadata: MetadataUpdate;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipUpdate
     */
    child: string;
    /**
     * 
     * @type {Date}
     * @memberof PeopleRelationshipUpdate
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PeopleRelationshipUpdate
     */
    to?: Date;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipUpdate
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PeopleRelationshipUpdate
     */
    parent: string;
    /**
     * 
     * @type {PeopleRelationshipType}
     * @memberof PeopleRelationshipUpdate
     */
    relationShipType: PeopleRelationshipType;
}

/**
 * Check if a given object implements the PeopleRelationshipUpdate interface.
 */
export function instanceOfPeopleRelationshipUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "child" in value;
    isInstance = isInstance && "parent" in value;
    isInstance = isInstance && "relationShipType" in value;

    return isInstance;
}

export function PeopleRelationshipUpdateFromJSON(json: any): PeopleRelationshipUpdate {
    return PeopleRelationshipUpdateFromJSONTyped(json, false);
}

export function PeopleRelationshipUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeopleRelationshipUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': MetadataUpdateFromJSON(json['metadata']),
        'child': json['child'],
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'parent': json['parent'],
        'relationShipType': PeopleRelationshipTypeFromJSON(json['relationShipType']),
    };
}

export function PeopleRelationshipUpdateToJSON(value?: PeopleRelationshipUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': MetadataUpdateToJSON(value.metadata),
        'child': value.child,
        'from': value.from === undefined ? undefined : (value.from.toISOString().substr(0,10)),
        'to': value.to === undefined ? undefined : (value.to.toISOString().substr(0,10)),
        'notes': value.notes,
        'parent': value.parent,
        'relationShipType': PeopleRelationshipTypeToJSON(value.relationShipType),
    };
}

