/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral extracts
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CadastralExtractRequest,
  CadastralExtractRequestCreate,
} from '../models';
import {
    CadastralExtractRequestFromJSON,
    CadastralExtractRequestToJSON,
    CadastralExtractRequestCreateFromJSON,
    CadastralExtractRequestCreateToJSON,
} from '../models';

export interface CreateRequestForOpportunityRequest {
    cadastralExtractRequestCreate: CadastralExtractRequestCreate;
}

export interface GetRequestsForOpportunityRequest {
    opportunity: string;
}

/**
 * CadastralExtractApi - interface
 * 
 * @export
 * @interface CadastralExtractApiInterface
 */
export interface CadastralExtractApiInterface {
    /**
     * 
     * @param {CadastralExtractRequestCreate} cadastralExtractRequestCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadastralExtractApiInterface
     */
    createRequestForOpportunityRaw(requestParameters: CreateRequestForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CadastralExtractRequest>>;

    /**
     */
    createRequestForOpportunity(requestParameters: CreateRequestForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CadastralExtractRequest>;

    /**
     * 
     * @param {string} opportunity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadastralExtractApiInterface
     */
    getRequestsForOpportunityRaw(requestParameters: GetRequestsForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CadastralExtractRequest>>>;

    /**
     */
    getRequestsForOpportunity(requestParameters: GetRequestsForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CadastralExtractRequest>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadastralExtractApiInterface
     */
    pollPendingRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    pollPendingRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class CadastralExtractApi extends runtime.BaseAPI implements CadastralExtractApiInterface {

    /**
     */
    async createRequestForOpportunityRaw(requestParameters: CreateRequestForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CadastralExtractRequest>> {
        if (requestParameters.cadastralExtractRequestCreate === null || requestParameters.cadastralExtractRequestCreate === undefined) {
            throw new runtime.RequiredError('cadastralExtractRequestCreate','Required parameter requestParameters.cadastralExtractRequestCreate was null or undefined when calling createRequestForOpportunity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cadastral-extract-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CadastralExtractRequestCreateToJSON(requestParameters.cadastralExtractRequestCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CadastralExtractRequestFromJSON(jsonValue));
    }

    /**
     */
    async createRequestForOpportunity(requestParameters: CreateRequestForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CadastralExtractRequest> {
        const response = await this.createRequestForOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsForOpportunityRaw(requestParameters: GetRequestsForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CadastralExtractRequest>>> {
        if (requestParameters.opportunity === null || requestParameters.opportunity === undefined) {
            throw new runtime.RequiredError('opportunity','Required parameter requestParameters.opportunity was null or undefined when calling getRequestsForOpportunity.');
        }

        const queryParameters: any = {};

        if (requestParameters.opportunity !== undefined) {
            queryParameters['opportunity'] = requestParameters.opportunity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cadastral-extract-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CadastralExtractRequestFromJSON));
    }

    /**
     */
    async getRequestsForOpportunity(requestParameters: GetRequestsForOpportunityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CadastralExtractRequest>> {
        const response = await this.getRequestsForOpportunityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pollPendingRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cadastral-extract-requests/poll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pollPendingRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pollPendingRequestsRaw(initOverrides);
    }

}
