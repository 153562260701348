/* tslint:disable */
/* eslint-disable */
/**
 * Cadastral parcel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcelAddress
 */
export interface ParcelAddress {
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    namedPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelAddress
     */
    street?: string;
}

/**
 * Check if a given object implements the ParcelAddress interface.
 */
export function instanceOfParcelAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function ParcelAddressFromJSON(json: any): ParcelAddress {
    return ParcelAddressFromJSONTyped(json, false);
}

export function ParcelAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': json['country'],
        'city': json['city'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'namedPlace': !exists(json, 'namedPlace') ? undefined : json['namedPlace'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
    };
}

export function ParcelAddressToJSON(value?: ParcelAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'city': value.city,
        'locality': value.locality,
        'namedPlace': value.namedPlace,
        'number': value.number,
        'postalCode': value.postalCode,
        'street': value.street,
    };
}

