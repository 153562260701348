import React, { FunctionComponent, PropsWithChildren } from "react";
import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next, withTranslation } from "react-i18next";
import LoadingScreen from "../LoadingScreen";
import { setLocale } from "yup";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: "field_invalid",
    required: ({ label, path }) => ({
      key: "field-required",
      values: { label: label || path },
    }),
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: "field-too-short", values: { min } }),
    max: ({ max }) => ({ key: "field-too-big", values: { max } }),
  },
});

i18n
  .use(i18nBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: false,
    returnNull: false,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      nsMode: "fallback",
      useSuspense: false,
    },
  });

export const TranslationProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TranslationLoadingScreenWithTranslations>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr-FR">
        {children}
      </LocalizationProvider>
    </TranslationLoadingScreenWithTranslations>
  );
};

const TranslationLoadingScreen: FunctionComponent<
  PropsWithChildren<{ tReady: boolean }>
> = ({ children, tReady }) => {
  if (!tReady) return <LoadingScreen />;
  return <>{children}</>;
};

const TranslationLoadingScreenWithTranslations = withTranslation([
  "common",
  "administrative-units",
  "cities",
  "luxmap",
  "magicbell",
  "opportunities",
  "parcels",
  "people",
])(TranslationLoadingScreen);
