/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuildingAlignmentLocation } from './BuildingAlignmentLocation';
import {
    BuildingAlignmentLocationFromJSON,
    BuildingAlignmentLocationFromJSONTyped,
    BuildingAlignmentLocationToJSON,
} from './BuildingAlignmentLocation';
import type { BuildingAlignmentType } from './BuildingAlignmentType';
import {
    BuildingAlignmentTypeFromJSON,
    BuildingAlignmentTypeFromJSONTyped,
    BuildingAlignmentTypeToJSON,
} from './BuildingAlignmentType';
import type { OpenMinMax } from './OpenMinMax';
import {
    OpenMinMaxFromJSON,
    OpenMinMaxFromJSONTyped,
    OpenMinMaxToJSON,
} from './OpenMinMax';

/**
 * 
 * @export
 * @interface BuildingAlignment
 */
export interface BuildingAlignment {
    /**
     * 
     * @type {string}
     * @memberof BuildingAlignment
     */
    geometry: string;
    /**
     * 
     * @type {OpenMinMax}
     * @memberof BuildingAlignment
     */
    distanceM: OpenMinMax;
    /**
     * 
     * @type {BuildingAlignmentType}
     * @memberof BuildingAlignment
     */
    type: BuildingAlignmentType;
    /**
     * 
     * @type {BuildingAlignmentLocation}
     * @memberof BuildingAlignment
     */
    location: BuildingAlignmentLocation;
}

/**
 * Check if a given object implements the BuildingAlignment interface.
 */
export function instanceOfBuildingAlignment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "distanceM" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function BuildingAlignmentFromJSON(json: any): BuildingAlignment {
    return BuildingAlignmentFromJSONTyped(json, false);
}

export function BuildingAlignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingAlignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometry': json['geometry'],
        'distanceM': OpenMinMaxFromJSON(json['distanceM']),
        'type': BuildingAlignmentTypeFromJSON(json['type']),
        'location': BuildingAlignmentLocationFromJSON(json['location']),
    };
}

export function BuildingAlignmentToJSON(value?: BuildingAlignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry': value.geometry,
        'distanceM': OpenMinMaxToJSON(value.distanceM),
        'type': BuildingAlignmentTypeToJSON(value.type),
        'location': BuildingAlignmentLocationToJSON(value.location),
    };
}

