/* tslint:disable */
/* eslint-disable */
/**
 * Sourcing opportunities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpportunityLocationQualityDisturbance
 */
export interface OpportunityLocationQualityDisturbance {
    /**
     * 
     * @type {string}
     * @memberof OpportunityLocationQualityDisturbance
     */
    type: OpportunityLocationQualityDisturbanceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OpportunityLocationQualityDisturbanceTypeEnum {
    Railway = 'Railway',
    HighVoltageLine = 'HighVoltageLine',
    NoiseArea = 'NoiseArea'
}


/**
 * Check if a given object implements the OpportunityLocationQualityDisturbance interface.
 */
export function instanceOfOpportunityLocationQualityDisturbance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function OpportunityLocationQualityDisturbanceFromJSON(json: any): OpportunityLocationQualityDisturbance {
    return OpportunityLocationQualityDisturbanceFromJSONTyped(json, false);
}

export function OpportunityLocationQualityDisturbanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpportunityLocationQualityDisturbance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function OpportunityLocationQualityDisturbanceToJSON(value?: OpportunityLocationQualityDisturbance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

